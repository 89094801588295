/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import MikeVisualizerLib from '../MikeVisualizer/lib/MikeVisualizer';
import {
  EViewerModes,
  IThreeDRenderElement,
  IViewerBounds,
} from '../MikeVisualizer/lib/IMikeVisualizerModels';
import './MeshOpenLayers.css';

import { store } from '../store';
import { t } from '../translations/i18n';

import WorkspaceSocketManager from '../managers/WorkspaceSocketManager';
import {
  getDrawnDataSize,
  getDrawnWorkspaceGeometriesByIds,
  getDrawnWorkspaceMeshesByIds,
  getDrawnWorkspaceVariablesByIds,
} from '../store/selectors/WorkspaceDrawnDataSelectors';
import WorkspaceGeometrySelectors from '../store/selectors/WorkspaceGeometrySelectors';
import WorkspaceVariableSelectors from '../store/selectors/WorkspaceVariableSelectors';
import WorkspaceMeshSelectors from '../store/selectors/WorkspaceMeshSelectors';
import { getElementCategory } from '../store/selectors/WorkspaceDataItemUtils';
import { ELEMENT_CATEGORIES } from '../shared/panels/mesh-panel-constants';
import { IWorkspaceGeometry } from '../models/IGeometries';
import { IWorkspaceMesh } from '../models/IMeshes';
import { IWorkspaceVariable } from '../models/IVariables';
import { IDrawnDataItem } from '../models/IWorkspaceData';
import {
  INotificationItemData,
  INotificationItemDataStructure,
  INotificationItemDataUpdatedMsg,
  INotificationAttributeUpdatedMsg,
  INotificationAttributeData,
  IMeshStreamInfo,
} from '../models/IWorkspaceNotifications';
import { CUSTOMSELECTIONCOLOR } from '../workspaces/viewer/viewer-utils';
import FeatureFlags from '../app/feature-flags';
import { getRouteByPath, ROUTES } from '../app/routes';
import { IOperationMetadata } from '../models/IOperations';
import { IWorkspaceComment } from '../models/IComments';
import { IWorkspaceQuery } from '../models/IQueries';
import { WorkspaceActionType } from '../workspaces/store/WokspaceActionType';
import { IExports } from '../models/IExports';
import {
  _selectOrDeselectElements,
  _showOrHideElements,
  _onBaseMapProjectionFetchFailed,
  _highlightElement,
} from './viewer-utilities';
import { IWorkspace, IWorkspaceOverview } from '../models/IWorkspaces';
import { MikeVisualizer } from '../MikeVisualizer/MikeVisualizer';
import MmgViewerColorLegend from './viewer-color-legend';
import MmgViewerInfoBar from './viewer-info-bar';
import { usePrevious } from 'react-use';
import { ColorLegendsZindex, PanelWidth, ToolsZindex } from '../shared/styles/styleConsts';
import { EWorkspaceDataActionType } from '../store/actions/WorkspaceDataActionType';
import { ILabelNotification } from '../models/ILabelNotification';
import { EOperationActionType } from '../store/actions/OperationActionType';
import { EWorkspaceQueryActionType } from '../store/actions/WorkspaceQueryActionType';
import { INodePatch, IPatchFeature, IQueryCompleted } from '../models/IQueryCompleted';
import { EWorkspaceMeshActionType } from '../store/actions/WorkspaceMeshActionType';
import { onBoundsChanged } from '../MikeVisualizer/lib/MikeVisualizerEvents';
import { isEqual } from 'lodash-es';
import { ISpatialQueryDefinitionApi } from '../models/IQueryDefinitions';
import { getSelectionGeometryId, getSelectionLabelId } from '../store/reducers/WorkspaceQueryReducer';
import { EMapToolActionType } from '../store/actions/MapToolActionType';
import { EViewerCursorActionType } from '../store/actions/ViewerCursorActionType';
import { EDIT_MODE_DELETED } from '../store/reducers/EditReducer';
import { Feature } from 'geojson';
import { setFeaturesForEditing, setLoadingFeaturesForEditing } from '../store/actions/editingItems';
import { INodeNeighbours } from '../store/reducers/MeshMapToolReducer';
import { patchFeaturesToFeatures } from '../workspaces/sagas/meshEditHelpers';
import { EIAMActionType } from '../store/actions/IAMActionType';
import mikeSharedTheme from '../shared/styles/mikeSharedTheme';
import { IGlobalState } from '../store/reducers';
import { useNavigate, useParams } from 'react-router-dom';

const getLegendStyle = (panelIsOOpen: boolean) => css`
  display: flex;
  position: absolute;
  top: 0;
  right: ${panelIsOOpen ? PanelWidth : 0};
  z-index: ${ColorLegendsZindex};
  padding: ${mikeSharedTheme.spacing(2)} ${mikeSharedTheme.spacing(1)};
`;

const getInfoStyle = (panelIsOOpen: boolean) => {
  return panelIsOOpen
    ? css`
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: ${mikeSharedTheme.palette.mediumGrey.dark};
        width: calc(100vw - ${PanelWidth});
        z-index: ${ToolsZindex};
        justify-content: space-between;
      `
    : css`
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: ${mikeSharedTheme.palette.mediumGrey.dark};
        width: '100vw';
        z-index: ${ToolsZindex};
        justify-content: space-between;
      `;
};

const {
  onWorkStarted,
  onWorkEnded,
  onMouseMove,
  onBaseMapProjectionNotSupported,
  onBaseMapProjectionFetchFailed,
  setConfiguration,
  setViewerZScale,
  unhighlight,
  setEpsgCode,
  setCrs,
  getState,
} = MikeVisualizerLib;

let basemapNotSupportedToastOnce = false;

const MngViewerConnected = () => {
  const navigate = useNavigate();
  const { workspaceId, projectId } = useParams();

  const { viewerMode, viewerBaseMapId } = useSelector(
    (state: IGlobalState) => state.ViewerModeReducer as { viewerMode: EViewerModes; viewerBaseMapId: string },
  );
  setConfiguration(CUSTOMSELECTIONCOLOR);
  const { connectedToNewSocket } = useSelector((state: IGlobalState) => state.SocketReducer);
  const { highlightedWorkspaceElementId, workspace, workspaceBoundingBox } = useSelector(
    (state: IGlobalState) => state.WorkspaceReducer,
  );
  const { hiddenWorkspaceGeometries, selectedWorkspaceGeometries } = useSelector(
    (state: IGlobalState) => state.WorkspaceGeometryReducer,
  );
  const { hiddenWorkspaceVariables } = useSelector((state: IGlobalState) => state.WorkspaceVariableReducer);
  const { hiddenWorkspaceMeshes, selectedWorkspaceMeshes } = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshReducer,
  );

  const { panelHidden } = useSelector((state: IGlobalState) => state.PanelReducer);
  const { viewerWorking } = useSelector((state: IGlobalState) => state.ViewerCursorReducer);
  const drawnWorkspaceGeometries: Array<IDrawnDataItem> = useSelector(getDrawnWorkspaceGeometriesByIds);
  const drawnWorkspaceVariables: Array<IDrawnDataItem> = useSelector(getDrawnWorkspaceVariablesByIds);
  const drawnWorkspaceMeshes: Array<IDrawnDataItem> = useSelector(getDrawnWorkspaceMeshesByIds);
  const totalGeometriesLoad = useSelector(WorkspaceGeometrySelectors.getWorkspaceGeometriesNotFailed);
  const totalVariablesToLoad = useSelector(WorkspaceVariableSelectors.getWorkspaceVariablesNotFailed);
  const meshes: Array<IWorkspaceMesh> = useSelector(WorkspaceMeshSelectors.getWorkspaceMeshesNotFailed);
  const totalMeshesToLoad: Array<IWorkspaceMesh> = meshes.filter(({ dataId }) => Boolean(dataId));

  const drawnDataSize: number = useSelector(getDrawnDataSize);

  const { id } = useSelector((state: IGlobalState) => state.UserReducer.user);

  const [currentViewerCoordinates, setCurrentViewerCoordinates] = useState([0, 0, 0]);
  const [currentlyHiddenElements, setCurrentlyHiddenElements] = useState(Array<string>());
  const [currentlySelectedElements, setCurrentlySelectedElements] = useState(Array<string>());
  // const previouslyHighlightedElement = usePrevious(highlightedWorkspaceElementId);

  const infoStyle = getInfoStyle(!panelHidden);
  const legendStyle = getLegendStyle(!panelHidden);

  useEffect(
    () => {
      // When drawn data size exceeds 1GB remove mesh tile layers of latest bounds
      if (drawnDataSize > 1000000000) {
        store.dispatch({ type: EWorkspaceMeshActionType.REMOVE_FIRST_MESH_TILE_BOUNDS });
      }
    },
    [drawnDataSize],
  );

  useEffect(
    () => {
      // Reset viewer Z scale on 2D to avoid hill shading:
      viewerMode === EViewerModes.TWO_D && setViewerZScale(1e-10);
    },
    [viewerMode],
  );

  const hiddenEl = useMemo(
    () => {
      const hiddenEl = [...hiddenWorkspaceGeometries, ...hiddenWorkspaceVariables, ...hiddenWorkspaceMeshes];
      return hiddenEl;
    },
    [hiddenWorkspaceGeometries, hiddenWorkspaceVariables, hiddenWorkspaceMeshes],
  );

  useEffect(
    () => {
      if (!isEqual(hiddenEl, currentlyHiddenElements)) {
        _showOrHideElements(hiddenEl, currentlyHiddenElements, totalMeshesToLoad);
        setCurrentlyHiddenElements(hiddenEl);
      }
    },
    [hiddenEl, currentlyHiddenElements, totalMeshesToLoad],
  );

  useEffect(
    () => {
      const selected = [...selectedWorkspaceGeometries, ...selectedWorkspaceMeshes];
      const selectedElementsChanged = _selectOrDeselectElements(selected, currentlySelectedElements);
      if (selectedElementsChanged) {
        setCurrentlySelectedElements(selected);
      }
    },
    [selectedWorkspaceGeometries, selectedWorkspaceMeshes, currentlySelectedElements],
  );

  useEffect(
    () => {
      if (highlightedWorkspaceElementId) {
        // Highlight / unhighlight element in the viewer.
        // For performance reasons, variables are excluded from highlight.
        const { highlightedElementId, selectedElementIds, renderedElements } = getState();

        if (highlightedElementId) {
          unhighlight(highlightedElementId);
        }
        const renderedElement = renderedElements.find(
          (element: IThreeDRenderElement) => element.id === highlightedWorkspaceElementId,
        );
        _highlightElement(highlightedWorkspaceElementId, selectedElementIds, renderedElement, viewerBaseMapId);
      } else {
        const { highlightedElementId } = getState();
        if (highlightedElementId) {
          unhighlight(highlightedElementId);
        }
      }
    },
    [highlightedWorkspaceElementId, viewerBaseMapId],
  );

  /**
   * Connect to socket & listen for workspace updates.
   * Only one connection needed per workspace.
   *
   * TODO dan: If another user made the update, we should probaby show a toast with who updated. Or have a more elegant concept that is not as distracting.
   * @param wsId The workspace to listen to changes for.
   */
  const setupSocket = useCallback(
    (pId: string, wsId: string) => {
      const socketManager = WorkspaceSocketManager.getInstance();
      const { on, defaultEvents, managerEvents } = socketManager;

      /**
       * When the current workspace is deleted, e.g. by another user,
       * route back to workspace list, show toast and clean up.
       *
       * Options required in case `this` scope is lost after routing:
       * @param options as `{ <options> }`, see below
       * @param options.projectId
       * @param options.workspaceId
       */
      const onWorkSpaceDeleted = ({ projectId, workspaceId }: { projectId: string; workspaceId: string }) => {
        navigate(getRouteByPath(ROUTES.workspaceList.path, { projectId }));  
        store.dispatch({
          type: 'toast/ADD/INFO',
          toast: { text: t('WORKSPACE_CURRENT_WS_DELETED') },
        });
        store.dispatch({
          type: 'workspace/recent/DELETE',
          workspaceId,
        });
      };

      // todo hevo we should have a saga eventChannel that listens for the manager events and dispatces proper action
      // But be careful! Events carrying data should still be handled in mesh-viewer!. We do NOT want to get the hughe amount of data dispatched as part of any action!!
      on(managerEvents.WORKSPACE_CONNECTED, (workspace: IWorkspace) => {
        store.dispatch({
          type: WorkspaceActionType.WORKSPACE_CONNECTED,
          workspace,
        });
      });

      on(managerEvents.WORKSPACE_DELETED, (delWsId: string) => {
        if (delWsId !== workspaceId) {
          // prettier-ignore
          console.info("Deleted id (" + delWsId + ") doesn't match current workspace id (" + wsId + ")");
          return;
        }
        onWorkSpaceDeleted({
          projectId: projectId,
          workspaceId: workspaceId,
        });
      });

      on(managerEvents.WORKSPACE_STRUCTURE_UPDATED, (workspace: IWorkspace) => {
        store.dispatch({
          type: WorkspaceActionType.STRUCTURE_UPDATED,
          workspace,
        });
      });

      on(managerEvents.WORKSPACE_OVERVIEW_UPDATED, (workspaceOverview: IWorkspaceOverview) => {
        store.dispatch({
          type: WorkspaceActionType.OVERVIEW_UPDATED,
          workspaceOverview,
        });
      });

      on(managerEvents.VTKFILE_CREATED_OR_UPDATED, (dataUpdatedMsg: INotificationItemDataUpdatedMsg) => {
        const { itemId, dataId, itemType, updated, created, isTiled } = dataUpdatedMsg;
        if (isTiled) {
          store.dispatch({ type: EWorkspaceMeshActionType.CLEAR_TILED_MESH_FIELD_STATISTICS, data: itemId });
        }
        // For each message event, stream data corresponding to the corresponding item that changed, unless it has no data
        if (dataId) {
          store.dispatch({
            type: WorkspaceActionType.LOAD_DATA,
            identity: itemId,
            workspaceId,
            itemId,
            dataId,
            category: getElementCategory(itemType),
            created,
            updated,
            excludeDataArrays: FeatureFlags.useStreamVtkGeometry,
            isTiled,
          });
        }
      });

      on(managerEvents.RAW_DATA_LOADED, async (rawDataItem: INotificationItemData) => {
        store.dispatch({ type: WorkspaceActionType.SOCKET_RAW_DATA_LOADED, data: {rawDataItem, workspaceId: wsId } });
      });

      on(managerEvents.TILE_DATA_LOADED, async (rawDataItem: INotificationItemData, streamInfo: IMeshStreamInfo) => {
        store.dispatch({ type: WorkspaceActionType.SOCKET_TILE_DATA_LOADED, data: { rawDataItem, streamInfo } });
      });

      on(
        managerEvents.ALL_TILE_DATA_LOADED,
        async (partIds: Array<string>, size: number, streamInfo: IMeshStreamInfo) => {
          store.dispatch({
            type: EWorkspaceMeshActionType.ADD_MESH_TILE_BOUNDS,
            data: { streamInfo, partIds, size },
          });
          store.dispatch({
            type: WorkspaceActionType.SOCKET_ALL_TILE_DATA_LOADED,
            data: { streamInfo, partIds, size, workspaceId: wsId },
          });
        },
      );

      on(managerEvents.RAW_DATA_LOADED_EXCL_DATAARRAYS, async (rawDataItem: INotificationItemDataStructure) => {
        store.dispatch({ type: WorkspaceActionType.SOCKET_RAW_DATA_LOADED_EXCL_DATAARRAYS, data: {rawDataItem, workspaceId: wsId} });
      });

      on(managerEvents.DATA_DELETED, ({ itemId }: { itemId: string }) => {
        store.dispatch({ type: WorkspaceActionType.SOCKET_DATA_DELETED, data: itemId });
      });

      on(managerEvents.DATA_ITEMS_DELETED, (itemDataIds) => {
        store.dispatch({ type: WorkspaceActionType.SOCKET_DATA_ITEMS_DELETED, data: itemDataIds });
      });

      on(managerEvents.DATA_ATTRIBUTE_ADDED_OR_UPDATED, (attributeNotification: INotificationAttributeUpdatedMsg) => {
        const { itemId, propertyName } = attributeNotification;
        store.dispatch({
          type: WorkspaceActionType.LOAD_DATA_ATTRIBUTE,
          identity: { itemId, attributeName: propertyName },
          workspaceId,
          attributeNotification,
        });
      });

      on(managerEvents.DATA_ATTRIBUTE_LOADED, (attributeData: INotificationAttributeData) => {
        store.dispatch({
          type: WorkspaceActionType.SOCKET_DATA_ATTRIBUTE_LOADED,
          data: { attributeData, workspaceId: wsId }
        });
      });

      on(managerEvents.DATA_ATTRIBUTE_DELETED, (atributeDeleted: INotificationAttributeUpdatedMsg) => {
        store.dispatch({
          type: WorkspaceActionType.SOCKET_DATA_ATTRIBUTE_DELETED,
          data: atributeDeleted,
        });
      });

      on(managerEvents.DATA_ATTRIBUTE_FAILED_LOADING, (itemId: string, attributeName: string) => {
        store.dispatch({
          type: WorkspaceActionType.LOAD_DATA_ATTRIBUTE_FAILED,
          identity: { itemId, attributeName },
          workspaceId,
          itemId,
          attributeName,
        });
      });

      on(
        managerEvents.ITEM_ADDED_OR_UPDATED,
        (item: IWorkspaceGeometry | IWorkspaceMesh | IWorkspaceVariable | IWorkspaceQuery) => {
          const { itemType } = item;
          const category = getElementCategory(itemType);
          switch (category) {
            case ELEMENT_CATEGORIES.MESH: {
              store.dispatch({
                type: 'workspace/meshes/ITEM_ADDED_OR_UPDATED',
                workspaceMesh: item,
              });
              break;
            }
            case ELEMENT_CATEGORIES.VARIABLE: {
              store.dispatch({
                type: 'workspace/variables/ITEM_ADDED_OR_UPDATED',
                workspaceVariable: item,
              });
              break;
            }
            case ELEMENT_CATEGORIES.GEOMETRY: {
              store.dispatch({
                type: 'workspace/geometries/ITEM_ADDED_OR_UPDATED',
                workspaceGeometry: item,
              });
              break;
            }
            case ELEMENT_CATEGORIES.QUERY: {
              store.dispatch({
                type: EWorkspaceQueryActionType.ITEM_ADDED_OR_UPDATED,
                workspaceQuery: item,
              });
              break;
            }
            default:
              console.warn('Unknown itemType', itemType);
              break;
          }
        },
      );

      on(managerEvents.EXPORT_STARTED_OR_COMPLETED, (exports: IExports) => {
        store.dispatch({
          type: WorkspaceActionType.EXPORT_STARTED_OR_COMPLETED,
          exports,
        });
      });

      on(managerEvents.LABELS_CREATED, (labels: ILabelNotification) => {
        if (labels && labels.userId === id) {
          store.dispatch({
            type: EWorkspaceDataActionType.ADD_LABELS,
            labels,
          });
        }
      });

      on(managerEvents.QUERY_COMPLETED, (queryResult: IQueryCompleted) => {
        if (queryResult && queryResult.userId === id) {
          const resultReturnType =
            queryResult.queryDefinition && queryResult.queryDefinition.resultReturnType
              ? queryResult.queryDefinition.resultReturnType
              : '';

          if (resultReturnType === 'FeatureDtoCollection') {
            const resultType =
              queryResult.queryDefinition && queryResult.queryDefinition.resultType
                ? queryResult.queryDefinition.resultType
                : '';

            switch (resultType) {
              case 'Geometry': {
                if (queryResult.features && queryResult.features.length > 0) {
                  const features: Array<IPatchFeature> = queryResult.features;
                  const notDeletedFeatures = features.filter((feature: IPatchFeature) => {
                    const editState = feature.editState;
                    const isDeleted = editState === EDIT_MODE_DELETED;
                    return !isDeleted;
                  });
                  const editedFeatures = patchFeaturesToFeatures(notDeletedFeatures);
                  store.dispatch(
                    setFeaturesForEditing(
                      { type: 'FeatureCollection', features: editedFeatures },
                      queryResult.operationId,
                    ),
                  );                  
                }
                store.dispatch(setLoadingFeaturesForEditing(false));
                break;
              }
              case 'NodePatch': {
                // mesh editing
                const selectionGeometry =
                  queryResult.queryDefinition.featureCollection &&
                  queryResult.queryDefinition.featureCollection.features &&
                  queryResult.queryDefinition.featureCollection.features.length > 0
                    ? queryResult.queryDefinition.featureCollection.features[0].geometry
                    : null;
                if (selectionGeometry && selectionGeometry.type === 'Point') {
                  if (queryResult.features && queryResult.features.length > 0) {
                    const nodePatch: INodePatch = queryResult.features[0];
                    const neighbours = nodePatch.elements.map((el: IPatchFeature) => {
                      const feature = JSON.parse(el.geoJsonFeature);
                      return feature;
                    });
                    const meshNodeFeature: Feature<any> = JSON.parse(nodePatch.geoJsonFeature);
                    if (meshNodeFeature && meshNodeFeature.geometry && meshNodeFeature.geometry.type === 'Point') {
                      const nodeCoords = meshNodeFeature.geometry.coordinates;
                      if (nodeCoords && nodeCoords.length > 1) {
                        const nodeNeighbours: INodeNeighbours = {
                          nodeIndex: nodePatch.vtuIndex,
                          nodeX: nodeCoords[0],
                          nodeY: nodeCoords[1],
                          nodeProperties: meshNodeFeature.properties,
                          neighbouringFeatures: neighbours,
                        };
                        store.dispatch({
                          type: EWorkspaceMeshActionType.SET_NODE_NEIGHBOURS,
                          data: nodeNeighbours,
                        });
                      }
                    }
                  }
                }
                store.dispatch(setLoadingFeaturesForEditing(false));
                break;
              }
            }
          } else if (resultReturnType === 'VtpString') {
            const queryDef = queryResult.queryDefinition as ISpatialQueryDefinitionApi;
            if (queryResult.queryId && queryResult.vtp) {
              store.dispatch({
                type: EWorkspaceQueryActionType.SELECTION_RESULT_ADD,
                data: { selectionId: queryResult.queryId, vtp: queryResult.vtp },
              });
              const selectionId = queryResult.queryId;
              const labelName = queryResult.queryName;
              const geomId = getSelectionGeometryId(selectionId);
              const labelId = getSelectionLabelId(selectionId);
              store.dispatch({
                type: EWorkspaceQueryActionType.SELECTION_GEOMETRY_ADD,
                data: {
                  id: geomId,
                  selectionId,
                  featureCollection: queryDef.featureCollection,
                  labelName,
                  labelId,
                  sourceItemId: queryDef.sourceItemId,
                },
              });
            }
          } else {
            store.dispatch({
              type: EWorkspaceQueryActionType.QUERY_COMPLETED,
              data: queryResult,
            });
          }
        }
      });

      on(managerEvents.GEOMETRIES_DELETED, (geometryIds: Array<string>, operationIds: Array<string>) => {
        store.dispatch({
          type: WorkspaceActionType.SOCKET_GEOMETRIES_DELETED,
          data: { geometryIds: geometryIds, operationIds: operationIds },
        });
      });

      on(managerEvents.VARIABLES_DELETED, (variableIds: Array<string>, operationIds: Array<string>) => {
        store.dispatch({
          type: WorkspaceActionType.SOCKET_VARIABLES_DELETED,
          data: { variableIds: variableIds, operationIds: operationIds },
        });
      });

      on(managerEvents.MESHES_DELETED, (meshIds: Array<string>, operationIds: Array<string>) => {
        store.dispatch({
          type: WorkspaceActionType.SOCKET_MESHES_DELETED,
          data: { meshIds: meshIds, operationIds: operationIds },
        });
      });

      on(managerEvents.QUERIES_DELETED, (queryIds: Array<string>) => {
        store.dispatch({
          type: 'workspace/queries/ITEMS_DELETED',
          queryIds,
        });
      });

      // todo hevo Events for comments should be handled somewhere else. No need to do this in the viewer
      on(managerEvents.COMMENT_CREATED, (comment: IWorkspaceComment) => {
        store.dispatch({
          type: 'comments/ADD',
          comment,
        });
      });

      on(managerEvents.COMMENT_UPDATED, (comment: IWorkspaceComment) => {
        store.dispatch({
          type: 'comments/UPDATE',
          comment,
        });
      });

      on(managerEvents.COMMENT_DELETED, (commentId: string) => {
        store.dispatch({
          type: 'comments/DELETE',
          commentId,
        });
      });

      // todo hevo Events for operations should be handled somewhere else. No need to do this in the viewer
      on(managerEvents.OPERATION_CREATED_OR_UPDATED, (operationMetadata: IOperationMetadata) => {
        store.dispatch({
          type: EOperationActionType.CREATE_OR_UPDATE,
          operationMetadata,
        });
      });

      on(managerEvents.VOLATILE_OPERATION_CREATED_OR_UPDATED, (operationMetadata: IOperationMetadata) => {
        if (operationMetadata.userId === id) {
          store.dispatch({
            type: EOperationActionType.VOLATILE_CREATE_OR_UPDATE,
            data: operationMetadata,
          });
        }
      });

      on(managerEvents.OPERATIONS_DELETED, (operationIds: Array<string>) => {
        store.dispatch({
          type: 'workspace/operations/ITEMS_DELETED',
          operationIds,
        });
      });

      on(managerEvents.ITEM_FAILED_LOADING, (itemId) => {
        store.dispatch({
          type: WorkspaceActionType.LOAD_DATA_FAILED,
          identity: itemId,
          workspaceId,
          itemId,
        });
      });

      on(defaultEvents.OPEN, () => {
        // Reset error count if a connection is successful.
        store.dispatch({ type: WorkspaceActionType.SOCKET_SET_TRY_RECONNECT, data: 0 });
        console.log('Opened socket for ' + workspaceId);
      });

      on(defaultEvents.ERROR, (error) => {
        const errorMessage = t('WORKSPACE_SOCKET_ERROR');
        console.error(error);
        store.dispatch({ type: WorkspaceActionType.SOCKET_TRY_RECONNECT, data: { errorMessage, navigate, projectId: pId, workspaceId: wsId } });
      });

      on(defaultEvents.CLOSE, () => {
        const errorMessage = t('WORKSPACE_SOCKET_CLOSED');
        console.warn(`Socket connection closed for ${workspaceId}`);
        store.dispatch({ type: WorkspaceActionType.SOCKET_TRY_RECONNECT, data: { errorMessage, navigate, projectId: pId, workspaceId: wsId } });
      });

      on(defaultEvents.RECONNECTING, () => {
        store.dispatch({
          type: 'toast/ADD/WORKING',
          toast: {
            text: `${t('WORKSPACE_LIVE_UPDATES_LOST')} \n ${t('WORKSPACE_LIVE_UPDATES_LOST_MESSAGES')}`,
          },
        });

        console.warn(`Socket reconnecting for ${workspaceId}`);
      });

      on(defaultEvents.RECONNECTED, () => {
        store.dispatch({
          type: 'toast/CLEAR_ALL_WORKING',
        });

        console.warn(`Socket reconnected for ${workspaceId}`);
      });

      on(defaultEvents.RECONNECTING_FAILED, () => {
        store.dispatch({
          type: 'toast/ADD/ERROR',
          toast: { text: t('WORKSPACE_SOCKET_RETRY_STOP') },
        });

        console.warn(`Socket reconnecting failed for ${workspaceId}`);
      });

      on(defaultEvents.TOKEN_INVALID, () => {
        store.dispatch({
          type: EIAMActionType.USER_SESSION_EXPIRED,
          data: true,
        });
        store.dispatch({ type: EIAMActionType.USER_LOGOUT });
      });
    },
    [navigate, workspaceId, projectId, id],
  );

  const previousConnectedToSocket = usePrevious(connectedToNewSocket);
  useEffect(
    () => {
      if (connectedToNewSocket && connectedToNewSocket !== previousConnectedToSocket) {
        setupSocket(projectId, workspaceId);
      }
    },
    [connectedToNewSocket, previousConnectedToSocket, projectId, workspaceId, setupSocket],
  );

  useEffect(() => {
    if (workspaceId){
      const onViewerMouseOver = (coordinates: Array<number>) => {
        setCurrentViewerCoordinates(coordinates);
      };

      const onMeshBaseMapProjectionNotSupported = () => {
        if (!basemapNotSupportedToastOnce) {
          store.dispatch({
            type: 'toast/ADD/NOTICE',
            toast: { text: t('BASEMAP_PROJECTION_NOT_SUPPORTED') },
          });
          // Show this warning only once:
          basemapNotSupportedToastOnce = true;
        }
        // TODO: joel; Move to SRS check, because ideally whether maptools work or not, is not related to if the base-map fails.
        store.dispatch({
          type: EMapToolActionType.DISALLOW_MAPTOOLS,
        });
        store.dispatch({
          type: EMapToolActionType.SET_VIEWER_BASEMAP_SUPPORTED,
          data: false,
        });
        // Set to no base-map to avoid attributions, errors etc:
        store.dispatch({
          type: EMapToolActionType.SET_BASEMAP,
          data: '',
        });
      };

      const onViewerWorkStarted = () => {
        store.dispatch({ type: EViewerCursorActionType.SET_WORKING });
      };

      const onAllViewerWorkEnded = () => {
        store.dispatch({ type: EViewerCursorActionType.UNSET_WORKING });
      };

      const onViewerBoundsChanged = (callData: any) => {
        const { renderer } = getState();
        if (renderer !== callData.pokedRenderer) {
          return;
        }
        store.dispatch({ type: EWorkspaceMeshActionType.CHECK_VIEW_BOUNDS, data: {workspaceId, wait: null} });
      };

      const events = [
        onWorkStarted(onViewerWorkStarted),
        onWorkEnded(onAllViewerWorkEnded),
        onMouseMove(onViewerMouseOver),
        onBaseMapProjectionNotSupported(onMeshBaseMapProjectionNotSupported),
        onBaseMapProjectionFetchFailed(_onBaseMapProjectionFetchFailed),
        onBoundsChanged(onViewerBoundsChanged),
      ];

      return () => {
        events.forEach((unsubscribe) => unsubscribe());
        store.dispatch({ type: WorkspaceActionType.SOCKET_CLOSE });
      };
    }
  }, [workspaceId]);

  const setupMap = useCallback(
    () => {
      const epsgCode = workspace && workspace.epsgCode ? workspace.epsgCode : 4326;
      if (workspace && workspace.proj4String) {
        setCrs(workspace.proj4String, epsgCode);
      } else {
        setEpsgCode(epsgCode);
      }
    },
    [workspace],
  );

  return workspace ? (
    <>
      <MikeVisualizer bounds={workspaceBoundingBox as IViewerBounds} onReady={setupMap} />
      <aside css={legendStyle}>
        <MmgViewerColorLegend />
      </aside>
      <footer css={infoStyle}>
        <MmgViewerInfoBar
          totalGeometriesToLoad={totalGeometriesLoad.length}
          totalVariablesToLoad={totalVariablesToLoad.length}
          totalMeshesToLoad={totalMeshesToLoad.length}
          geometriesLoadedSoFar={drawnWorkspaceGeometries.length}
          variablesLoadedSoFar={drawnWorkspaceVariables.length}
          meshesLoadedSoFar={drawnWorkspaceMeshes.length}
          isWorking={viewerWorking}
          epsgCode={workspace && workspace.epsgCode ? workspace.epsgCode.toString() : ''}
          currentViewerCoordinates={currentViewerCoordinates}
          drawnDataSize={drawnDataSize}
        />
      </footer>
    </>
  ) : null;
};

export default MngViewerConnected;
