import { REPRESENTATION } from '../MikeVisualizer/lib/MikeVisualizerConstants';
import { IRepresentation } from '../MikeVisualizer/lib/models/IRepresentation';
import { IDrawnDataGradientSettings } from '../MikeVisualizer/lib/IMikeVisualizerModels';
import { DEFAULT_VARIABLE_ELEVATION_PROPERTY } from '../variables/create/variable-draw-constants';

export interface IWorkspaceAttributeSettings {
  name: string;
  isCategorical?: boolean;
  defaultRepresentation?: IRepresentation;
  defaultPointSize?: number;
  defaultGradientSettings?: IDrawnDataGradientSettings;
}

const NODEMARKER_SETTINGS = {
  isCategorical: true,
  defaultRepresentation: REPRESENTATION.POINTS, // todo hevo points+wireframe would be better. Need tome adjustment in shared viewer
  defaultPointSize: 8,
};

/**
 * Contains special settings of attributes. Only non-default settings does have to be defined.
 * Not all attributes are yet prefixed with mmg by the API/ALG.
 *
 */
export const ATTRIBUTE_SETTINGS: Array<IWorkspaceAttributeSettings> = [
  { name: 'mmg_AspectRatio' },
  { name: 'AspectRatio' },

  {
    name: 'mmg_Area',
    defaultGradientSettings: { gradientPreset: 'Red to Blue Rainbow' },
  },
  {
    name: 'Area',
    defaultGradientSettings: { gradientPreset: 'Red to Blue Rainbow' },
  },

  { name: 'mmg_ConditionNumber' },
  { name: 'ConditionNumber' },

  {
    name: 'mmg_Region',
    isCategorical: true,
  },
  {
    name: 'Region',
    isCategorical: true,
  },

  {
    name: 'mmg_ElementType',
    isCategorical: true,
  },

  {
    name: 'ElementType',
    isCategorical: true,
  },

  {
    name: 'mmg_nodeType',
    ...NODEMARKER_SETTINGS,
  },
  {
    name: 'nodeType',
    ...NODEMARKER_SETTINGS,
  },
];

export const NON_CATEGORICAL_MESH_ATTRIBUTES = ATTRIBUTE_SETTINGS.filter((attributeSetting: IWorkspaceAttributeSettings) => !attributeSetting.isCategorical).map((attributeSetting: IWorkspaceAttributeSettings) => attributeSetting.name.toLowerCase())

export const MESH_ATTRIBUTES_TO_BE_FORMATTED = [...NON_CATEGORICAL_MESH_ATTRIBUTES, DEFAULT_VARIABLE_ELEVATION_PROPERTY]