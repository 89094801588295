/**
 * Utils to help with handling HTTP errors and logging.
 *
 * @module HttpUtils
 * @version 2.0.0
 */
import { store } from '../store/store';
import { merge, isString, pick } from 'lodash-es';
import { IHttpError } from '../models/IResponses';
import { trackTrace, trackException } from '../managers/application-insights';
import FeatureFlags from '../app/feature-flags';
import { AxiosError } from 'axios';
import { IMmgConfiguration } from './ConfigurationManager';

/* const appInsightsSeverity = {
  // See: https://github.com/Microsoft/ApplicationInsights-JS/blob/master/JavaScript/JavaScriptSDK.Interfaces/Contracts/Generated/SeverityLevel.ts
  info: 1,
  warning: 2,
  error: 3,
}; */

const getConfigurationPropertiesForLogger = (configuration: IMmgConfiguration) => {
  if (!configuration) {
    return {};
  }

  // we only include the relevant configurations
  const includedConfigurations = pick(configuration, ['oAuth2', 'versionInfo', 'environment', 'connection']);

  const configurationProperties = Object.keys(includedConfigurations).reduce((acc, cur) => {
    const properties = includedConfigurations[cur];

    if (isString(properties)) {
      return {
        ...acc,
        [cur]: properties,
      };
    }

    return {
      ...acc,
      ...properties,
    };
  }, {});

  return configurationProperties;
};

// Addional to the default log data, route properties & configuration are added to each AppInsights message by default.
export const getCustomLoggerProperties = () => {
  const { AppReducer } = store.getState();

  const routeProperties = AppReducer.routeParams || {};
  const configurationProperties = getConfigurationPropertiesForLogger(AppReducer.configuration);
  // const appProperties = { clientVersion: process.env.VERSION };

  return {
    ...routeProperties,
    ...configurationProperties,
    // ...appProperties,
    ...FeatureFlags,
  };
};

/**
 * Tries to map log methods to application insights.
 * There is no guarantee appInsights is ready when it runs; if it isn't: it doesn't do anything.
 */
const appInsightsLogger = () => {
  return (type: string, message: string, properties: any, error: IHttpError | null) => {
    const customProperties = { ...getCustomLoggerProperties(), ...properties };
    if (type === 'error' && error) {
      const { status, statusText, data } = error.response;
      const { responseURL } = error.request;

      const customErrorProperties = {
        message,
        data,
        responseURL,
        status: `${status.toString()} ${statusText}`,
        ...customProperties,
      };
 

      if (trackException){
        trackException(
          {exception: new Error(customErrorProperties.status)},        
          customErrorProperties
        );
      }
    } else {
      if (trackTrace){
        trackTrace({message}, customProperties);
      }      
    }
  };
};

/**
 * A console and custom logger wrapper.
 *
 * Logs a message to the console and a custom logger of choice.
 * We're using application insights at the moment.
 */
function ConsoleWrapper() {
  const customLogger = appInsightsLogger();

  const send = (type, message, properties, error) => {
    switch (type) {
      case 'warn':
      case 'error':
        console[type](message, properties, error);
        break;
      default:
        break;
    }
    customLogger(type, message, properties, error);
  };

  return {
    log: (message: string, properties?: any) => send('log', message, properties, null),
    info: (message: string, properties?: any) => send('info', message, properties, null),
    warn: (message: string, properties?: any) => send('warn', message, properties, null),
    error: (message: string, apiError: IHttpError, properties?: any) => send('error', message, properties, apiError),
  };
}

export const logger = ConsoleWrapper();

/**
 * Handles HTTP API errors.
 *
 * @param message Plain, humanly-readable message.
 * @param apiError
 */
export function HttpErrorHandler(message: string, apiError: AxiosError) {
  console.error(apiError); // Log original error message no matter what. Sometimes errors that happen in managers are not related to API calls.
   const apiErrorTemplate: IHttpError = {    
    message: '',
    response: {
      status: 0,
      statusText: '',
      data: '',
    },
    request: {
      responseURL: '',
    },
  };

  if (apiError && !apiError.response) {
    // For consistency, error messages without a 'response' property (i.e. websocket, preflight) are merged into a generic error message.
    const genericError = {
      message: message + ' / API cannot be reached.',
      response: {
        status: 400,
        statusText: 'Bad Request',
      },
      request: {},
    };

    const error = merge(apiErrorTemplate, genericError);
    logger.error(message, error);

    throw error;
  } else {
    const error = merge(apiErrorTemplate, apiError);
    logger.error(message, error);

    throw error;
  }
}
