import ConfigurationManager from './ConfigurationManager';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

/**
 * Injects application insights in the front-end app.
 */
//const appInsights = require('applicationinsights-js').AppInsights;
const parameters = {
  disableAjaxTracking: true,
  disableExceptionTracking: false,
  verboseLogging: true,
};
const mode = import.meta.env.MODE;
const applicationInsightsEnabled = mode === 'production' && !window.location.origin.includes("localhost");
let appInsights: ApplicationInsights;
if (applicationInsightsEnabled) {
  /**
   * Get configuration async and setup app insights.
   * Logging can happen before this (seems like the logged events get stored and set when setup is done).
   */
  ConfigurationManager.getConfiguration()
    .then((config) => {
      if (config && config.applicationInsights) {
        const { instrumentationKey } = config.applicationInsights;
        const insights = new ApplicationInsights({ config: {
          instrumentationKey,
          ...parameters,
        } });
        insights.loadAppInsights();
        insights.trackPageView();
        appInsights = insights
      }
      else{
        throw new Error('No instrumentation key');
      }
    })
    .catch((error) => {
      console.error('Failed to setup application insights', error);
    });
}

/**
 * Gets current application insights context.
 */
export function getContext() {
  return appInsights && appInsights.context;
}

/**
 * Tracks a page view.
 *
 * @param name Page name
 */
export function trackPageView(name: string) {
  appInsights && appInsights.trackPageView(
    {name}
  );
}

/**
 * Sets the authenticated user context. This makes it easier to track errors to a user.
 * @see https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#setauthenticatedusercontext
 *
 * @param userId
 */
export function setAuthenticatedUserContext(userId: string) {
  appInsights && appInsights.setAuthenticatedUserContext(userId, null, true); // todo hevo: would it make sense to log accountId as tenantId/clientId instead of null ?
}

/**
 * Re-export appInsights methods to be used by other modules.
 */
export const trackException = applicationInsightsEnabled ? appInsights && appInsights.trackException ? appInsights.trackException : () => null  : () => null;
export const trackEvent = applicationInsightsEnabled ? appInsights && appInsights.trackEvent ? appInsights.trackEvent : () => null  : () => null;
export const trackTrace = applicationInsightsEnabled ? appInsights && appInsights.trackTrace  ? appInsights.trackTrace : () => null  : () => null;
