import { useMemo } from 'react';
import { MmgConnectedLayer } from '../../shared/layers/mesh-layer';
import { MmgConnectedLayerVariableActions } from '../../variables/list/actions/layer-variable-actions';
import { MikeConnectedLayerOpenActionButton } from '../../shared/layers/actions/layer-open-action-button';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import VariableIconUtils from '../../variables/variable-icon-utils';
import { EElementCategories, ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import LayerUtils from '../../shared/layers/layer-utils';
import { getVariableDescription } from '../../shared/panels/panel-utils';
import { MmgLayerError } from '../../shared/layers/mesh-layer-error';
import { IProject } from '../../models/IProject';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../store/reducers';
import { MikeLayerColorComplication } from '../../shared-components/mike-layer/complications/layer-color-complications';
import { MikeLayerWorking } from '../../shared-components/mike-layer/layer-working';
const { getIcon } = VariableIconUtils;
const { isLayerWorking, isLayerFailed, isLayerHidden, getLayerSurfaceColor, getLayerEdgeColor } = LayerUtils;

type VariableListItemProps = {
  variable: IWorkspaceEnrichedVariable;
  hiddenVariables: Array<string>;
  drawnVariables: Array<IDrawnDataItem>;
  onVariableEdit: (variableId: string) => void;
  onVariableOpenPressed: (variableId: string) => void;
};

/**
 * @name MmgVariableListItem
 * @summary An item in a variable list.
 *
 * @param props
 */
export function MmgVariableListItem(props: VariableListItemProps) {
  const { variable, drawnVariables, hiddenVariables } = props;
  const { id, name, message } = variable;
  const isWorking = isLayerWorking(variable, drawnVariables);
  const project: IProject | null = useSelector((state: IGlobalState) => state.ProjectReducer.project);
  const canUpdateWorkspace = useMemo(
    () => {  
      return project && project.capabilities && project.capabilities.canUpdateContent;      
    },
    [project],
  );
  // const [isHovering, setIsHovering] = useState(false);

  const drawnData = useMemo(
    () => {
      if (drawnVariables && id) {
        return drawnVariables.find((d: IDrawnDataItem) => d.id === id);
      } else {
        return undefined;
      }
    },
    [drawnVariables, id],
  );

  /**
   * Gets common right actions for variable layers.
   */
  const getCommonRightActions = () => {
    return (
      <>
        {canUpdateWorkspace && (
          <MmgConnectedLayerVariableActions
            variableDrawnData={drawnData}
            layerId={id}
            variable={variable}
            updateUserSettingsOnToggle={true}
            onVariableEdit={props.onVariableEdit}
          />
        )}
        <MikeConnectedLayerOpenActionButton layerId={id} layerCategory={ELEMENT_CATEGORIES.VARIABLE} />
      </>
    );
  };

/*   const handleLayerMouseEnter = () => {
    setIsHovering(true);
  };

  const handleLayerMouseLeave = () => {
    setIsHovering(false);
  }; */

  const DefaultLayer = () => {
    return (
      <MmgConnectedLayer
        layerId={id}
        {...baseLayerProperties}
        leftIcon={getIcon(variable)}
        leftComplications={
          <MikeLayerColorComplication
            edgeColor={getLayerSurfaceColor(variable, drawnVariables)}
            surfaceColor={getLayerEdgeColor(variable, drawnVariables)}
          />
        }
        showTooltip={true}
        type={EElementCategories.VARIABLE}
/*         onLayerMouseEnter={handleLayerMouseEnter}
        onLayerMouseLeave={handleLayerMouseLeave} */
        showVisibilityIcon={true}
        // hovering={isHovering}
      />
    );
  };

  const baseLayerProperties = {
    layerId: id,
    layerName: name,
    layerDescription: getVariableDescription(variable),
    rightActions: getCommonRightActions(),
    // isLayerFaded: isLayerHidden(ELEMENT_CATEGORIES.VARIABLE, variable.id, hiddenVariables),
    onLayerNamePressed: props.onVariableOpenPressed,
  };

  if (isLayerFailed(variable)) {
    return <MmgLayerError {...baseLayerProperties} layerDescription={message} />;
  }

  if (isWorking && isLayerHidden(ELEMENT_CATEGORIES.VARIABLE, variable.id, hiddenVariables)) {
    return <DefaultLayer />;
  }

  if (isWorking) {
    return <MikeLayerWorking {...baseLayerProperties} />;
  }

  return <DefaultLayer />;
}
