/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
;
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from '../../translations/i18n';
import WorkspaceManager from '../../managers/WorkspaceManager';
import { IWorkspaceDataset } from '../../models/IWorkspaces';
import { store } from '../../store';
import { WorkspaceActionType } from '../store/WokspaceActionType';
import { DATASET_TYPES, DEFAULT_USAGE_TYPE, IUsageType } from '../../project-datasets/mesh-project-dataset-constants';
import { MmgProjectPermissionsMessage } from '../../projects/permissions/project-permission-message';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import MmgProjectContent from '../../project-datasets/project-content';
import MmgProjectContentButton from '../../project-datasets/project-content-button';
import MmgProjectContentExplorer from '../../project-datasets/project-content-explorer';
import { EDATASETUSAGE_TYPE, IGetDataset } from '../../models/IGetDataset';
import { MmgGroup } from '../../shared/groups/group';
import { EWorkspaceActionType } from '../../store/actions/WorkspaceActionType';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';
import { IGlobalState } from '../../store/reducers';
import MikeButton from '../../shared-components/mike-button';

type IProps = {
  onWorkspaceUpdated: (projectId: string, workspaceId: string) => void;
  onWorkspaceDeleted: (projectId: string, workspaceId: string) => void;
};

const buttonContainerStyle = css`
  display: flex;
  justify-content: space-between;
`;

const WorkspaceImportDatasetTitleStyle = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding-left: ${mikeSharedTheme.spacing(2)};
  text-transform: none;
`;

const separatorStyle = css`
  padding-left: ${mikeSharedTheme.spacing(1)};
`;

const titleStyle = css`
  padding-bottom: ${mikeSharedTheme.spacing(0.5)};
`;

/**
 * @name MmgWorkspaceEdit
 * @param props
 * @summary Component rendering the settings of a workspace
 *
 */
const MmgWorkspaceEdit = (props: IProps) => {
  const { onWorkspaceDeleted } = props;
  const { projectId, workspaceId } = useParams();
  const navigate = useNavigate();
  const { project } = useSelector((state: IGlobalState) => state.ProjectReducer);
  const currentWorkspace = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspace);
  const loadingWorkspace = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspaceLoading);
  const updatingWorkspace = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspaceUpdating);

  const [workspaceName, setWorkspaceName] = useState('');
  const [workspaceDescription, setWorkspaceDescription] = useState('');
  const [selectedDatasetUsageTypes, setSelectedDatasetUsageTypes] = useState(Array<IUsageType>());
  const [isDatasetConfigurationComplete, setIsDatasetConfigurationComplete] = useState(true);
  const [isDeleteConfirmationHidden, setIsDeleteConfirmationHidden] = useState(true);
  const [deletingWorkspace, setDeletingWorkspace] = useState(false);

  // on change of project check access rights
  const canUpdateContent = useMemo(
    () => {
      return project && project.capabilities && project.capabilities.canUpdateContent;
    },
    [project],
  );

  // on change of workspaceId load workspace
  useEffect(
    () => {
      if (workspaceId) {
        store.dispatch({ type: WorkspaceActionType.LOAD_WORKSPACE, data: { workspaceId, navigate } });
      }
    },
    [navigate, workspaceId],
  );

  // on change of workspace load usage types
  useEffect(
    () => {
      if (currentWorkspace) {
        setWorkspaceName(currentWorkspace.name ? currentWorkspace.name : '');
        setWorkspaceDescription(currentWorkspace.description ? currentWorkspace.description : '');
      }
    },
    [currentWorkspace],
  );

  const handleWorkspaceNameChange = (event) => {
    setWorkspaceName(event.target.value);
  };

  const handleWorkspaceDescChange = (event) => {
    setWorkspaceDescription(event.target.value);
  };

  /**
   * Callback for when a dataset type is updated or a dataset is deleted.
   *
   * @param newUsageTypes
   */
  const onDatasetsToBeImportedChanged = (newUsageTypes: Array<IUsageType>) => {
    if (currentWorkspace) {
      setSelectedDatasetUsageTypes(newUsageTypes);
      setIsDatasetConfigurationComplete(newUsageTypes.findIndex((dataset) => !dataset.usageType) === -1);
    }
  };

  const updateWorkspace = (e) => {
    e.preventDefault();

    const newWorkspaceDatasets: Array<IWorkspaceDataset> = selectedDatasetUsageTypes.map((usageType: IUsageType) => {
      return {
        id: usageType.datasetId,
        projectId: usageType.projectId, // todo sku: ask Kai if projectId is still needed as Get dataset request does not require a projectId
        usageType: usageType.usageType,
        spatialInformation: usageType.spatialInformation,
      };
    });
    const updatedWorkspace = {
      ...currentWorkspace,
      name: workspaceName,
      description: workspaceDescription,
      datasets: newWorkspaceDatasets,
    };   
    store.dispatch({ type: WorkspaceActionType.UPDATE_WORKSPACE, data: { workspace: updatedWorkspace, navigateToWorkspacePanel: true, navigate } });
  };

  const openDeleteConfirmationDialog = () => {
    setIsDeleteConfirmationHidden(false);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    if (confirmed) {
      deleteWorkspace();
    }
    setIsDeleteConfirmationHidden(true);
  };

  const deleteWorkspace = useCallback(
    () => {
      setDeletingWorkspace(true);

      WorkspaceManager.deleteWorkspace(workspaceId)
        .then(() => {
          const toast = {
            text: t('DELETED', 1, { thing: currentWorkspace.name }),
          };

          store.dispatch({ type: 'workspace/recent/DELETE', workspaceId });
          store.dispatch({ type: 'toast/ADD/SUCCESS', toast });
          store.dispatch({ type: EWorkspaceActionType.CLOSE }); // After deletion, it is important to close (effectively clear) the workspace & its related members

          onWorkspaceDeleted(projectId, workspaceId);
        })
        .catch((error) => {
          const toast = {
            text: t('FAILED_TO', 1, {
              action: t('DELETE', 1, { thing: t('WORKSPACE') }),
            }),
            operationId: error.operationId,
          };
          store.dispatch({ type: 'toast/ADD/ERROR', toast });
        })
        .finally(() => {
          setDeletingWorkspace(false);
        });
    },
    [currentWorkspace, onWorkspaceDeleted, projectId, workspaceId],
  );

  const handleConfirmDeleteDialogClose = () => {
    setIsDeleteConfirmationHidden(true);
  };

  const handleCancel = () => {
    setIsDeleteConfirmationHidden(true);
  };

  const canReadContent = project && project.capabilities && project.capabilities.canReadContent;

  const updateIsAllowed =
    !updatingWorkspace && !deletingWorkspace && isDatasetConfigurationComplete && canUpdateContent;

  /**
   * Callback for when datasets to be imported are added via project explorer.
   *
   * @param selectedItems
   */
  const onAddDatasetsToBeImported = useCallback(
    (selectedItems: Array<IGetDataset>) => {
      const newUsageTypes: IUsageType[] = selectedItems.map((item: IGetDataset) => {
        // set type if it can be inferred from the datasetType
        let newUsageType = DEFAULT_USAGE_TYPE;

        if (item.usageTypes.length === 1) {
          newUsageType = item.usageTypes[0];
        } else {
          if (item.datasetType === DATASET_TYPES.GISVECTORDATA) {
            newUsageType = EDATASETUSAGE_TYPE.Geometry;
          } else if (item.datasetType === DATASET_TYPES.MESHDATA || item.datasetType === DATASET_TYPES.THREEDMESH) {
            newUsageType = EDATASETUSAGE_TYPE.Mesh;
          }
        }
        return {
          datasetId: item.id,
          usageType: newUsageType,
          name: item.name,
          spatialInformation: item.spatialInformation ? item.spatialInformation : {},
          projectId: item.projectId,
          datasetUsageTypes: item.usageTypes,
        };
      });

      const mergedUsageTypes = selectedDatasetUsageTypes.concat(newUsageTypes);
      setSelectedDatasetUsageTypes(mergedUsageTypes);
      setIsDatasetConfigurationComplete(mergedUsageTypes.findIndex((dataset) => !dataset.usageType) === -1);
    },
    [selectedDatasetUsageTypes],
  );

  return loadingWorkspace ? (
    <MmgPanelSubsection>
      <Typography css={titleStyle} variant={'body2'}>
        {t('LOADING', 1, { thing: t('WORKSPACE') })}
      </Typography>
      <div>
        <CircularProgress />
      </div>
    </MmgPanelSubsection>
  ) : (
    <>
      {projectId &&
        canUpdateContent && <MmgProjectContentExplorer onSelectedDatasetsChanged={onAddDatasetsToBeImported} />}
      <Dialog
        open={!isDeleteConfirmationHidden}
        onClose={handleConfirmDeleteDialogClose}
        id="confirm-delete-workspace"
        aria-labelledby="delete-workspace-dialog-title"
        aria-describedby="delete-workspace-dialog-description"
      >
        <DialogTitle id="delete-workspace-dialog-title" /* disableTypography={false} */>
          {t('WORKSPACE_CONFIRM_DELETE_TITLE')}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="delete-mesh-dialog-description">
            {workspaceName
              ? t('WORKSPACE_CONFIRM_DELETE_TEXT', 1, {
                  name: workspaceName,
                })
              : t('WORKSPACE_CONFIRM_DELETE_TEXT_NO_NAME')}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <MikeButton onClick={handleCancel} color="secondary" variant="outlined">
            {t('CANCEL')}
          </MikeButton>

          <MikeButton onClick={() => onDeleteConfirmed(true)} color="secondary" variant="contained" autoFocus>
            {t('DELETE')}
          </MikeButton>
        </DialogActions>
      </Dialog>
      {project && !canReadContent && <MmgProjectPermissionsMessage />}
      <MmgPanelSubsection>
        <TextField
          id="workspacename"
          label={`${t('WORKSPACE_NAME_LABEL')}`}
          value={workspaceName}
          onChange={handleWorkspaceNameChange}
          autoComplete="off"
          fullWidth={true}
          placeholder={`${t('TYPE', 1, { thing: t('NAME') })}`}
        />
        <TextField
          id="workspacedesc"
          label={t('WORKSPACE_DESCRIPTION_LABEL')}
          value={workspaceDescription}
          onChange={handleWorkspaceDescChange}
          autoComplete="off"
          fullWidth={true}
          placeholder={`${t('TYPE', 1, { thing: t('DESCRIPTION') })}`}
        />
        <MmgProjectContentButton />
      </MmgPanelSubsection>

      {selectedDatasetUsageTypes.length > 0 ? (
        <MmgGroup
          groupName={
            <span css={WorkspaceImportDatasetTitleStyle}>
              <span>{t('WORKSPACE_CREATE_IMPORT_FILES')}</span>
              <span css={separatorStyle}>{t('WORKSPACE_CREATE_FILE_TYPES')}</span>
            </span>
          }
          canBeHidden={false}
        >
          <MmgPanelSubsection>
            <MmgProjectContent
              selectedDatasetUsageTypesChanged={onDatasetsToBeImportedChanged}
              selectedDatasetUsageTypes={selectedDatasetUsageTypes}
            />
          </MmgPanelSubsection>
        </MmgGroup>
      ) : null}
      <MmgPanelSubsection>
        <div css={buttonContainerStyle}>
          <MikeButton
            disabled={!canUpdateContent}
            active={deletingWorkspace}
            onClick={openDeleteConfirmationDialog}
            color="secondary"
            variant="outlined"
          >
            {t('DELETE', 1, { thing: t('WORKSPACE') })}
          </MikeButton>
          <MikeButton
            disabled={!updateIsAllowed}
            active={updatingWorkspace}
            onClick={updateWorkspace}
            color="secondary"
            variant="contained"
          >
            {t('UPDATE')}
          </MikeButton>
        </div>
      </MmgPanelSubsection>
    </>
  );
};

export default MmgWorkspaceEdit;
