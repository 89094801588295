/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';
import { t } from '../../translations/i18n';
import { Feature, FeatureCollection } from 'geojson';
import { TextField, TextFieldProps, Typography } from '@mui/material';
import { fetchLayer, showLayer } from '../../shared/layers/layer-utils';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import { EMapToolActionType } from '../../store/actions/MapToolActionType';
import { EWorkspaceActionType } from '../../store/actions/WorkspaceActionType';
import { useParams } from 'react-router-dom';
import EditModes, { EDITMODE_IDS, EEditModeIds } from '../../shared/edit-modes/edit-modes';
import MmgSelectForEditingModes, { ESelectForEditingModeIds } from '../../shared/edit-modes/select-for-editing-modes';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import WorkspaceVariableSelectors from '../../store/selectors/WorkspaceVariableSelectors';
import {
  clearFeaturesForEditing,
  createVariable,
  getFeaturesForEditing,
  setEditMode,
  updateValue,
  updateVariableData,
} from '../../store/actions/editingItems';
import { EItemType } from '../../models/IOperationDescriptions';
import { MmgKeyboardEditHelp } from '../../shared/edit-modes/keyboard-edit-help';
import { EDIT_LAYER_ID } from '../../store/reducers/EditReducer';
import { CONFIRM_PANEL_IDS } from '../../shared/confirm-containers/confirm-constants';
import { MmgGroup } from '../../shared/groups/group';
import {
  ContentBottomContainerStyle,
  ContentBottomDescriptionStyle,
  PanelBottomActionsStyle,
  PanelBottomContainerStyle,
  PanelBottomDescriptionStyle,
  buttonStyle,
  descriptionWithHelpStyle,
  fieldsStyle,
  outerPaddingStyle,
} from '../../shared/edit-modes/editStyles';
import { EGeometryItemTypes } from '../../models/IGeometries';
import { previousEditsToPatchFeatures } from '../../workspaces/sagas/meshEditHelpers';
import { CELLDATA, roundValue } from '../create/variable-draw-constants';
import { translateWithPrefix } from '../../translations/utils';
import MikeVisualizer from '../../MikeVisualizer';
import { IGlobalState } from '../../store/reducers';
import MikeVisualizer2DDrawCore from '../../MikeVisualizer/lib/2d/draw/MikeVisualizer2DDrawCore';
import MikeVisualizer2DDataCore from '../../MikeVisualizer/lib/2d/data/MikeVisualizer2DDataCore';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';
import MikeStickyPanelContent from '../../shared-components/mike-sticky-panel/MikeStickyPanelContent';
import MikeButton from '../../shared-components/mike-button';

const {
  onDrawnDataUpdated,
  onDrawingInProgressChanged,
  clearDrawnVectorLayerData,
  disable2DPolygonSelection,
  delete2DData,
} = MikeVisualizer;

/**
 * @name MmgConnectedVariableEditDataConfirm Allows updating (confirming) a variable drawing.
 * @summary It can either update or cancel a drawing of an existing variable.
 *
 */
export function MmgConnectedVariableEditDataConfirm() {
  const getDrawnDataSelectorInstance = WorkspaceVariableSelectors.makeGetVariableDrawnData();
  const { workspaceId } = useParams();
  const activeConfirmPanelId = useSelector((state: IGlobalState) => state.ConfirmPanelReducer.activeConfirmPanelId);
  const loadedData: Array<string> = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.loadedData);
  const { variableEditTargetId } = useSelector((state: IGlobalState) => state.VariableMapToolReducer);
  const variableDrawnData: IDrawnDataItem = useSelector((state: IGlobalState) =>
    getDrawnDataSelectorInstance(state, {
      variableId: variableEditTargetId,
    }),
  );

  const {
    featuresForEditing,
    loadingFeaturesForEditing,
    previousEdits,
    editsToApply,
    propertiesForEditing,
    editMode,
    selectionMode,
  } = useSelector((state: IGlobalState) => state.EditReducer);

  const { updatingVariableData } = useSelector((state: IGlobalState) => state.WorkspaceVariableReducer);

  const createNewVariable = useMemo(
    () => {
      return activeConfirmPanelId && activeConfirmPanelId === CONFIRM_PANEL_IDS.VARIABLE_DRAW_NEW;
    },
    [activeConfirmPanelId],
  );

  const [drawingInProgress, setDrawingInProgress] = useState(false);

  const hasInputError = false;

  useEffect(() => {
    const drawUpdateCallback = (newFeatureCollection: FeatureCollection<any, any>) => {
      if (newFeatureCollection.features && newFeatureCollection.features.length > 0) {
        store.dispatch({ type: EMapToolActionType.VALIDATE_CHANGED_VARIABLE_POINTS, data: newFeatureCollection });
      }
    };

    const unsubscribers = [];
    const unsubscribeOnDrawnDataUpdated = onDrawnDataUpdated(drawUpdateCallback);
    const unsubscribeOnDrawingInProgressChanged = onDrawingInProgressChanged(drawingProgressChangedCallback);
    unsubscribers.push(unsubscribeOnDrawingInProgressChanged, unsubscribeOnDrawnDataUpdated);

    return () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
      // on unmount we reset to default tools, cancel drawing and remove any drawn items.
      store.dispatch({ type: EMapToolActionType.RESET_ALLOWED_TOOLS });
      store.dispatch({ type: EMapToolActionType.VARIABLES_UNLOAD });
      store.dispatch({ type: EMapToolActionType.DISABLE_ALL_DRAWING_TOOLS });
      store.dispatch(clearFeaturesForEditing());
      delete2DData(EDIT_LAYER_ID);
    };
  }, []);

  useEffect(
    () => {
      if (createNewVariable) {
        store.dispatch({ type: EMapToolActionType.SET_PROPERTIES_FOR_FEATURES_FOR_CREATING });
        store.dispatch(setEditMode(EEditModeIds.EDIT_MODE_ADD, EGeometryItemTypes.POINT, EItemType.VARIABLE));
      } else {
        const initializeOpenLayersMapsAndeditMode = async () => {
          clearDrawnVectorLayerData();
          // These open layers maps are required for drawing the selection geometry
          const { _getOrSetupDrawMap } = MikeVisualizer2DDrawCore;
          const { _getOrSetupOpenLayersDataMap } = MikeVisualizer2DDataCore;
          await _getOrSetupDrawMap();
          await _getOrSetupOpenLayersDataMap();
          store.dispatch({
            type: EMapToolActionType.SET_SELECTION_MODE,
            data: ESelectForEditingModeIds.SELECT_BY_RECTANGLE,
          });
          store.dispatch(setEditMode(EEditModeIds.EDIT_MODE_MODIFY, EGeometryItemTypes.POINT, EItemType.VARIABLE));
        };

        initializeOpenLayersMapsAndeditMode();
      }
    },
    [createNewVariable],
  );

  useEffect(
    () => {
      if (variableDrawnData && variableDrawnData.dataArrays && !createNewVariable) {
        store.dispatch({
          type: EMapToolActionType.SET_PROPERTIES_FOR_FEATURES_FOR_EDITING,
          data: { dataArrays: variableDrawnData.dataArrays, type: CELLDATA },
        });
      }
    },
    [createNewVariable, variableDrawnData],
  );

  const selectedFeaturesCount = useMemo(
    () => {
      return featuresForEditing && featuresForEditing.features ? featuresForEditing.features.length : 0;
    },
    [featuresForEditing],
  );

  const shouldDisableConfirmButton = useMemo(
    () => {
      return previousEdits.length === 0 || updatingVariableData || drawingInProgress || hasInputError;
    },
    [drawingInProgress, hasInputError, updatingVariableData, previousEdits],
  );

  /**
   * Confirms drawing and updates variable from the drawn items.
   */
  const confirmDrawing = useCallback(
    () => {
      if (createNewVariable) {
        store.dispatch(createVariable(workspaceId, variableEditTargetId, previousEditsToPatchFeatures(previousEdits)));
      } else {
        store.dispatch(
          updateVariableData(workspaceId, variableEditTargetId, previousEditsToPatchFeatures(previousEdits)),
        );
      }
    },
    [createNewVariable, previousEdits, variableEditTargetId, workspaceId],
  );

  /**
   * Exits current panel - further cleanups are done in useEffect
   */
  const cancelDrawing = () => {
    store.dispatch({ type: EWorkspaceActionType.EXIT_ACTIVE_PANEL });
  };

  /**
   * Callback for when drawing in progress changes.
   *
   * @param inProgress
   */
  const drawingProgressChangedCallback = (inProgress: boolean) => {
    setDrawingInProgress(inProgress);
  };

  /**
   * Callback for when a property-to-append value has changed.
   *
   * @param propertyKey
   */
  const onPropertyToAppendValueChanged = (propertyKey: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    store.dispatch(updateValue(propertyKey, value));
  };

  useEffect(
    () => {
      if (createNewVariable || !variableEditTargetId) {
        return;
      }
      if (!loadedData.includes(variableEditTargetId)) {
        fetchLayer(variableEditTargetId, ELEMENT_CATEGORIES.VARIABLE);
      } else {
        showLayer(ELEMENT_CATEGORIES.VARIABLE, variableEditTargetId, false);
      }
    },
    [createNewVariable, loadedData, variableEditTargetId],
  );

  const handleChangeEditMode = async (mode: EEditModeIds) => {
    clearDrawnVectorLayerData();
    if (mode !== EEditModeIds.EDIT_MODE_ADD) {
      store.dispatch({
        type: EMapToolActionType.SET_SELECTION_MODE,
        data: ESelectForEditingModeIds.SELECT_BY_RECTANGLE,
      });
    }
    store.dispatch(setEditMode(mode, EGeometryItemTypes.POINT, EItemType.VARIABLE));
  };
  const handleChangeSelectForEditingMode = (mode: ESelectForEditingModeIds) => {
    store.dispatch({ type: EMapToolActionType.SET_SELECTION_MODE, data: mode });
  };

  const handleSelectionGeometryChanged = useCallback(
    async (feature: Feature<any>) => {
      store.dispatch({ type: EMapToolActionType.SET_SELECTION_MODE, data: null });
      disable2DPolygonSelection();
      if (selectionMode) {
        clearDrawnVectorLayerData();
        // Gets data corresponding to the target variable and selection geometry and sets it as drawn geojson.
        store.dispatch(
          getFeaturesForEditing(
            workspaceId,
            variableEditTargetId,
            EItemType.VARIABLE,
            { type: 'FeatureCollection', features: [feature] },
            previousEdits,
            EGeometryItemTypes.POINT,
          ),
        );
      }
    },
    [previousEdits, selectionMode, variableEditTargetId, workspaceId],
  );

  const handleUndo = () => {
    store.dispatch({ type: EMapToolActionType.UNDO_FEATURES_FOR_EDITING, data: EGeometryItemTypes.POINT });
  };

  const handleApply = useCallback(
    () => {
      if (editMode === EEditModeIds.EDIT_MODE_ADD) {
        store.dispatch({ type: EMapToolActionType.ADD_DRAWN_FEATURES, data: EGeometryItemTypes.POINT });
      } else {
        store.dispatch({ type: EMapToolActionType.STORE_FEATURES_FOR_EDITING, data: EGeometryItemTypes.POINT });
      }
    },
    [editMode],
  );

  const getFields = useCallback(
    () => {
      const commonTextFieldProps = {
        margin: 'normal',
        fullWidth: true,
      } as TextFieldProps;
      return (
        <div css={fieldsStyle}>
          {Object.keys(propertiesForEditing).map((key) => {        
            const value = roundValue(propertiesForEditing[key], key);
            const isValueMixed = value && value.toString().startsWith('range');
            const niceLabel = translateWithPrefix('PROP', key);
            let label = t('VARIABLE_DRAW_PROPERTY_LABEL', 1, { property: niceLabel });
            if (isValueMixed) {
              label = label + ' ' + value;
            }
            return (
              <TextField
                disabled={editMode !== EEditModeIds.EDIT_MODE_ADD && selectedFeaturesCount === 0}
                label={label}
                value={!isValueMixed ? value : ''}
                placeholder={isValueMixed ? label : ''}
                onChange={onPropertyToAppendValueChanged(key)}
                key={key}
                {...commonTextFieldProps}
              />
            );
          })}
        </div>
      );
    },
    [propertiesForEditing, selectedFeaturesCount, editMode],
  );

  const getApplyTitle = useCallback(
    () => {
      switch (editMode) {
        case EEditModeIds.EDIT_MODE_MODIFY:
          return t('MOVE_VARIABLE_POINTS_APPLY');
        case EEditModeIds.EDIT_MODE_ATTRIBUTION:
          return t('ATTRIBUTION_VARIABLE_POINTS_APPLY');
        case EEditModeIds.EDIT_MODE_ADD:
          return createNewVariable
            ? t('ADD_VARIABLE_POINTS_APPLY') + '.'
            : t('ADD_VARIABLE_POINTS_APPLY') + ' ' + t('OR_IN_OTHER_EDITMODE') + '.';
        case EEditModeIds.EDIT_MODE_DELETE:
          return t('DELETE_VARIABLE_POINTS_APPLY');
      }
    },
    [editMode, createNewVariable],
  );

  const getApplyGroupTitle = useCallback(
    () => {
      switch (editMode) {
        case EEditModeIds.EDIT_MODE_MODIFY:
          return t('CHANGE_VARIABLE_POINTS_GROUP_MODIFY');
        case EEditModeIds.EDIT_MODE_ATTRIBUTION:
          return t('CHANGE_VARIABLE_POINTS_GROUP_ATTRIBUTION');
        case EEditModeIds.EDIT_MODE_ADD:
          return t('CHANGE_VARIABLE_POINTS_GROUP_ADD');
        case EEditModeIds.EDIT_MODE_DELETE:
          return t('CHANGE_VARIABLE_POINTS_GROUP_DELETE');
      }
    },
    [editMode],
  );

  const disableApplyButton = useCallback(
    () => {
      if (editMode === EEditModeIds.EDIT_MODE_DELETE) {
        return selectedFeaturesCount === 0;
      }
      return !editsToApply;
    },
    [editMode, editsToApply, selectedFeaturesCount],
  );

  const getEditInstructionsText = useCallback(
    () => {
      if (editMode === EEditModeIds.EDIT_MODE_MODIFY) {
        return (
          <Typography variant="body2" css={outerPaddingStyle}>
            {t('MOVE_VARIABLE_POINTS')}
          </Typography>
        );
      } else {
        return null;
      }
    },
    [editMode],
  );

  return (
    <MikeStickyPanel>
      <MikeStickyPanelHeaderContainer>
        <Typography css={outerPaddingStyle} variant="h4">
          {createNewVariable ? t('VARIABLE_DRAW_CONFIRMATION_TITLE') : t('VARIABLE_EDIT_CONFIRMATION_TITLE')}
        </Typography>
        <EditModes
          onEditModeChanged={handleChangeEditMode}
          editMode={editMode as any}
          itemType={EItemType.VARIABLE}
          createNew={createNewVariable}
          disabled={loadingFeaturesForEditing}
        />
      </MikeStickyPanelHeaderContainer>
      <MikeStickyPanelContent>
        {editMode === EDITMODE_IDS.EDIT_MODE_ADD ? (
          <MmgGroup groupName={t('ADD_VARIABLE_POINTS_GROUP')}>
            <div css={descriptionWithHelpStyle}>
              <Typography variant="body2">{t('ADD_VARIABLE_POINTS')}</Typography>
              <MmgKeyboardEditHelp
                editMode={EDITMODE_IDS.EDIT_MODE_ADD}
                itemType={EItemType.VARIABLE}
                geometryType={EGeometryItemTypes.POINT}
              />
            </div>
          </MmgGroup>
        ) : (
          <MmgGroup groupName={t('SELECT_VARIABLE_POINTS_GROUP')}>
            <div css={outerPaddingStyle}>
              <MmgSelectForEditingModes
                onSelectForEditingModeChanged={handleChangeSelectForEditingMode}
                selectionMode={selectionMode}
                onSelectionGeometryChanged={handleSelectionGeometryChanged}
                disabled={loadingFeaturesForEditing}
                itemType={EItemType.VARIABLE}
              />
            </div>
          </MmgGroup>
        )}

        <MmgGroup groupName={getApplyGroupTitle()}>
          <div css={ContentBottomContainerStyle}>
            {[EEditModeIds.EDIT_MODE_ATTRIBUTION, EEditModeIds.EDIT_MODE_ADD].includes(editMode as any)
              ? getFields()
              : getEditInstructionsText()}

            <Typography css={ContentBottomDescriptionStyle} variant="body2">
              {getApplyTitle()}
            </Typography>
            <div css={PanelBottomActionsStyle}>
              <MikeButton
                css={buttonStyle}
                variant="outlined" color="secondary"
                disabled={previousEdits.length === 0}
                onClick={handleUndo}
              >
                {t('MESH_NODE_EDIT_UNDO')}
              </MikeButton>
              <MikeButton
                css={buttonStyle}
                variant="outlined" color="secondary"
                onClick={handleApply}
                disabled={disableApplyButton()}
              >
                {t('APPLY_CHANGES')}
              </MikeButton>
            </div>
          </div>
        </MmgGroup>
      </MikeStickyPanelContent>
      <div css={PanelBottomContainerStyle}>
        <Typography css={PanelBottomDescriptionStyle} variant="body2">
          {createNewVariable ? t('CREATE_VARIABLE_POINTS_SAVE') : t('FINISH_EDIT_SESSION')}
        </Typography>
        <div css={PanelBottomActionsStyle}>
          <MikeButton variant="outlined" color="secondary" onClick={cancelDrawing} disabled={updatingVariableData}>
            {t('CANCEL')}
          </MikeButton>
          <MikeButton variant="contained" color="secondary" disabled={shouldDisableConfirmButton} onClick={confirmDrawing} active={updatingVariableData}>
            {createNewVariable ? t('CONFIRM_CREAING_VARIABLE') : t('CONFIRM_EDITING_VARIABLE')}
          </MikeButton>
        </div>
      </div>
    </MikeStickyPanel>
  );
}
