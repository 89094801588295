import { EProgressItemsActionType } from '../actions/ProgressItemsActionType';
import { IAction } from '../actions/Action';
import { IProgressItem } from '../../shared-components/mike-topbar-progress-spinner';

export interface IProgressItemsState {
  progressItems: IProgressItem[];
}

const initState = {
  progressItems: Array<IProgressItem>()
} 

export default function(state: IProgressItemsState = initState, action: IAction) {
  switch (action.type) {
    case EProgressItemsActionType.UPDATE_OR_ADD_PROGRESS_ITEM: {
      const item = state.progressItems.find((item) => {
        return item.id !== action.data.id;
      });
      if (item !== undefined) {
        const updatedItems = state.progressItems.map((item) => {
          return item.id === action.data.id ? action.data : item
        });
        return {progressItems: [...updatedItems]}
      } else {
        return {progressItems: [...state.progressItems, action.data]}
      }
    }

    case EProgressItemsActionType.REMOVE_PROGRESS_ITEM: {
      const clearedItems = state.progressItems.filter((item) => {
        return item.id !== action.data.id;
      });
      return {progressItems: [...clearedItems]};
    }

    default:
      return state;
  }
}
