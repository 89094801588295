/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useEffect, useMemo } from 'react';
import { IGlobalState } from '../store/reducers';
import { Radio, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { translateWithPrefixAndSuffix } from '../translations/utils';
import Info from '../icons/Info.svg?react';
import { EElementCategories } from '../shared/panels/mesh-panel-constants';
import {
  updateWorkspaceDataZAttributeName,
  updateWorkspaceDataStatisticAttributeName,
  updateWorkspaceDataLabelAttributeName,
} from '../store/actions/workspaceDataActions';
import { IDrawnDataItem } from '../models/IWorkspaceData';
import { t } from '../translations/i18n';
import { getWorkspaceVariableById } from '../store/selectors/WorkspaceVariableSelectors';
import { IDataArrayStatistics } from './mmg-mesh-statistics-container';
import { useParams } from 'react-router-dom';
import { addLabels, deleteLabels, getGeometryLabelStyle } from '../workspaces/viewer/viewer-utils';
import { EViewerModes } from '../MikeVisualizer/lib/IMikeVisualizerModels';
import mikeSharedTheme from '../shared/styles/mikeSharedTheme';
import MIKE_COLORS from '../MikeVisualizer/colors/mike-colors';
import MikeTooltip from '../shared-components/mike-tooltip';

// const { NO_Z_ATTRIBUTE_NAME } = MikeVisualizerLib;
const NO_Z_ATTRIBUTE_NAME = 'NO_Z_VALUES"';

interface MmgStatisticsProps {
  statistics: IDataArrayStatistics;
  renderName?: (name: string, id: string) => void;
  elementId?: string;
  elementCategory?: EElementCategories;
  geometryAttributeLabels?: any;
  layerAttribute?: string;
  isTiled?: boolean;
}

const HEIGHT = mikeSharedTheme.spacing(3.75);

const StatEntryLabelStyle = css`
  flex-shrink: 0;
  margin-right: ${mikeSharedTheme.spacing(2)};
  text-transform: none;
  font-size: 14px;
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
`;

const NameInfoStyle = css`
  display: flex;
  align-items: center;
  font-size: 14px;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: flex-start;
`;

const StatEntryValueStyle = css`
  font-size: 14px;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`;

const StatEntryPStyle = css`
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin: 0;
  width: 100%;
`;

const FlexContainer = css`
  display: flex; 
`;

const PlaceHolderStyle= css`
  width: ${HEIGHT};
  height: ${HEIGHT};
`;

const Column1Style= css`
  width: 40%;
  height: ${HEIGHT};
`;

const Column2Style= css`
  width: 20%;
  height: ${HEIGHT};
`;
const Column3Style= css`
  width: 20%;
  height: ${HEIGHT};
`;
const Column4Style= css`
  width: 20%;
  height: ${HEIGHT};
`;

const radioInputProps = {
  style: {
    height: 3,
    width: 3,
  },
};

const radioStyle = {
    width: 10,
    height: 10,
    '&:after': {
      backgroundColor: 'transparent',
    }  
};

export const MmgConnectedDataStatistics = (props: MmgStatisticsProps) => {
  const {
    statistics,
    renderName,
    elementId = "",
    elementCategory,
    geometryAttributeLabels,
    layerAttribute,
    isTiled,
  } = props;

  const { workspaceId } = useParams<{ workspaceId: string }>();

  const workspaceData: IDrawnDataItem[] = useSelector(
    (state: IGlobalState) => state.WorkspaceDataReducer.workspaceData,
  );

  const { viewerMode } = useSelector(
    (state: IGlobalState) => state.ViewerModeReducer as { viewerMode: EViewerModes; viewerBaseMapId: string },
  );

  const getDataItem = (inId: string) => workspaceData.find(({ id }) => id === inId);

  const dataItem = getDataItem(elementId);

  useEffect(
    () => {
      if (
        viewerMode === EViewerModes.TWO_D &&
        elementCategory === EElementCategories.GEOMETRY &&
        geometryAttributeLabels
      ) {
        const labelsId = `${elementId}_${layerAttribute}`;
        geometryAttributeLabels && addLabels(labelsId, geometryAttributeLabels, getGeometryLabelStyle(elementId) as any);
      }
    /*   return () => {
        deleteLabels(`${elementId}_${layerAttribute}`);
      }; */
    },
    [elementId, geometryAttributeLabels, viewerMode, elementCategory, workspaceId, layerAttribute],
  );

  useEffect(
    () => {
      if (!layerAttribute) {
        deleteLabels(`${elementId}_${layerAttribute}`);
      }
    },
    [elementId, layerAttribute],
  );

  const filteredStats = useMemo(
    () => {
      // TODO: joel; This is a hack to avoid showing duplicates, but the real question is:
      // Why are there duplicates; ie why does 'Elevation' show up twice? (id=mmg_Elevation & id=Elevation).
      // See task for real fix https://dhigroup.visualstudio.com/MIKE/_workitems/edit/45616.
      const statNames: Array<string> = [];
      return statistics.filter(({ name }) => {
        const already = statNames.includes(name);
        statNames.push(name);
        return !already;
      });
    },
    [statistics],
  );

  const showPickZAttribute = useMemo(
    () => {
      const isVariable = elementCategory === EElementCategories.VARIABLE;
      const wsVariable = getWorkspaceVariableById(elementId);
      const isGrid = (wsVariable || {}).isGrid;
      return isVariable && isGrid;
    },
    [elementCategory, elementId],
  );

  const handleStatisticAttributeClick = useCallback(
    (id: string) => () => {
      updateWorkspaceDataStatisticAttributeName(elementId, id, true, isTiled);
    },
    [elementId, isTiled],
  );

  const handleZAttributeClick = useCallback(
    (name: string) => () => {
      const zAttribute = name === null ? NO_Z_ATTRIBUTE_NAME : name;
      updateWorkspaceDataZAttributeName(elementId, zAttribute, true);
    },
    [elementId],
  );

  const handleLabelAttributeClick = useCallback(
    (id: string) => () => {
      updateWorkspaceDataLabelAttributeName(elementId, id, workspaceId);
    },
    [elementId, workspaceId],
  );

  const isZAttrChecked = useCallback(
    (id: string) => {
      const theId = id === null ? NO_Z_ATTRIBUTE_NAME : id;
      if (dataItem && dataItem.zAttributeName) {
        return dataItem.zAttributeName.toLowerCase() === theId.toLowerCase();
      } else {
        return false;
      }
    },
    [dataItem],
  );

  const renderInfoIcon = (id: string) => {
    let translation;
    if (id) {
      translation = translateWithPrefixAndSuffix('PROP', 'HELPTEXT', id);
    }
    let formattedTranslation;
    if (translation) {
      formattedTranslation = translation.replace(/\s+/g, '');
    }

    if (translation === undefined || formattedTranslation === undefined) {
      return <div css={PlaceHolderStyle}/>;
    }

    if (translation === formattedTranslation || formattedTranslation === id) {
      return <div css={PlaceHolderStyle}/>;
    }

    return (
      <MikeTooltip title={translation}>
        <Info width={20} height={20} viewBox={'0 0 30 30'} />
      </MikeTooltip>
    );
  };

  const getSwitchState = useCallback(
    (id) => {
      if (!id && layerAttribute === '') {
        return true;
      } else if (id === layerAttribute) {
        return true;
      } else {
        return false;
      }
    },
    [layerAttribute],
  );

  return (
    <>
      {renderName && (
        <div css={css`${FlexContainer}`}>
          <div css={Column1Style}>{t('STATISTICS_ATTRIBUTE_NAME_HEADER')}</div>
          <div css={Column2Style}>           
            {t('STATISTICS_ATTRIBUTE_COLOR_HEADER')}
          </div>
          {elementCategory === EElementCategories.GEOMETRY && (
            <div css={Column3Style}>            
              {t('STATISTICS_LABELS_HEADER')}
            </div>
          )}
          {showPickZAttribute && (
            <div css={Column4Style}>
              &nbsp;
              {t('STATISTICS_ATTRIBUTE_3D_HEADER')}
            </div>
          )}
        </div>
      )}
      {filteredStats &&
        filteredStats.map(({ name, id, value }) => {
          return (
            <div key={id + value}>
              <div>
                <div css={FlexContainer}>
                  <div css={Column1Style}>
                    <div css={NameInfoStyle}>
                      {name}
                      {renderInfoIcon(id)}
                    </div>
                  </div>
                  <div css={Column2Style}>                 
                    <Radio
                      color="primary"
                      sx={radioStyle}
                      inputProps={radioInputProps}
                      size="small"
                      checked={(dataItem || {}).statisticsAttributeName === id}
                      onClick={handleStatisticAttributeClick(id)}
                    />              
                  </div>
                  {elementCategory === EElementCategories.GEOMETRY && (
                    <div css={Column3Style}>                      
                      <Radio
                        checked={getSwitchState(id)}
                        color="primary"
                        sx={radioStyle}
                        inputProps={radioInputProps}
                        size="small"
                        onClick={handleLabelAttributeClick(id)}
                      />                 
                     </div>
                  )}
                  {showPickZAttribute && (
                    <div css={Column4Style}>                     
                      <Radio
                        onClick={handleZAttributeClick(id)}
                        color="primary"
                        sx={radioStyle}
                        inputProps={radioInputProps}
                        size="small"
                        checked={isZAttrChecked(id)}
                      />                  
                    </div>
                  )}
                </div>

                <div css={StatEntryPStyle}>
                  <Typography css={StatEntryLabelStyle} variant="body2">
                    {renderName ? '' : name}
                  </Typography>
                  <span css={StatEntryValueStyle} title={value}>
                    {value}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};
