export const en = {
  /**
   * Common strings
   */
  LOAD: 'Load {thing}',
  CREATE: 'Create {thing}',
  APPEND: 'Append {thing}',
  UPDATE: 'Update {thing}',
  DELETE: 'Delete {thing}',
  DUPLICATE: 'duplicate {thing}',
  GROUP: 'group {thing}',
  NEXT: 'Next',

  CREATED: 'Created {thing}',
  APPENDED: 'Appended {thing}',
  UPDATED: 'Updated {thing}',
  DELETED: 'Deleted {thing}',
  DUPLICATED: 'Duplicated {thing}',
  GROUPED: 'Grouped {thing}',
  NO_RESULTS_QUERY: 'Your query did not produce any results',

  HIDE: 'hide {thing}',
  SHOW: 'show {thing}',
  SELECT: 'select {thing}',
  CLOSE: 'close',
  NAME: 'name',
  DESCRIPTION: 'description',
  CONFIGURATION: 'configuration',
  ALL: 'all',
  AND: 'and',
  BY: 'by',
  OR: 'or',
  RETRY: 'retry',
  FILE: 'file | files',
  LIST: 'list {thing}',
  OPTION: 'option | options',
  OPTIONAL: 'optional',
  SAVE: 'Save',
  DONE: 'Done',
  APPLY: 'Apply {thing}',
  SAVED: 'Saved {thing}',
  EXTRACT: 'extract',
  COPY: 'copy',
  IMPORT: 'Import',
  IMPORTED: 'Imported {thing}',
  EXPORT: 'Export',
  EXPORTED: 'Exported {thing}',
  SETTING: 'Setting | Settings',
  CREATE_NEW: 'Create a new {thing} | Create new {things}',
  NEW: 'New {thing}',
  GO_TO: 'Go to {thing}',
  CANCEL: 'Cancel {thing}',
  YES: 'Yes',
  NO: 'No',
  LOADING: 'Loading {thing}...',
  EDITING: 'Editing {thing}',
  EDIT: 'Edit {thing}',
  FAILED_TO: 'Failed to {action}',
  COULD_NOT: 'Could not {action}',
  NOT_AVAILABLE: 'No {thing} available',
  SELECTED: '{number} {thing} selected',
  TYPE: 'Type a {thing}... | Type {things}...',
  CHANGES_SAVED: 'Changes saved',
  RECENT: 'Recent',
  SEARCH: 'Search',
  SUBMIT: 'Submit',
  GOT_IT: 'Got it',
  MIN: 'min',
  MAX: 'max',
  OTHER: 'other',
  DISMISS: 'dismiss',
  REFRESH: 'refresh',
  FAILED: 'Failed',
  SELECT_ALL: 'All',
  SELECTION: 'Selection | Selections',
  RENAME: 'Rename',
  REPLACE: 'Replace',
  SET: 'Set',
  CONFIGURE: 'Configure',
  OK: 'Ok',
  UNTITLED: 'Untitled',
  EPSG: 'EPSG',
  REQUIRED_HELPERTEXT: 'Required',
  RELOAD: 'Reload',
  BACK: 'Back',
  TOP_10: 'Top 10',
  FETCH: 'load and show',
  NA: 'not available',
  SELECTION_APPLIED: 'Selection applied',
  SECOND: 'second | seconds',
  MINUTE: 'minute | minutes',
  HOUR: 'hour | hours',
  DAY: 'day | days',
  MB: 'MB',
  GB: 'GB',
  TILED: 'Tiled',

  /**
   * Application lingo
   */
  APP_NAME: 'Mesh Builder',
  LOADING_APPLICATION: 'Loading...',
  HOME: 'My projects',
  PROJECT: 'project | projects',
  WORKSPACE: 'workspace | workspaces',
  WORKSPACE_NAME: 'Workspace',
  DATA: 'data',
  DATASET: 'dataset | datasets',
  GEOMETRY: 'geometry | geometries',
  ATTRIBUTE: 'attribute | attributes',
  UI_CLIPOPERATION: 'Clip',
  UI_SPLITOPERATION: 'Split',
  ELEMENT: 'item | items',
  VARIABLE: 'variable | variables',
  QUERY: 'query | queries',
  MESH_OPERATION: 'Mesh operation | Mesh operations',
  OPERATION_LIST_ASC: 'Most recent to oldest',
  OPERATION_LIST_DESC: 'Most old to most recent',

  /**
   * Workspace dataset usage types
   */
  USAGE_TYPE_0: 'Unknown',
  USAGE_TYPE_1: 'Geometry',
  USAGE_TYPE_2: 'Variable',
  USAGE_TYPE_3: 'Geometry & Variable',
  USAGE_TYPE_4: 'Mesh',
  USAGE_TYPE_5: 'Workspace',
  SELECT_USAGE_TYPE: 'Select data type',
  VARTYPE_VARIABLEELEVATION: 'Elevation',
  VARTYPE_VARIABLEPRESSURE: 'Pressure',
  VARTYPE_VARIABLEWATERDEPTH: 'Water depth',
  VARTYPE_VARIABLEOTHER: 'Other',
  VARTYPE_ELEVATION: 'Elevation',
  VARTYPE_VARIABLE: 'Variable',
  VARTYPE_GRID: 'Grid',
  VARTYPE_SCATTER: 'Scatter',

  /**
   * Toolbar labels
   */
  GENERATE_MESH: 'Generate',
  EDIT_MESH: 'Edit',
  GEOMETRY_OPERATIONS: 'Operation',
  VARIABLE_OPERATIONS: 'Operation',
  GEOMETRY_EDIT_DATA: 'Edit shape',
  VARIABLE_EDIT_DATA: 'Edit data',
  USE_FOR_EXTRACTION: 'Use to extract data',
  GEOMETRIES_DELETE: 'Delete',
  VARIABLES_DELETE: 'Delete',
  MESHES_DELETE: 'Delete',
  ADD_TO_MESH: 'Add to mesh',
  MARK_AS_HOLE: 'Mark as hole',
  UNMARK_AS_HOLE: 'Unmark as hole',
  EXPORT_MESH_FORMAT_2: '.mesh file (LONG/LAT)',
  /**
   * Messages
   */
  SHOW_LESS: 'Read less',
  SHOW_MORE: 'Read more',

  NO_DATASETS_AVAILABLE: 'The project does not contain any datasets.',
  MESSAGE_DATASET_TYPE_TIP: 'Select the data to add to the workspace and assign the data type when required.',
  MESSAGE_DATASET_UNKNOWN_TYPE_TIP: 'Only .vtu meshes and ESRI grid (.asc) variables are supported.',
  MESSAGE_DATASET_UNKNOWN_TYPE_DETAILS:
    'Generic files can be imported as meshes or variables. The only mesh format currently supported is ".vtu". The only variable format currently supported is ESRI grid (ASCII).',
  MESSAGE_IMPORT_DATASET_NOT_ALLOWED: 'You are not allowed to read data.',
  MESSAGE_IMPORT_DATASET_NOT_ALLOWED_DETAILS:
    'You do not have the permissions needed to read data in this project. If you think this is a mistake, please contact your administrator.',

  MESSAGE_MESH_FAILED_TITLE: 'Meshing failed',
  MESSAGE_GEOMETRY_FAILED_TITLE: 'Geometry error',
  MESSAGE_VARIABLE_FAILED_TITLE: 'Variable error',

  MESSAGE_GEOMETRY_QUERY_TIP:
    'Select an attribute and define an associated range as to extract these geometries that meet the defined query criteria',
  MESSAGE_GEOMETRY_QUERY_ADDITIONAL_TIP: 'Queries can only be applied on geometry metadata.',
  MESSAGE_GEOMETRY_QUERY_DETAILS:
    'When importing GIS vector data, the application stores all available metadata (also referred to attributes). In this panel those attributes can be queried & used to create copies of subsets of the original data. Depending on the source, some datasets might have more attributes than others. Attributes are arbitrary and their number and names can vary from dataset to dataset.',

  /**
   * Geometry viewer
   */
  RENDERING: 'Rendering',
  READY: 'Ready',

  /**
   * Project Eplorer
   */
  PROJECT_EXPLORER_COLUMN_NAME: 'Name',
  PROJECT_EXPLORER_COLUMN_TYPE: 'Type',
  PROJECT_EXPLORER_COLUMN_UPDATED_AT: 'Edited',
  PROJECT_EXPLORER_WRONG_DATASET_FORMAT: 'Please select a dataset with a format supported as data source',

  /**
   * Workspace
   */
  WORKSPACE_IMPORT_FROM_PLATFORM: 'Copy from Platform',
  WORKSPACE_LOADING: 'Building visualisation',
  WORKSPACE_LOADING_DESC: 'It will be ready in just a moment',
  WORKSPACE_LOAD_FAILED: 'Failed to load workspace data from API',
  WORKSPACE_DATA_LOAD_FAILED: 'Failed to load workspace data from API',
  WORKSPACE_SOCKET_ERROR: 'Disconnected from server. Retrying...',
  WORKSPACE_SOCKET_CLOSED: 'Disconnected from server. Retrying...',
  WORKSPACE_LIVE_UPDATES_LOST: 'You are not receiving live updates.',
  WORKSPACE_LIVE_UPDATES_LOST_MESSAGES: 'Please wait while we reconnect or refresh your browser.',
  WORKSPACE_SOCKET_RETRY_STOP: 'Failed to connect. Please refresh your browser or try later.',
  WORKSPACE_ITEM_FAILED_LOADING: 'Failed to load some data.',
  WORKSPACE_ITEM_READING_FAILED: 'Failed to read some data.',
  WORKSPACE_ITEM_READING_FAILED_ITEM: 'Failed to read data from',
  WORKSPACE_ATTRIBUTE_FAILED_LOADING: 'Failed to load some data attribute.',

  WORKSPACE_SELECT_PROJECT: 'Project',
  WORKSPACE_SELECT_PROJECT_PLACEHOLDER: 'Select project',
  WORKSPACE_SELECT_PROJECT_NO_MATCH: 'No project matches your search',
  WORKSPACE_NAME_LABEL: 'Name',
  WORKSPACE_DESCRIPTION_LABEL: 'Description',
  NO_WORKSPACES: "You haven't created any workspaces yet",
  WORKSPACES_PAGE_SUBHEADER: 'Subfolders',

  WORKSPACE_CREATE_TITLE: 'Create new workspace',
  WORKSPACE_CREATE_IMPORT_FILES: 'Files ready for import:',
  WORKSPACE_CREATE_FILE_TYPES: 'Import files as:',
  PROJECT_DATASET: 'Data from project',
  WORKSPACE_PROJECTION: 'Projection system',
  WORKSPACE_PROJECTION_SELECT: 'Indicate projection system',
  WORKSPACE_PROJECTIONS_RECENT_HELP: 'Or use a recent projection',
  WORKSPACE_PROJECTION_SELECT_MIXED:
    'The seleted datasets have mixed projections. You can only select datasets in the same projection.',
  WORKSPACE_PROJECTION_EXIST: 'The projection of the selected datasets will be used.',
  WORKSPACE_PROJECTION_SELECT_UNKNOWNS:
    'Some of the selected datasets have unknown projection. The projection selected below will be assumed.',
  WORKSPACE_CREATE_BUTTON: 'Create workspace',
  FOLDER_CREATE_BUTTON: 'Create folder',
  WORKSPACE_DELETE_BUTTON: 'Delete workspace',
  WORKSPACE_CREATE_IN_PROJECT: 'Create workspace in existing project',
  WORKSPACE_CREATE_IN_NEW_PROJECT: 'Create workspace in new project',
  WORKSPACE_CURRENT_WS_DELETED: 'The current workspace was deleted. You were redirected to the workspace list.',
  WORKSPACE_SELECT_FILES: 'Select files to copy',
  WORKSPACE_IMPORT: 'Copy',

  /**
   * Projection select
   */
  PROJECTION_PLACEHOLDER: 'Search for a projection',
  PROJECTION_NO_MATCH: 'No projections match your search',
  PROJECTION_LOADING_NAME: 'Updating projection information...',
  PROJECTION_LOADING: 'Searching projections...',
  PROJECTION_HELPERTEXT: 'Search for EPSG or name',

  /**
   * Panels
   */
  COMMENTS_PANEL: 'Comments',
  WORKSPACE_EDIT_PANEL: 'Workspace settings',
  WORKSPACE_OVERLAYS: 'Overlays',
  WORKSPACE_GRID_SWITCH_LABEL: 'Coordinate grid',
  ELEMENT_OPERATIONS_PANEL: 'Perform meshing',
  GEOMETRY_TRANFORMS_PANEL: 'Geometry operation: {geometryName}',
  GEOMETRY_TRANSFORM_MULTIPLE_PANEL: 'Geometry operation: {count} geometry | Geometry operation: {count} geometries',
  GEOMETRY_ADDTOMESH_PANEL: "Add '{geometryName}' to mesh",
  GEOMETRY_QUERY_PANEL: 'Extract from {geometryName}',
  MESH_POST_OPERATIONS_PANEL: '{operation} mesh: {meshName}',
  MESH_CONFIGURATION_NEW_MESH_PANEL: 'Configure new mesh',
  MESH_CONFIGURATION_EDIT_MESH_PANEL: 'Edit mesh configuration',
  MESH_INTERPOLATIONS_PANEL: 'Interpolations',
  MESH_INTERPOLATION_CREATE_PANEL: 'Add new interpolation',
  MESH_INTERPOLATION_EDIT_PANEL: 'Edit: {name}',

  MESH_EXPORT_PANEL: 'Export meshes',
  VARIABLE_ADDTOMESH_PANEL: "Add '{variableName}' to mesh",
  SELECT_MESH_MEMBERS_PANEL: 'Select items to mesh',
  SELECT_GEOMETRIES_TO_TRANSFORM_PANEL: 'Select operation geometries',
  SELECT_GEOMETRIES_TO_DELETE_PANEL: 'Select geometries to delete',
  SELECT_VARIABLES_TO_DELETE_PANEL: 'Select variables to delete',
  SELECT_VARIABLES_TO_TRANSFORM_PANEL: 'Select operation variables',
  SELECT_MESHES_TO_DELETE_PANEL: 'Select meshes to delete',
  GEOMETRY_OPERATION_PANEL: 'Geometry operations',
  GEOMETRY_OPERATION_MULTIPLE_PANEL_TITLE: 'Geometry operations',
  GEOMETRY_OPERATION_MULTIPLE_PANEL_DESCRIPTION: '{count} geometry | {count} geometries',
  VARIABLE_OPERATION_PANEL: 'Variable operations',
  VARIABLE_OPERATION_GROUP: 'Variable operations',
  SELECT_VARIABLE_OPERATION: 'Select operation',

  GEOMETRY_OPERATION_GROUP: 'Geometry operations',
  SELECT_GEOMETRY_OPERATION: 'Select operation',
  SELECT_GEOMETRY_ATTRIBUTE_QUERY: 'Select attribute',

  SELECT_MESH: 'Select mesh',
  SELECT_MESH_LABEL: 'Add to',
  SELECT_CREATE_MESH_OPERATION_LABEL: 'Mesh operation',
  SELECT_CREATE_MESH_OPERATION: 'Select mesh operation',
  SELECT_POST_MESHING_OPERATION_LABEL: 'Post operation',
  SELECT_POST_MESHING_OPERATION: 'Select post operation',
  POST_OPERATIONS_TITLE: 'Post operations',
  POST_OPERATIONS_NOT_AVAILABLE: 'Post operations not available',
  MESH_NOT_GENERATED_POSTOPERATIONS_DETAILS: 'Generate the mesh to execute operations',
  VARIABLE_POSTOPERATIONS_NOTVIABLE_DETAILS: 'Operations not viable for this variable',
  OPERATIONS_NOT_AVAILABLE: 'Operations not available',
  SHOW_POST_OPERATIONS: 'Expand',
  HIDE_POST_OPERATIONS: 'Collapse',
  EDIT_MESH_NODES_ACTION: 'Edit mesh nodes',
  POST_OPERATIONS_ACTION: 'Operation',
  SUBMIT_MESH_POST_OPERATION: 'Submit operation',
  MESH_POST_OPERATION_SUBMITTED_SUCCESSFULLY: 'Operation submitted. Data will load shortly.',
  MESH_POST_OPERATION_SUBMITTED_FAILED: 'Mesh post operation failed.',

  NO_MESH_ATTRIBUTES: 'No attributes available.',

  OPERATION: 'operation | operations',
  OPERATION_INPUT: 'Input | Input',
  OPERATION_SELECT_INPUT_LABEL: 'Input',
  OPERATION_SELECT_INPUT_PLACEHOLDER: 'Select input for operation',

  OPERATION_VARIABLE_INPUT: 'Variable | Variables',
  OPERATION_SELECT_VARIABLE_INPUT_LABEL: 'Variable',
  OPERATION_SELECT_VARIABLE_INPUT_PLACEHOLDER: 'Select variable for operation',

  OPERATION_GEOMETRY_INPUT: 'Geometry | Geometries',
  OPERATION_SELECT_GEOMETRY_INPUT_LABEL: 'Geometry',
  OPERATION_SELECT_GEOMETRY_INPUT_PLACEHOLDER: 'Select geometry for operation',

  OPERATION_MESH_INPUT: 'Mesh | Meshes',
  OPERATION_SELECT_MESH_INPUT_LABEL: 'Mesh',
  OPERATION_SELECT_MESH_INPUT_PLACEHOLDER: 'Select mesh for operation',

  INTERPOLATION_OPERATION_LABEL: 'Interpolation operation',
  SELECT_INTERPOLATION_OPERATION_LABEL: 'Interpolation operation',
  SELECT_INTERPOLATION_OPERATION: 'Select interpolation operation',
  INTERPOLATION_VARIABLE_INPUT: 'Variable | Variables',
  INTERPOLATION_SELECT_VARIABLE_INPUT_LABEL: 'Variable to interpolate',
  INTERPOLATION_SELECT_VARIABLE_INPUT_PLACEHOLDER: 'Select variable to interpolate',

  INTERPOLATION_STEP_VARIABLES_AND_OPERATION: 'Select operation & variables',
  INTERPOLATION_STEP_VARIABLES: 'Select variables to interpolate',
  INTERPOLATION_STEP_CONFIGURE: 'Configure',
  INTERPOLATIO_OTHER: '',

  INTERPOLATION_NEW_ELEVATION: 'New elevation interpolation',
  INTERPOLATION_NEW_OTHER: 'New other interpolation',

  MESH_OPERATION_GEOMETRY_QUERY_INPUT: 'Include selection',
  MESH_OPERATION_GEOMETRY_QUERIES_TITEL: 'Selections',
  MESH_OPERATION_GEOMETRY_QUERIES_INFO: '',

  /**
   * Edit panels and planned changes layer
   */
  LEGEND_PLANNED_CHANGES: 'Planned changes',
  LEGEND_EDIT: 'Edit',
  RESET: 'Reset',
  APPLY_CHANGES: 'Apply changes',
  MESH_MOVE_NODES: 'Move nodes',
  MESH_NODES_ATTRIBUTION: 'Attribution',
  MESH_ADD_NODES: 'Add node',
  MESH_DELETE_NODES: 'Delete node',

  GEOMETRY_MODIFY_VERTICES: 'Modify vertices',
  GEOMETRY_GEOPROCESSING: 'Operations',
  GEOMETRY_ADD_POINTS: 'Add',
  GEOMETRY_ADD_POLYLINES: 'Add',
  GEOMETRY_ADD_POLYGONS: 'Add',
  GEOMETRY_DELETE_POINTS: 'Delete',
  GEOMETRY_DELETE_POLYLINES: 'Delete',
  GEOMETRY_DELETE_POLYGONS: 'Delete',

  VARIABLE_POINTS_ATTRIBUTION: 'Attribution',
  VARIABLE_ADD_POINTS: 'Add points',
  VARIABLE_DELETE_POINTS: 'Delete points',
  VARIABLE_MOVE_POINTS: 'Move points',
  VARIABLE_POINTS_ATTRIBUTION_TITLE_MODIFY: 'Change attribution values',
  VARIABLE_POINTS_ATTRIBUTION_TITLE_ATTRIBUTION: 'Change attribution values',
  VARIABLE_POINTS_ATTRIBUTION_TITLE_ADD: 'Set attribution values',
  VARIABLE_POINTS_ATTRIBUTION_TITLE_DELETE: 'Attribution values',
  ADD_VARIABLE_POINTS_APPLY: 'Press apply changes and continue with different attribution values',
  OR_IN_OTHER_EDITMODE: 'or in another edit mode',
  FINISH_EDIT_SESSION: 'Finish edit session.',
  CREATE_VARIABLE_POINTS_SAVE: 'Finish edit session.',
  ADD_VARIABLE_POINTS: 'Use the cursor and keyboard shortcuts to add points.',
  JUST_ADDED_VARIABLE_POINTS:
    'Just added points can be modified. Use the cursor to move or select a just added point. Use keyboard shortcut delete to remove a just added point after selecting.',
  SELECT_TOOLS_TITLE: 'Use the following tools to create a selection.',
  SELECT_TOOL_TITLE: 'Use the following tool to create a selection.',
  CLIP_DRAW_TOOLS_TITLE: 'Draw one or more clipping polygons. Press the checkmark when done.',
  SPLIT_DRAW_TOOLS_TITLE: 'Draw one or more splitting polylines. Press the checkmark when done.',
  SELECTED_FOR_EDITING: 'selected',
  CLEAR_EDIT_SELECTION: 'Clear selection',
  TOOLTIP_SELECT_BY_POINT: 'Select by point',
  TOOLTIP_SELECT_BY_RECTANGLE: 'Select by rectangle',
  TOOLTIP_SELECT_BY_POLYGON: 'Select by polygon',
  MOVE_VARIABLE_POINTS: 'Press CTRL and use the cursor to move selected point.',
  MOVE_VARIABLE_POINTS_APPLY: 'Press apply changes and create a new selection.',
  DELETE_VARIABLE_POINTS_APPLY: 'Press apply changes and create a new selection.',
  ATTRIBUTION_VARIABLE_POINTS_APPLY: 'Press apply changes and create a new selection.',
  APPLY_CHANGES_AND_CREATE_NEW_SELECTION: 'Press apply changes and create a new selection.',
  APPLY_CHANGES_AND_SUBMIT: 'Press apply changes and submit',
  FINISH_EDITS_AFTER_ADDING_MESH_NODE: 'After adding a node the edit session needs to be finished.',
  FINISH_EDITS_AFTER_DELETING_MESH_NODE: 'After deleting a node the edit session needs to be finished.',
  SELECT_VARIABLE_POINTS_GROUP: 'Select points',
  ADD_VARIABLE_POINTS_GROUP: 'Add points',
  CHANGE_VARIABLE_POINTS_GROUP_ADD: 'Change attribution',
  CHANGE_GEOMETRY_POINTS_GROUP_ADD: 'Save points',
  CHANGE_GEOMETRY_POLYLINES_GROUP_ADD: 'Save lines',
  CHANGE_GEOMETRY_POLYGONS_GROUP_ADD: 'Save polygons',
  CHANGE_GEOMETRY_POINTS_GROUP_DELETE: 'Delete points',
  CHANGE_GEOMETRY_POLYLINES_GROUP_DELETE: 'Delete lines',
  CHANGE_GEOMETRY_POLYGONS_GROUP_DELETE: 'Delete polygons',
  CHANGE_VARIABLE_POINTS_GROUP_MODIFY: 'Move points',
  CHANGE_GEOMETRY_GROUP_MODIFY: 'Modify vertices',
  CHANGE_VARIABLE_POINTS_GROUP_ATTRIBUTION: 'Change attribution',
  CHANGE_GEOMETRY_GROUP_GEOPROCESSING: 'Geoprocessing operations',
  CHANGE_VARIABLE_POINTS_GROUP_DELETE: 'Delete points',
  SELECT_GEOMTRY_POLYGONS_GROUP: 'Select polygons',
  SELECT_GEOMTRY_POINTS_GROUP: 'Select point',
  SELECT_GEOMTRY_POLYLINES_GROUP: 'Select lines',
  ADD_GEOMETRY_POINTS_GROUP: 'Add points',
  ADD_GEOMETRY_POLYLINES_GROUP: 'Add lines',
  ADD_GEOMETRY_POLYGONS_GROUP: 'Add polygons',
  ADD_GEOMETRY_POINTS: 'Use the cursor and keyboard shortcuts to add points.',
  ADD_GEOMETRY_POLYLINES: 'Use the cursor and keyboard shortcuts to add lines.',
  ADD_GEOMETRY_POLYGONS: 'Use the following tools and keyboard shortcuts to add polygons.',
  MODIFY_GEOMETRY_POINTS: 'Use the cursor to move points.',
  MODIFY_GEOMETRY_POLYLINES: 'Use the cursor and keyboard shortcuts to modify vertices of selected lines.',
  MODIFY_GEOMETRY_POLYGONS: 'Use the cursor and keyboard shortcuts to modify vertices of selected polygons.',
  ADD_MESH_NODES_GROUP: 'Add node',
  ADD_MESH_NODES: 'Use the cursor and keyboard shortcuts to add a node.',
  SELECT_MESH_NODE_GROUP: 'Select node',
  MOVE_MESH_NODE: 'Press CTRL and use the cursor to move selected node.',
  CHANGE_MESH_NODE_GROUP_MODIFY: 'Move node',
  CHANGE_MESH_NODE_GROUP_DELETE: 'Delete node',
  CHANGE_MESH_NODE_GROUP_ADD: 'Save node',
  CHANGE_MESH_NODE_GROUP_ATTRIBUTION: 'Change attribution',

  /**
   * Mesh item, states & messages
   */
  MESH_ITEM: 'mesh',
  MESH_MEMBER: 'mesh item | mesh items',
  MESH_ELEMENT: 'element | elements',
  MESH_ROW: 'row | rows',
  MESH_COLUMN: 'column | columns',

  MESH_INTERPOLATIONS: 'Interpolations',
  MESH_EDIT_INTERPOLATIONS: 'Edit Interpolations',
  MESH_ADD_INTERPOLATIONS: 'Add interpolations',
  MESH_ADD_INTERPOLATION: 'Add interpolation',
  MESH_INTERPOLATION_VARIABLES_CONFIGURATION: 'Variable settings',
  MESH_INTERPOLATION_BASIC_CONFIGURATION: 'Basic settings',
  MESH_INTERPOLATIONS_NOT_AVAILABLE: 'Your workspace contains no variables that supports interpolations.',

  MESH_LOADING: 'Mesh loading...',
  MESH_MISSING:
    'Oh snap! The mesh is not loading yet. If loading does not start in a few minutes, try refreshing your browser. If the mesh still does not load, it might have been removed by someone else.',

  MESH_INPERPOLATION_NOT_INTERPOLATED: 'Currently not interpolated',
  MESH_INTERPOLATION_EXCUTED: 'Interpolated {date}',
  MESH_INTERPOLATION_FAILED: 'Interpolation failed',
  MESH_INTERPOLATION_OUTDATED: 'Interpolation outdated',
  MESH_INTERPOLATION_OUTDATED_INFO:
    'This can happen if the variable or the mesh has been modified. | This can happen if the variables or the mesh have been modified.',

  MESH_INTERPOLATIONS_EXECUTE: 'Run interpolations',
  MESH_INTERPOLATION_EXECUTE: 'Run interpolation',
  MESH_INTERPOLATIONS_EDIT_ACTION: 'Interpolations',
  MESH_INTERPOLATION_EDIT_ACTION: 'Edit interpolation',
  MESH_INTERPOLATION_SUBMITTED: 'Interpolation submitted',
  MESH_INTERPOLATION_SUBMITTING_FAILED: 'Failed to interpolate',

  MESH_INTERPOLATION_SAVED: 'Interpolation settings saved',
  MESH_INTERPOLATION_SAVE_FAILED: 'Failed to save interpolation settings',
  MESH_INTERPOLATION: 'Interpolation',
  MESH_SAVE_INTERPOLATION: 'Save settings',
  MESH_SAVE_AND_RUN_INTERPOLATION: 'Interpolate',
  MESH_INTERPOLATION_ADD_VARIABLES: 'Add variables',
  MESH_INTERPOLATION_CONFIRM_DELETE_TITLE: 'Delete interpolation confirmation',
  MESH_INTERPOLATION_CONFIRM_DELETE_TEXT: "Are you sure want to delete '{name}'?",
  MESH_SAVE_AND_RUN_INTERPOLATION_FORBIDDEN_PROPERTY_HINT: 'Please enter a result property other than',

  MESH_HAS_SOME_FAILED_OPERATIONS: 'Some mesh operation have failed',
  FAILED_TO_LOAD_AVAILABLE_MESH_INTERPOLATIONS:
    "We've encountered some problems while retrieving available interpolations. Please try again.",
  FAILED_TO_LOAD_CURRENT_MESH_INTERPOLATIONS:
    "We've encountered some problems while loading your previous interpolations. Please try again.",
  FAILED_TO_LOAD_CURRENT_MESH_INTERPOLATION:
    "We've encountered some problems while loading your previous interpolation. Please try again.",
  MESH_OPERATIONS_TITLE: 'Mesh operations',
  MESH_CONFIRM_GENERATE_TITLE: 'Are you sure you want to generate this mesh?',
  MESH_CONFIRM_GENERATE_TEXT: 'Any applied post-operations, interpolations or other manual edits will be lost.',
  MESH_MEMBER_CONFIRM_DELETE_TITLE: 'Remove mesh item confirmation',
  MESH_MEMBER_CONFIRM_DELETE_TEXT: "Are you sure you want to remove '{name}' from the mesh?",
  MESH_CONFIRM_DELETE_TITLE: 'Delete mesh confirmation | Delete meshes confirmation',
  MESH_CONFIRM_DELETE_TEXT:
    "Are you sure you want to delete '{name}'? | Are you sure you want to delete multiple meshes?",

  /**
   * Configure mesh
   */
  MESH_SAVE: 'Save settings',
  MESH_SAVE_AND_GENERATE: 'Generate',
  MESH_EDIT_CONFIGURATION: 'Edit configuration',
  MESH_ADD_MESH_MEMBERS: 'Add geometries',
  MESH_GO_TO_CONFIGURATION: 'Configure',
  NO_ITEMS_TO_ADD_TO_MESH: 'All available items have already been added to this mesh.',
  MESH_MEMBERS_TO_ADD: 'Added geometries',
  MESH_MEMBERS_CURRENT: 'Current geometry configurations',
  MESH_MEMBERS_CONFIGURATION: 'Geometry configurations',
  MESH_BASIC_CONFIGURATION: 'Basic configuration',
  MESH_SUBMITTED: 'Mesh submitted',
  MESH_SUBMITTED_SUCCESSFULLY: 'Mesh submitted',
  MESH_SUBMITTING_FAILED: 'Failed to mesh',

  MESH_SETTINGS_SAVED: 'Mesh settings saved',
  MESH_SETTINGS_SAVED_SUCCESSFULLY: 'Settings saved. Refresh the mesh to generate mesh-elements.',
  MESH_SETTINGS_SAVE_FAILED: 'Failed to save mesh settings',

  MESH_UPDATED: 'Mesh updated',
  MESH_UPDATED_SUCCESSFULLY: 'Mesh updated',
  MESH_UPDATE_FAILED: 'Failed to mesh',

  MESH_SETTINGS_UPDATED: 'Mesh settings updated',
  MESH_SETTINGS_UPDATED_SUCCESSFULLY: 'Settings updated. Refresh the mesh to re-generate mesh-elements.',
  MESH_SETTINGS_UPDATE_FAILED: 'Failed to update mesh settings',
  MESH_ADD_TO_MESH_GET_ALREADY_INCLUDED_FAILED: 'Something went wrong while checking what mesh already uses this item.',

  CREATE_MESH_SELECT_GEOMETRIES: 'Select geometries',
  EDIT_MESH_SELECT_GEOMETRIES: 'Select geometries',
  EDIT_MESH_NO_SELECTED_GEOMETRIES_TIP: 'Add geometries to configure the mesh.',

  /**
   * Mesh multi operations
   */
  FAILED_TO_DELETE_MESH: 'Failed to delete mesh: {meshNames} | Failed to delete meshes: {meshNames}',

  /**
   * Variables item, states & messages
   */
  VARIABLE_LOADING: 'Variable loading...',
  VARIABLE_MISSING:
    'Oh snap! The variable is not loading yet. If loading does not start in a few minutes, try refreshing your browser. If the variable still does not load, it might have been removed by someone else.',
  VARIABLE_UPDATED_SUCCESSFULLY: 'Variable saved',
  VARIABLE_UPDATE_FAILED: 'Failed to update variable',
  VARIABLE_CONFIRM_DELETE_TITLE: 'Delete variable confirmation | Delete variables confirmation',
  VARIABLE_CONFIRM_DELETE_TEXT:
    "Are you sure you want to delete '{name}'? | Are you sure you want to delete multiple variables?",

  /**
   * Variable multi operations
   */
  FAILED_TO_DELETE_VARIABLE:
    'Failed to delete variable: {variableNames} | Failed to delete geometries: {variableNames}',

  /**
   * Geometry item, states & messages
   */
  GEOMETRY_LOADING: 'Geometry loading...',
  GEOMETRY_MISSING:
    'Oh snap! The geometry is not loading yet. If loading does not start in a few minutes, try refreshing your browser. If the geometry still does not load, it might have been removed by someone else.',
  GEOMETRY_UPDATED_SUCCESSFULLY: 'Geometry saved',
  GEOMETRY_UPDATE_FAILED: 'Failed to update geometry',
  SUBMIT_VARIABLE_OPERATION: 'Submit operation',
  GO_TO_GEOMETRY_OPERATION: 'Specify operation',
  SUBMIT_GEOMETRY_OPERATION: 'Submit operation',
  SUBMIT_GEOMETRY_ON_THE_FLY_OPERATION: 'Save',
  CANCEL_GEOMETRY_ON_THE_FLY_OPERATION: 'Cancel',
  GEOMETRY_OPERATION_SUBMITTED_SUCCESSFULLY: 'Geometry operation submitted. Data will load shortly.',
  GEOMETRY_OPERATION_SUBMITTED_FAILED: 'Geometry operation failed.',
  VARIABLE_OPERATION_SUBMITTED_FAILED: 'Variable operation failed.',
  VARIABLE_OPERATION_SUBMITTED_SUCCESSFULLY: 'Variable operation submitted. Data will load shortly.',
  SELECT_MORE_GEOMETRIES: 'Add geometries',

  NO_DATA: 'No data to show',
  START: 'Start',
  UPLOAD: 'upload',
  UPLOADED: 'uploaded',
  SAVED_MESHES: 'Saved mesh settings',
  CREATED_MESHES: 'Created meshes',

  QUERY_EXTRACT_DATA_CHECK: 'Remove data from original geometry',
  QUERY_EXTRACT_DATA_CHECK_TIP:
    'Data will be extracted from your original geometries. Un-check this option to create a duplicate instead.',
  EXTRACT_QUERY_DATA: 'Extract query data',
  COPY_QUERY_DATA: 'Copy extracted data',
  QUERY_SUBMITTED: 'Query submitted. Data will load shortly.',
  GEOMETRY_ATTRIBUTES_LOADING: 'Geometry attributes loading...',
  GEOMETRY_CONFIRM_DELETE_TITLE: 'Delete geometry confirmation | Delete geometries confirmation',
  GEOMETRY_CONFIRM_DELETE_TEXT:
    "Are you sure you want to delete '{name}'? | Are you sure you want to delete multiple geometries?",

  /**
   * Geometry multiple operations
   */
  FAILED_TO_DELETE_GEOMETRY:
    'Failed to delete geometry: {geometryNames} | Failed to delete geometries: {geometryNames}',

  /**
   * Panel tabs
   */
  GEOMETRY_OVERVIEW_TAB: 'Overview',
  GEOMETRY_SELECTIONS_TAB: 'Selections',
  GEOMETRY_OPERATIONS_TAB: 'Operations',
  GO_TO_VARIABLES_OPERATION: 'Specify operation',
  MESH_OVERVIEW_TAB: 'Overview',
  MESH_CONFIGURATION_TAB: 'Configure',
  MESH_INTERPOLATION_TAB: 'Interpolate',
  MESH_SELECTIONS_TAB: 'Selections',
  MESH_POST_OPERATIONS_TAB: 'Operations',
  MESH_QUERY_TAB: 'Query',

  VARIABLE_OVERVIEW_TAB: 'Overview',

  /**
   * Element-type and other datadriven translations.
   */
  POINT: 'Point',
  MULTIPOINT: 'Multipoint',
  POLYGON: 'Polygon',
  MULTIPOLYGON: 'Multipolygon',
  LINESTRING: 'Polyline',
  MULTILINESTRING: 'Multipolyline',
  ELEVATION: 'Elevation',
  POLYGON_HOLE: 'Polygon (exclude from meshes)',
  MULTIPOLYGON_HOLE: 'Multipolygon (exclude from meshes)',
  MULTILINESTRING_HOLE: 'Multipolyline (exclude from meshes)',
  GRIDDED: 'Grid',
  SCATTER: 'Scatter',
  MIXED: 'Mixed',
  MESHSETTINGS: 'Mesh not generated',

  ELEMENTCATEGORY_MESH: 'Mesh | Meshes',
  ELEMENTCATEGORY_GEOMETRY: 'Geometry | Geometries',
  ELEMENTCATEGORY_VARIABLE: 'Variable | Variables',

  /**
   * Representations
   */
  REPRESENTATION: 'Representation | Representations',
  REPR_SURFACE_WITH_EDGES: 'Surface with wireframe',
  REPR_SURFACE: 'Surface',
  REPR_WIREFRAME: 'Wireframe',
  REPR_POINTS: 'Points',

  /**
   * Colouring
   */
  COLOR: 'Colour',
  EDGE_COLOR: 'Edge colour',
  SURFACE_COLOR: 'Surface colour',

  /**
   * Dataset-related properties & operations
   */
  APPEND_WORKSPACE_DATA_TITLE: 'Add datasets',
  APPEND_WORKSPACE_DATA_DESC: 'Datasets will be added to your existing work without affecting it.',
  USE_DEFAULTS: 'Use suggested settings',
  ADD_WORKSPACE_DATASETS: 'Add datasets',
  ADD_WORKSPACE_VARIABLES: 'Add datasets',
  CONFIGURE_DATASETS: 'Configure datasets',
  DATASET_VARIABLE_TITLE: 'Variables',
  DATASET_ITEMS_TITLE: 'Geometries & variables',
  NO_GEOM_OR_VAR_TIP: 'There are no geometries or variables available yet.',
  NO_GEOM_TIP: 'There are no geometries available yet.',
  NO_VAR_TIP: 'There are no variables available yet.',
  NO_MESH_TIP: 'There are no meshes available yet.',
  SELECT_GEOM_TO_TRANSFORM_TIP: 'Select geometries to apply operations to them.',
  SELECT_GEOM_FOR_OPERATION_TIP: 'Select geometries to apply operations to them.',

  CONFIGURE_AFTER_DATA_PROCESSED: 'Your data is still being processed and cannot be configured yet.',
  NO_MESHES_TO_ADD_TO_TIP: 'There are no meshes to add items to.',
  NO_ITEMS_IN_WORKSPACE: 'Your workspace is empty.',
  NO_ITEMS_IN_WORKSPACE_TIP: 'You can either edit your workspace to include datasets or extract geometries.',
  NO_ITEMS_IN_THIS_WORKSPACE: 'This workspace is empty.',
  NO_ITEMS_IN_THIS_WORKSPACE_TIP:
    'You have only view rights. Get in contact with the project owner to get more rights.',

  /**
   * Navigation
   */
  BACK_TO_PROJECTS: 'All projects',
  BACK_TO_WORKSPACES: 'All project workspaces',
  PREV_PAGE: 'Previous page',
  PAGE: 'Page',
  NEXT_PAGE: 'Next page',

  /**
   * Uploader
   */
  DROP_TO_UPLOAD: 'Drop files anywhere to upload',
  SUPPORTED_FILES: 'Supported file extensions',

  /**
   * Workspace notifications
   */
  WORKSPACE_UPDATED: 'Workspace updated',
  WORKSPACE_UPDATED_BY: '{person} updated the workspace & we have refreshed your data automatically.',
  WORKSPACE_SNAPSHOT_SUBMITTED: 'Workspace snapshot submitted succesfully',
  WORKSPACE_SNAPSHOT_DIALOG_TEXT:
    ' The snapshot will be created for support purpose. Please do not create it without contacting a DHI Support member. Do you want to proceed?',

  /**
   * workspace confirmations
   */
  WORKSPACE_CONFIRM_DELETE_TITLE: 'Delete workspace confirmation',
  WORKSPACE_CONFIRM_DELETE_TEXT: "Are you sure you want to delete '{name}'?",
  WORKSPACE_CONFIRM_DELETE_TEXT_NO_NAME: 'Are you sure you want to delete the workspace?',

  /**
   * UI geometry operations
   */
  UI_CLEANOPERATION: 'Clean',
  UI_DISSOLVEOPERATION: 'Dissolve',
  UI_SIMPLIFYOPERATION: 'Simplify',

  /**
   * Geometry operations
   */
  GEOMETRY_OPERATIONS_TIP: 'Geometry operations',
  OP_POLYGONTOPOINTOPERATIONDEFINITION: 'Polygon to point',

  OP_STRAIGHTSKELETONOPERATIONDEFINITION_NAME: 'Straight skeleton operation',
  OP_STRAIGHTSKELETONOPERATIONDEFINITION: 'Straight skeleton',
  OP_STRAIGHTSKELETONOPERATIONDEFINITION_DESCRIPTION:
    'Creates the straight skeleton of a convex polygon resulting in a set of straight line segments partitioning the polygon and from which a medial axis is generally extracted.',
  OP_STRAIGHTSKELETONOPERATIONDEFINITION_DRAFT: '',
  OP_STRAIGHTSKELETONOPERATIONDEFINITION_PROCESSING: '',
  OP_STRAIGHTSKELETONOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_STRAIGHTSKELETONOPERATIONDEFINITION_FAILED: '',
  OP_STRAIGHTSKELETONOPERATIONDEFINITION_SCHEDULED: '',
  OP_STRAIGHTSKELETONOPERATIONDEFINITION_SUCCEEDED: '',

  OP_MEDIALAXISOPERATIONDEFINITION_NAME: 'Medial axis operation',
  OP_MEDIALAXISOPERATIONDEFINITION: 'Medial axis',
  OP_MEDIALAXISOPERATIONDEFINITION_DESCRIPTION:
    'Creates the medial axis of a convex polygon resulting in a set of polylines describing the centreline of the polygon.',
  OP_MEDIALAXISOPERATIONDEFINITION_DRAFT: '',
  OP_MEDIALAXISOPERATIONDEFINITION_PROCESSING: '',
  OP_MEDIALAXISOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_MEDIALAXISOPERATIONDEFINITION_FAILED: '',
  OP_MEDIALAXISOPERATIONDEFINITION_SCHEDULED: '',
  OP_MEDIALAXISOPERATIONDEFINITION_SUCCEEDED: '',

  OP_BUFFEROPERATIONDEFINITION_NAME: 'Buffer operation',
  OP_BUFFEROPERATIONDEFINITION: 'Buffer',
  OP_BUFFEROPERATIONDEFINITION_DESCRIPTION:
    'Creates a new polygon by offsetting the selected feature. The distance with which to buffer the polygon is to be prescribed in terms of a numerical distance.',
  OP_BUFFEROPERATIONDEFINITION_DRAFT: '',
  OP_BUFFEROPERATIONDEFINITION_PROCESSING: 'Buffering...',
  OP_BUFFEROPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_BUFFEROPERATIONDEFINITION_FAILED: 'Failed to buffer',
  OP_BUFFEROPERATIONDEFINITION_SCHEDULED: 'Waiting to buffer',
  OP_BUFFEROPERATIONDEFINITION_SUCCEEDED: '',

  OP_ORIENTEDBOUNDINGBOXOPERATIONDEFINITION_NAME: 'Oriented bounding box operation',
  OP_ORIENTEDBOUNDINGBOXOPERATIONDEFINITION: 'Oriented bounding box',
  OP_ORIENTEDBOUNDINGBOXOPERATIONDEFINITION_DESCRIPTION: 'Create an oriented bounding box',
  OP_ORIENTEDBOUNDINGBOXOPERATIONDEFINITION_DRAFT: '',
  OP_ORIENTEDBOUNDINGBOXOPERATIONDEFINITION_PROCESSING: 'Creating oriented bounding box',
  OP_ORIENTEDBOUNDINGBOXOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_ORIENTEDBOUNDINGBOXOPERATIONDEFINITION_FAILED: 'Failed to create oriented bounding box',
  OP_ORIENTEDBOUNDINGBOXOPERATIONDEFINITION_SCHEDULED: 'Waiting to create oriented bounding box',
  OP_ORIENTEDBOUNDINGBOXOPERATIONDEFINITION_SUCCEEDED: '',

  OP_ROOFOPERATIONDEFINITION_NAME: 'Roof operation',
  OP_ROOFOPERATIONDEFINITION: 'Roof',
  OP_ROOFOPERATIONDEFINITION_DESCRIPTION: 'Create a roof for the selected geometry',
  OP_ROOFOPERATIONDEFINITION_DRAFT: '',
  OP_ROOFOPERATIONDEFINITION_PROCESSING: 'Creating roof...',
  OP_ROOFOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_ROOFOPERATIONDEFINITION_FAILED: 'Failed to create roof',
  OP_ROOFOPERATIONDEFINITION_SCHEDULED: 'Waiting to create roof',
  OP_ROOFOPERATIONDEFINITION_SUCCEEDED: '',

  OP_VORONOIDIAGRAMOPERATIONDEFINITION_NAME: 'Voronoi diagram operation',
  OP_VORONOIDIAGRAMOPERATIONDEFINITION: 'Voronoi diagram (NetTopology)',
  OP_VORONOIDIAGRAMOPERATIONDEFINITION_DESCRIPTION: 'Create a voronoi diagram for the geometry (NetTopologySuite)',
  OP_VORONOIDIAGRAMOPERATIONDEFINITION_DRAFT: '',
  OP_VORONOIDIAGRAMOPERATIONDEFINITION_PROCESSING: '',
  OP_VORONOIDIAGRAMOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_VORONOIDIAGRAMOPERATIONDEFINITION_FAILED: '',
  OP_VORONOIDIAGRAMOPERATIONDEFINITION_SCHEDULED: '',
  OP_VORONOIDIAGRAMOPERATIONDEFINITION_SUCCEEDED: '',

  OP_VORONOIDIAGRAM2OPERATIONDEFINITION_NAME: 'Voronoi diagram operation (CGAL)',
  OP_VORONOIDIAGRAM2OPERATIONDEFINITION: 'Voronoi diagram (CGAL)',
  OP_VORONOIDIAGRAM2OPERATIONDEFINITION_DESCRIPTION: 'Create a voronoi diagram for the geometry (CGAL)',
  OP_VORONOIDIAGRAM2OPERATIONDEFINITION_DRAFT: '',
  OP_VORONOIDIAGRAM2OPERATIONDEFINITION_PROCESSING: 'Creating Voronoi diagram (CGAL)...',
  OP_VORONOIDIAGRAM2OPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_VORONOIDIAGRAM2OPERATIONDEFINITION_FAILED: 'Failed to create Voronoi diagram (CGAL)',
  OP_VORONOIDIAGRAM2OPERATIONDEFINITION_SCHEDULED: 'Waiting to create Voronoi diagram (CGAL)',
  OP_VORONOIDIAGRAM2OPERATIONDEFINITION_SUCCEEDED: '',

  OP_DENSIFYOPERATIONDEFINITION_NAME: 'Densify operation',
  OP_DENSIFYOPERATIONDEFINITION: 'Densify',
  OP_DENSIFYOPERATIONDEFINITION_DESCRIPTION:
    'Enriches the geometry path by increasing the number of vertices by a segment split approach. The target preferred max length for each edge is prescribed in terms of absolute length.',
  OP_DENSIFYOPERATIONDEFINITION_DRAFT: '',
  OP_DENSIFYOPERATIONDEFINITION_PROCESSING: 'Densifying...',
  OP_DENSIFYOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_DENSIFYOPERATIONDEFINITION_FAILED: 'Failed to densify',
  OP_DENSIFYOPERATIONDEFINITION_SCHEDULED: 'Waiting to densify',
  OP_DENSIFYOPERATIONDEFINITION_SUCCEEDED: '',

  OP_SIMPLIFY2OPERATIONDEFINITION_NAME: 'Simplify operation',
  OP_SIMPLIFY2OPERATIONDEFINITION: 'Simplify',
  OP_SIMPLIFY2OPERATIONDEFINITION_DESCRIPTION: '',
  OP_SIMPLIFY2OPERATIONDEFINITION_DRAFT: '',
  OP_SIMPLIFY2OPERATIONDEFINITION_PROCESSING: 'Simplifying...',
  OP_SIMPLIFY2OPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_SIMPLIFY2OPERATIONDEFINITION_FAILED: 'Failed to simplify',
  OP_SIMPLIFY2OPERATIONDEFINITION_SCHEDULED: 'Waiting to simplify',
  OP_SIMPLIFY2OPERATIONDEFINITION_SUCCEEDED: '',

  OP_SIMPLIFYBUILDINGSOPERATIONDEFINITION_NAME: 'Simplify buildings operation',
  OP_SIMPLIFYBUILDINGSOPERATIONDEFINITION: 'Simplify buildings',
  OP_SIMPLIFYBUILDINGSOPERATIONDEFINITION_DESCRIPTION: '',
  OP_SIMPLIFYBUILDINGSOPERATIONDEFINITION_DRAFT: '',
  OP_SIMPLIFYBUILDINGSOPERATIONDEFINITION_PROCESSING: 'Simplifying buildings...',
  OP_SIMPLIFYBUILDINGSOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_SIMPLIFYBUILDINGSOPERATIONDEFINITION_FAILED: 'Failed to simplify buildings',
  OP_SIMPLIFYBUILDINGSOPERATIONDEFINITION_SCHEDULED: 'Waiting to simplify buildings',
  OP_SIMPLIFYBUILDINGSOPERATIONDEFINITION_SUCCEEDED: '',

  OP_SNAPTOOPERATIONDEFINITION_NAME: 'Snap operation',
  OP_SNAPTOOPERATIONDEFINITION: 'Snap',
  OP_SNAPTOOPERATIONDEFINITION_DESCRIPTION: '',
  OP_SNAPTOOPERATIONDEFINITION_DRAFT: '',
  OP_SNAPTOOPERATIONDEFINITION_PROCESSING: 'Snapping...',
  OP_SNAPTOOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_SNAPTOOPERATIONDEFINITION_FAILED: 'Failed to snap',
  OP_SNAPTOOPERATIONDEFINITION_SCHEDULED: 'Waiting to snap',
  OP_SNAPTOOPERATIONDEFINITION_SUCCEEDED: '',

  OP_CLIENTSIDE_SUCCEEDED: 'Operation succeeded',
  OP_CLIENTSIDE_FAILED: 'Operation failed',
  WORKSPACE_OPERATION_PANEL: 'Workspace operation',
  WORKSPACE_OPERATIONS: 'Operations',
  WORKSPACE_SNAPSHOT: 'Create workspace snapshot',
  WORKSPACE_SNAPSHOT_SUPPORT: 'Send snapshot to support',
  OP_CLEANOPERATIONDEFINITION_NAME: 'Clean operation',
  OP_CLEANOPERATIONDEFINITION: 'Clean',
  OP_CLEANOPERATIONDEFINITION_DESCRIPTION:
    'Performs various minor modifications to input geometry, such that in some cases unmeshable geometry becomes meshable.',
  OP_CLEANOPERATIONDEFINITION_DRAFT: '',
  OP_CLEANOPERATIONDEFINITION_PROCESSING: 'Cleaning...',
  OP_CLEANOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_CLEANOPERATIONDEFINITION_FAILED: 'Failed to clean',
  OP_CLEANOPERATIONDEFINITION_SCHEDULED: 'Waiting to clean',
  OP_CLEANOPERATIONDEFINITION_SUCCEEDED: '',

  OP_SMOOTHOPERATIONDEFINITION_NAME: 'Smooth operation',
  OP_SMOOTHOPERATIONDEFINITION: 'Smooth',
  OP_SMOOTHOPERATIONDEFINITION_DESCRIPTION:
    'Smoothes the geometry path. User must inform the number of control points to be added to each input vertex in order to create a smooth curve. Default is 5. If the option of keeping the geometry points intact is chosen, additional control points are interpolated by the Catmull-Rom algorithm. If not, then the points are approximated by Chaikin’s algorithm.',
  OP_SMOOTHOPERATIONDEFINITION_DRAFT: '',
  OP_SMOOTHOPERATIONDEFINITION_PROCESSING: 'Smoothing...',
  OP_SMOOTHOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_SMOOTHOPERATIONDEFINITION_FAILED: 'Failed to smooth',
  OP_SMOOTHOPERATIONDEFINITION_SCHEDULED: 'Waiting to smooth',
  OP_SMOOTHOPERATIONDEFINITION_SUCCEEDED: '',

  OP_POLYGONIZEOPERATIONDEFINITION_NAME: 'Polygonize operation',
  OP_POLYGONIZEOPERATIONDEFINITION: 'Polygonize',
  OP_POLYGONIZEOPERATIONDEFINITION_DESCRIPTION: 'Attempts to create a polygon from polylines.',
  OP_POLYGONIZEOPERATIONDEFINITION_DRAFT: '',
  OP_POLYGONIZEOPERATIONDEFINITION_PROCESSING: 'Polygonizing...',
  OP_POLYGONIZEOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_POLYGONIZEOPERATIONDEFINITION_FAILED: 'Failed to polygonize',
  OP_POLYGONIZEOPERATIONDEFINITION_SCHEDULED: 'Waiting to polygonize',
  OP_POLYGONIZEOPERATIONDEFINITION_SUCCEEDED: '',

  OP_DISSOLVEOPERATIONDEFINITION_NAME: 'Dissolve operation',
  OP_DISSOLVEOPERATIONDEFINITION: 'Quick dissolve',
  OP_DISSOLVEOPERATIONDEFINITION_DESCRIPTION: 'Eliminate enclosed gaps with the minimal defined gap area size.',
  OP_DISSOLVEOPERATIONDEFINITION_DRAFT: '',
  OP_DISSOLVEOPERATIONDEFINITION_PROCESSING: '',
  OP_DISSOLVEOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_DISSOLVEOPERATIONDEFINITION_FAILED: '',
  OP_DISSOLVEOPERATIONDEFINITION_SCHEDULED: '',
  OP_DISSOLVEOPERATIONDEFINITION_SUCCEEDED: '',

  OP_DISSOLVE2OPERATIONDEFINITION_NAME: 'Dissolve operation',
  OP_DISSOLVE2OPERATIONDEFINITION: 'Dissolve',
  OP_DISSOLVE2OPERATIONDEFINITION_DESCRIPTION: 'Join polylines together whose end-points nearly touch.',
  OP_DISSOLVE2OPERATIONDEFINITION_DRAFT: '',
  OP_DISSOLVE2OPERATIONDEFINITION_PROCESSING: 'Dissolving...',
  OP_DISSOLVE2OPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_DISSOLVE2OPERATIONDEFINITION_FAILED: 'Failed to dissolve',
  OP_DISSOLVE2OPERATIONDEFINITION_SCHEDULED: 'Waiting to dissolve',
  OP_DISSOLVE2OPERATIONDEFINITION_SUCCEEDED: '',

  OP_AGGREGATEOPERATIONDEFINITION_NAME: 'Aggregate operation',
  OP_AGGREGATEOPERATIONDEFINITION: 'Aggregate',
  OP_AGGREGATEOPERATIONDEFINITION_DESCRIPTION: 'Create one large polygon from multiple smaller polygons.​',
  OP_AGGREGATEOPERATIONDEFINITION_DRAFT: '',
  OP_AGGREGATEOPERATIONDEFINITION_PROCESSING: 'Aggregating...',
  OP_AGGREGATEOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_AGGREGATEOPERATIONDEFINITION_FAILED: 'Failed to aggregate',
  OP_AGGREGATEOPERATIONDEFINITION_SCHEDULED: 'Waiting to aggregate',
  OP_AGGREGATEOPERATIONDEFINITION_SUCCEEDED: '',

  OP_CLIPOPERATIONDEFINITION_NAME: 'Clip operation',
  OP_CLIPOPERATIONDEFINITION: 'Clip',
  OP_CLIPOPERATIONDEFINITION_DESCRIPTION:
    'Clip geometries. Use the clip-polygons to clip the selected geometries depending on the operator. Intersection: the result is everything inside the clip-polygons. Difference: the result is everything outside the clip-polygons.',
  OP_CLIPOPERATIONUIDEFINITION_DESCRIPTION_POLYGON:
    'The clipping is a difference operation. In other words, the result is everything outside of the clipping polygon(s), with the clipping polygon(s) used to connect vertices of the original geometry.​',
  OP_CLIPOPERATIONUIDEFINITION_DESCRIPTION_LINE:
    'The clipping is a difference operation. In other words, the parts intersecting with the clipping polygon are deleted.​',
  OP_CLIPOPERATIONDEFINITION_DRAFT: '',
  OP_CLIPOPERATIONDEFINITION_PROCESSING: 'Clipping...',
  OP_CLIPOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_CLIPOPERATIONDEFINITION_FAILED: 'Failed to clip',
  OP_CLIPOPERATIONDEFINITION_SCHEDULED: 'Waiting to clip',
  OP_CLIPOPERATIONDEFINITION_SUCCEEDED: '',

  OP_UNIONOPERATIONDEFINITION_NAME: 'Union operation',
  OP_UNIONOPERATIONDEFINITION: 'Union',
  OP_UNIONOPERATIONDEFINITION_DESCRIPTION:
    'Unite geometries. Everything overlapping inside the selected polygons are merged together.',
  OP_UNIONOPERATIONDEFINITION_DRAFT: '',
  OP_UNIONOPERATIONDEFINITION_PROCESSING: 'Unioning...',
  OP_UNIONOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_UNIONOPERATIONDEFINITION_FAILED: 'Failed to union',
  OP_UNIONOPERATIONDEFINITION_SCHEDULED: 'Waiting to union',
  OP_UNIONOPERATIONDEFINITION_SUCCEEDED: '',

  OP_EXTRACTGEOMETRIESOPERATIONDEFINITION_NAME: 'Extract by attribute operation',
  OP_EXTRACTGEOMETRIESOPERATIONDEFINITION: 'Extract by attribute',
  OP_EXTRACTGEOMETRIESOPERATIONDEFINITION_DESCRIPTION:
    'Creates a new geometry as a subset of the original data. Select an attribute and define an associated range to specify the data to be included in the new geometry.',
  OP_EXTRACTGEOMETRIESOPERATIONDEFINITION_DRAFT: '',
  OP_EXTRACTGEOMETRIESOPERATIONDEFINITION_PROCESSING: 'Extracting by attribute',
  OP_EXTRACTGEOMETRIESOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_EXTRACTGEOMETRIESOPERATIONDEFINITION_FAILED: 'Failed to extract by attribute',
  OP_EXTRACTGEOMETRIESOPERATIONDEFINITION_SCHEDULED: 'Waiting to extract by attribute',
  OP_EXTRACTGEOMETRIESOPERATIONDEFINITION_SUCCEEDED: '',

  /**
   * Geometry operations initiated by api
   */
  OP_UPDATEGEOMETRYDATAOPERATIONDEFINITION_NAME: 'Update geometry',
  OP_UPDATEGEOMETRYDATAOPERATIONDEFINITION: 'Update geometry',
  OP_UPDATEGEOMETRYDATAOPERATIONDEFINITION_DESCRIPTION: '',
  OP_UPDATEGEOMETRYDATAOPERATIONDEFINITION_DRAFT: '',
  OP_UPDATEGEOMETRYDATAOPERATIONDEFINITION_PROCESSING: 'Updating geometry...',
  OP_UPDATEGEOMETRYDATAOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_UPDATEGEOMETRYDATAOPERATIONDEFINITION_FAILED: 'Failed to update geometry',
  OP_UPDATEGEOMETRYDATAOPERATIONDEFINITION_SCHEDULED: 'Waiting to update geometry...',
  OP_UPDATEGEOMETRYDATAOPERATIONDEFINITION_SUCCEEDED: '',

  OP_CREATEGEOMETRYFROMGEOJSONOPERATIONDEFINITION_NAME: 'Create geometry',
  OP_CREATEGEOMETRYFROMGEOJSONOPERATIONDEFINITION: 'Create geometry',
  OP_CREATEGEOMETRYFROMGEOJSONOPERATIONDEFINITION_DESCRIPTION: '',
  OP_CREATEGEOMETRYFROMGEOJSONOPERATIONDEFINITION_DRAFT: '',
  OP_CREATEGEOMETRYFROMGEOJSONOPERATIONDEFINITION_PROCESSING: 'Creating geometry...',
  OP_CREATEGEOMETRYFROMGEOJSONOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_CREATEGEOMETRYFROMGEOJSONOPERATIONDEFINITION_FAILED: 'Failed to create geometry',
  OP_CREATEGEOMETRYFROMGEOJSONOPERATIONDEFINITION_SCHEDULED: 'Waiting to create geometry...',
  OP_CREATEGEOMETRYFROMGEOJSONOPERATIONDEFINITION_SUCCEEDED: '',

  OP_DUPLICATEGEOMETRYOPERATIONDEFINITION_NAME: 'Duplicate geometry',
  OP_DUPLICATEGEOMETRYOPERATIONDEFINITION: 'Duplicate geometry',
  OP_DUPLICATEGEOMETRYOPERATIONDEFINITION_DESCRIPTION: '',
  OP_DUPLICATEGEOMETRYOPERATIONDEFINITION_DRAFT: '',
  OP_DUPLICATEGEOMETRYOPERATIONDEFINITION_PROCESSING: 'Duplicating geometry...',
  OP_DUPLICATEGEOMETRYOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_DUPLICATEGEOMETRYOPERATIONDEFINITION_FAILED: 'Failed to duplicate geometry',
  OP_DUPLICATEGEOMETRYOPERATIONDEFINITION_SCHEDULED: 'Waiting to duplicate geometry...',
  OP_DUPLICATEGEOMETRYOPERATIONDEFINITION_SUCCEEDED: '',

  OP_CREATEDOMAINOPERATIONDEFINITION_NAME: 'Create domain',
  OP_CREATEDOMAINOPERATIONDEFINITION: 'Create domain',
  OP_CREATEDOMAINOPERATIONDEFINITION_DESCRIPTION: '',
  OP_CREATEDOMAINOPERATIONDEFINITION_DRAFT: '',
  OP_CREATEDOMAINOPERATIONDEFINITION_PROCESSING: 'Creating domain...',
  OP_CREATEDOMAINOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_CREATEDOMAINOPERATIONDEFINITION_FAILED: 'Failed to create domain',
  OP_CREATEDOMAINOPERATIONDEFINITION_SCHEDULED: 'Waiting to create domain...',
  OP_CREATEDOMAINOPERATIONDEFINITION_SUCCEEDED: '',

  /**
   * Geometry Operation states in general. Used if more specific messages are not available
   * Will fallback to more general ones when empty
   */
  OP_GEOMETRY_DRAFT: '',
  OP_GEOMETRY_PROCESSING: '',
  OP_GEOMETRY_PARTIALLYSUCCEEDED: '',
  OP_GEOMETRY_FAILED: 'Operation failed',
  OP_GEOMETRY_SCHEDULED: '',
  OP_GEOMETRY_SUCCEEDED: '',
  OP_GEOMETRY_CONFLICTING: 'Some applied operations failed',

  /**
   * Mesh operations. Blank values will use more general mesh state messages as fallback.
   */
  OP_CREATEMESHOPERATIONDEFINITION_NAME: 'Creating mesh',
  OP_CREATEMESHOPERATIONDEFINITION_DRAFT: '',
  OP_CREATEMESHOPERATIONDEFINITION_PROCESSING: '',
  OP_CREATEMESHOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_CREATEMESHOPERATIONDEFINITION_FAILED: '',
  OP_CREATEMESHOPERATIONDEFINITION_SCHEDULED: '',
  OP_CREATEMESHOPERATIONDEFINITION_SUCCEEDED: '',
  OP_CREATEMESHOPERATIONDEFINITION_OUTDATED: '',

  OP_TRIANGLEOPERATIONDEFINITION: 'Triangulate',
  OP_TRIANGLEOPERATIONDEFINITION_NAME: 'Triangulate',
  OP_TRIANGLEOPERATIONDEFINITION_DRAFT: '',
  OP_TRIANGLEOPERATIONDEFINITION_PROCESSING: '',
  OP_TRIANGLEOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_TRIANGLEOPERATIONDEFINITION_FAILED: '',
  OP_TRIANGLEOPERATIONDEFINITION_SCHEDULED: '',
  OP_TRIANGLEOPERATIONDEFINITION_SUCCEEDED: '',
  OP_TRIANGLEOPERATIONDEFINITION_OUTDATED: '',

  OP_QUADOPERATIONDEFINITION: 'Channel',
  OP_QUADOPERATIONDEFINITION_NAME: 'Channel',
  OP_QUADOPERATIONDEFINITION_DRAFT: '',
  OP_QUADOPERATIONDEFINITION_PROCESSING: '',
  OP_QUADOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_QUADOPERATIONDEFINITION_FAILED: '',
  OP_QUADOPERATIONDEFINITION_SCHEDULED: '',
  OP_QUADOPERATIONDEFINITION_SUCCEEDED: '',
  OP_QUADOPERATIONDEFINITION_OUTDATED: '',

  OP_POLYLINECHANNELMESHERDEFINITION: 'Channel',
  OP_POLYLINECHANNELMESHERDEFINITION_NAME: 'Channel',
  OP_POLYLINECHANNELMESHERDEFINITION_DRAFT: '',
  OP_POLYLINECHANNELMESHERDEFINITION_PROCESSING: '',
  OP_POLYLINECHANNELMESHERDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_POLYLINECHANNELMESHERDEFINITION_FAILED: '',
  OP_POLYLINECHANNELMESHERDEFINITION_SCHEDULED: '',
  OP_POLYLINECHANNELMESHERDEFINITION_SUCCEEDED: '',
  OP_POLYLINECHANNELMESHERDEFINITION_OUTDATED: '',

  OP_RADIALOPERATIONDEFINITION: 'Radial',
  OP_RADIALOPERATIONDEFINITION_NAME: 'Radial',
  OP_RADIALOPERATIONDEFINITION_DRAFT: '',
  OP_RADIALOPERATIONDEFINITION_PROCESSING: '',
  OP_RADIALOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_RADIALOPERATIONDEFINITION_FAILED: '',
  OP_RADIALOPERATIONDEFINITION_SCHEDULED: '',
  OP_RADIALOPERATIONDEFINITION_SUCCEEDED: '',
  OP_RADIALOPERATIONDEFINITION_OUTDATED: '',

  OP_RECTANGULAROPERATIONDEFINITION: 'Rectangular',
  OP_RECTANGULAROPERATIONDEFINITION_NAME: 'Rectangular',
  OP_RECTANGULAROPERATIONDEFINITION_DRAFT: '',
  OP_RECTANGULAROPERATIONDEFINITION_PROCESSING: '',
  OP_RECTANGULAROPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_RECTANGULAROPERATIONDEFINITION_FAILED: '',
  OP_RECTANGULAROPERATIONDEFINITION_SCHEDULED: '',
  OP_RECTANGULAROPERATIONDEFINITION_SUCCEEDED: '',
  OP_RECTANGULAROPERATIONDEFINITION_OUTDATED: '',

  OP_EXCLUDEOPERATIONDEFINITION: 'Exclude from meshing',
  OP_EXCLUDEOPERATIONDEFINITION_NAME: 'Exclude from meshing',
  OP_EXCLUDEOPERATIONDEFINITION_DRAFT: '',
  OP_EXCLUDEOPERATIONDEFINITION_PROCESSING: '',
  OP_EXCLUDEOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_EXCLUDEOPERATIONDEFINITION_FAILED: '',
  OP_EXCLUDEOPERATIONDEFINITION_SCHEDULED: '',
  OP_EXCLUDEOPERATIONDEFINITION_SUCCEEDED: '',
  OP_EXCLUDEOPERATIONDEFINITION_OUTDATED: '',

  /**
   * Mesh operations initiated by api
   */
  OP_DUPLICATEMESHOPERATIONDEFINITION_NAME: 'Duplicate mesh',
  OP_DUPLICATEMESHOPERATIONDEFINITION: 'Duplicate mesh',
  OP_DUPLICATEMESHOPERATIONDEFINITION_DESCRIPTION: '',
  OP_DUPLICATEMESHOPERATIONDEFINITION_DRAFT: '',
  OP_DUPLICATEMESHOPERATIONDEFINITION_PROCESSING: 'Duplicating mesh...',
  OP_DUPLICATEMESHOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_DUPLICATEMESHOPERATIONDEFINITION_FAILED: '',
  OP_DUPLICATEMESHOPERATIONDEFINITION_SCHEDULED: 'Duplicating mesh...',
  OP_DUPLICATEMESHOPERATIONDEFINITION_SUCCEEDED: '',

  /**
   * Mesh interpolation operations
   */
  OP_MESHINTERPOLATIONOPERATIONDEFINITION_NAME: 'Variable interpolation',
  OP_MESHINTERPOLATIONOPERATIONDEFINITION: 'Interpolate variable',
  OP_MESHINTERPOLATIONOPERATIONDEFINITION_DESCRIPTION: '',
  OP_MESHINTERPOLATIONOPERATIONDEFINITION_DRAFT: 'Draft interpolation',
  OP_MESHINTERPOLATIONOPERATIONDEFINITION_PROCESSING: 'Interpolating...',
  OP_MESHINTERPOLATIONOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_MESHINTERPOLATIONOPERATIONDEFINITION_FAILED: 'Interpolation failed',
  OP_MESHINTERPOLATIONOPERATIONDEFINITION_SCHEDULED: 'Interpolating...',
  OP_MESHINTERPOLATIONOPERATIONDEFINITION_SUCCEEDED: '',
  OP_MESHINTERPOLATIONOPERATIONDEFINITION_OUTDATED: 'Interpolation outdated',

  OP_MESHELEVATIONOPERATIONDEFINITION_NAME: 'Elevation interpolation',
  OP_MESHELEVATIONOPERATIONDEFINITION: 'Interpolate elevation',
  OP_MESHELEVATIONOPERATIONDEFINITION_DESCRIPTION:
    "Will interpolate the selected variables onto z-values of the mesh nodes as well as in the 'Elevation' attribute.",
  OP_MESHELEVATIONOPERATIONDEFINITION_DRAFT: 'Draft interpolation',
  OP_MESHELEVATIONOPERATIONDEFINITION_PROCESSING: 'Interpolating elevation...',
  OP_MESHELEVATIONOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_MESHELEVATIONOPERATIONDEFINITION_FAILED: '',
  OP_MESHELEVATIONOPERATIONDEFINITION_SCHEDULED: 'Interpolating elevation...',
  OP_MESHELEVATIONOPERATIONDEFINITION_SUCCEEDED: '',
  OP_MESHELEVATIONOPERATIONDEFINITION_OUTDATED: 'Interpolation outdated',

  OP_REMOVEMESHPROPERTYOPERATIONDEFINITION_NAME: 'Removing mesh property',
  OP_REMOVEMESHPROPERTYOPERATIONDEFINITION_DRAFT: '',
  OP_REMOVEMESHPROPERTYOPERATIONDEFINITION_PROCESSING: 'Syncing attributes...',
  OP_REMOVEMESHPROPERTYOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_REMOVEMESHPROPERTYOPERATIONDEFINITION_FAILED: '',
  OP_REMOVEMESHPROPERTYOPERATIONDEFINITION_SCHEDULED: 'Syncing attributes...',
  OP_REMOVEMESHPROPERTYOPERATIONDEFINITION_SUCCEEDED: '',
  /**
   * Variable operations initiated by api
   */

  OP_CREATEVARIABLEFROMGEOJSONOPERATIONDEFINITION_NAME: 'Create variable',
  OP_CREATEVARIABLEFROMGEOJSONOPERATIONDEFINITION: 'Create variable',
  OP_CREATEVARIABLEFROMGEOJSONOPERATIONDEFINITION_DESCRIPTION: '',
  OP_CREATEVARIABLEFROMGEOJSONOPERATIONDEFINITION_DRAFT: '',
  OP_CREATEVARIABLEFROMGEOJSONOPERATIONDEFINITION_PROCESSING: 'Creating variable...',
  OP_CREATEVARIABLEFROMGEOJSONOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_CREATEVARIABLEFROMGEOJSONOPERATIONDEFINITION_FAILED: '',
  OP_CREATEVARIABLEFROMGEOJSONOPERATIONDEFINITION_SCHEDULED: 'Creating variable...',
  OP_CREATEVARIABLEFROMGEOJSONOPERATIONDEFINITION_SUCCEEDED: '',

  OP_DUPLICATEVARIABLEOPERATIONDEFINITION_NAME: 'Duplicate variable',
  OP_DUPLICATEVARIABLEOPERATIONDEFINITION: 'Duplicate variable',
  OP_DUPLICATEVARIABLEOPERATIONDEFINITION_DESCRIPTION: '',
  OP_DUPLICATEVARIABLEOPERATIONDEFINITION_DRAFT: '',
  OP_DUPLICATEVARIABLEOPERATIONDEFINITION_PROCESSING: 'Duplicating variable...',
  OP_DUPLICATEVARIABLEOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_DUPLICATEVARIABLEOPERATIONDEFINITION_FAILED: '',
  OP_DUPLICATEVARIABLEOPERATIONDEFINITION_SCHEDULED: 'Duplicating variable...',
  OP_DUPLICATEVARIABLEOPERATIONDEFINITION_SUCCEEDED: '',

  OP_UPDATEVARIABLEDATAOPERATIONDEFINITION_NAME: 'Update variable',
  OP_UPDATEVARIABLEDATAOPERATIONDEFINITION: 'Update variable',
  OP_UPDATEVARIABLEDATAOPERATIONDEFINITION_DESCRIPTION: '',
  OP_UPDATEVARIABLEDATAOPERATIONDEFINITION_DRAFT: '',
  OP_UPDATEVARIABLEDATAOPERATIONDEFINITION_PROCESSING: 'Updating variable...',
  OP_UPDATEVARIABLEDATAOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_UPDATEVARIABLEDATAOPERATIONDEFINITION_FAILED: '',
  OP_UPDATEVARIABLEDATAOPERATIONDEFINITION_SCHEDULED: 'Updating variable...',
  OP_UPDATEVARIABLEDATAOPERATIONDEFINITION_SUCCEEDED: '',

  OP_EXTENDMESHOPERATIONDEFINITION_NAME: 'Extend mesh',
  OP_EXTENDMESHOPERATIONDEFINITION: 'Extend mesh',
  OP_EXTENDMESHOPERATIONDEFINITION_DESCRIPTION: '',
  OP_EXTENDMESHOPERATIONDEFINITION_PROCESSING: 'Extending mesh in progress...',
  OP_EXTENDMESHOPERATIONDEFINITION_SCHEDULED: 'Extending mesh waiting to start...',
  OP_EXTENDMESHOPERATIONDEFINITION_FAILED: 'Extending mesh failed',
  OP_EXTENDMESHOPERATIONDEFINITION_SUCCEEDED: '',
  OP_EXTENDMESHOPERATIONDEFINITION_DRAFT: '',

  OP_MERGEMESHOPERATIONDEFINITION: 'Merge mesh',
  OP_MERGEMESHOPERATIONDEFINITION_DESCRIPTION: '',
  OP_MERGEMESHOPERATIONDEFINITION_PROCESSING: 'Merge mesh in progress...',
  OP_MERGEMESHOPERATIONDEFINITION_SCHEDULED: 'Merge mesh waiting to start...',
  OP_MERGEMESHOPERATIONDEFINITION_FAILED: 'Merge mesh failed',
  OP_MERGEMESHOPERATIONDEFINITION_SUCCEEDED: '',
  OP_MERGEMESHOPERATIONDEFINITION_DRAFT: '',

  OP_DERIVEGEOMETRYOPERATIONDEFINITION: 'Derive geometry',
  OP_DERIVEGEOMETRYOPERATIONDEFINITION_DESCRIPTION: '',
  OP_DERIVEGEOMETRYOPERATIONDEFINITION_PROCESSING: 'Derive geometry in progress...',
  OP_DERIVEGEOMETRYOPERATIONDEFINITION_SCHEDULED: 'Derive geometry waiting to start...',
  OP_DERIVEGEOMETRYOPERATIONDEFINITION_FAILED: 'Derive geometry failed',
  OP_DERIVEGEOMETRYOPERATIONDEFINITION_SUCCEEDED: '',
  OP_DERIVEGEOMETRYOPERATIONDEFINITION_DRAFT: '',

  /**
   *  Edit mesh nodes operations
   */
  OP_MOVENODEOPERATIONDEFINITION_DRAFT: '',
  OP_MOVENODEOPERATIONDEFINITION_PROCESSING: 'Saving mesh nodes...',
  OP_MOVENODEOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_MOVENODEOPERATIONDEFINITION_FAILED: '',
  OP_MOVENODEOPERATIONDEFINITION_SCHEDULED: 'Saving mesh nodes...',
  OP_MOVENODEOPERATIONDEFINITION_SUCCEEDED: '',

  /**
   * Variable Operation states in general. Used if more specific messages are not available
   * Will fallback to more general ones when empty
   */
  OP_VARIABLE_DRAFT: '',
  OP_VARIABLE_PROCESSING: '',
  OP_VARIABLE_PARTIALLYSUCCEEDED: '',
  OP_VARIABLE_FAILED: '',
  OP_VARIABLE_SCHEDULED: '',
  OP_VARIABLE_SUCCEEDED: '',

  /**
   * Operation states in general. Used if more specific messages are not available
   */
  OP_DRAFT: 'Draft',
  OP_PROCESSING: 'In progress...',
  OP_PARTIALLYSUCCEEDED: 'Partially succeeded',
  OP_FAILED: 'Failed',
  OP_SCHEDULED: 'Waiting to start...',
  OP_SUCCEEDED: 'Succeeded',
  OP_OUTDATED: 'Outdated',

  /**
   * Mesh-specific strings
   */
  DELETE_MESH_TIP: 'Select meshes to delete.',
  DUPLICATE_MESH_TIP: 'Select meshes to duplicate.',

  SELECT_TO_SEE_OPERATIONS: 'Make a selection in order to see available operations',
  TRIANGULARMESHOPERATION: 'Triangulate',
  CGALTRIANGULARMESHOPERATION: 'Triangulate (CGAL)',
  QUADRILATERALMESHOPERATION: 'Quadrangulate',

  /**
   * Mesh Post operations
   */
  OP_REMESHOPERATIONDEFINITION_NAME: 'Local remesh',
  OP_REMESHOPERATIONDEFINITION: 'Local remesh',
  OP_REMESHOPERATIONDEFINITION_DESCRIPTION: '',
  OP_REMESHOPERATIONDEFINITION_PROCESSING: 'Remeshing in progress...',
  OP_REMESHOPERATIONDEFINITION_SCHEDULED: 'Remeshing waiting to start...',
  OP_REMESHOPERATIONDEFINITION_FAILED: 'Remeshing failed',
  OP_REMESHOPERATIONDEFINITION_SUCCEEDED: '',
  OP_REMESHOPERATIONDEFINITION_DRAFT: '',

  OP_REFINEMESHOPERATIONDEFINITION_NAME: 'Refine',
  OP_REFINEMESHOPERATIONDEFINITION: 'Refine',
  OP_REFINEMESHOPERATIONDEFINITION_DESCRIPTION: '',
  OP_REFINEMESHOPERATIONDEFINITION_PROCESSING: 'Refining in progress...',
  OP_REFINEMESHOPERATIONDEFINITION_SCHEDULED: 'Refining waiting to start...',
  OP_REFINEMESHOPERATIONDEFINITION_FAILED: 'Refining failed',
  OP_REFINEMESHOPERATIONDEFINITION_SUCCEEDED: '',
  OP_REFINEMESHOPERATIONDEFINITION_DRAFT: '',

  OP_SMOOTHMESHOPERATIONDEFINITION_NAME: 'Smooth',
  OP_SMOOTHMESHOPERATIONDEFINITION: 'Smooth',
  OP_SMOOTHMESHOPERATIONDEFINITION_DESCRIPTION: '',
  OP_SMOOTHMESHOPERATIONDEFINITION_PROCESSING: 'Smoothing in progress...',
  OP_SMOOTHMESHOPERATIONDEFINITION_SCHEDULED: 'Smoothing waiting to start...',
  OP_SMOOTHMESHOPERATIONDEFINITION_FAILED: 'Smoothing failed',
  OP_SMOOTHMESHOPERATIONDEFINITION_SUCCEEDED: '',
  OP_SMOOTHMESHOPERATIONDEFINITION_DRAFT: '',

  OP_QUADDOMINANTMESHOPERATIONDEFINITION_NAME: 'Quad-dominant',
  OP_QUADDOMINANTMESHOPERATIONDEFINITION: 'Quad-dominant',
  OP_QUADDOMINANTMESHOPERATIONDEFINITION_DESCRIPTION: '',
  OP_QUADDOMINANTMESHOPERATIONDEFINITION_PROCESSING: 'Quad-dominant in progress...',
  OP_QUADDOMINANTMESHOPERATIONDEFINITION_SCHEDULED: 'Quad-dominant waiting to start...',
  OP_QUADDOMINANTMESHOPERATIONDEFINITION_FAILED: 'Quad-dominant failed',
  OP_QUADDOMINANTMESHOPERATIONDEFINITION_SUCCEEDED: '',
  OP_QUADDOMINANTMESHOPERATIONDEFINITION_DRAFT: '',

  OP_REDUCENODALCONNECTIVITYOPERATIONDEFINITION_NAME: 'Reduce node-elements',
  OP_REDUCENODALCONNECTIVITYOPERATIONDEFINITION: 'Reduce node-elements',
  OP_REDUCENODALCONNECTIVITYOPERATIONDEFINITION_DESCRIPTION: '',
  OP_REDUCENODALCONNECTIVITYOPERATIONDEFINITION_PROCESSING: 'Reduce node-elements in progress...',
  OP_REDUCENODALCONNECTIVITYOPERATIONDEFINITION_SCHEDULED: 'Reduce node-elements waiting to start...',
  OP_REDUCENODALCONNECTIVITYOPERATIONDEFINITION_FAILED: 'Reduce node-elements failed',
  OP_REDUCENODALCONNECTIVITYOPERATIONDEFINITION_SUCCEEDED: '',
  OP_REDUCENODALCONNECTIVITYOPERATIONDEFINITION_DRAFT: '',

  OP_DELETEMESHELEMENTSOPERATIONDEFINITION_NAME: 'Delete elements',
  OP_DELETEMESHELEMENTSOPERATIONDEFINITION: 'Delete elements',
  OP_DELETEMESHELEMENTSOPERATIONDEFINITION_DESCRIPTION: '',
  OP_DELETEMESHELEMENTSOPERATIONDEFINITION_PROCESSING: 'Delete elements in progress...',
  OP_DELETEMESHELEMENTSOPERATIONDEFINITION_SCHEDULED: 'Delete elements waiting to start...',
  OP_DELETEMESHELEMENTSOPERATIONDEFINITION_FAILED: 'Delete elements failed',
  OP_DELETEMESHELEMENTSOPERATIONDEFINITION_SUCCEEDED: '',
  OP_DELETEMESHELEMENTSOPERATIONDEFINITION_DRAFT: '',

  OP_ASSIGNNODETYPESOPERATIONDEFINITION_NAME: 'Assign node markers',
  OP_ASSIGNNODETYPESOPERATIONDEFINITION: 'Assign node markers',
  OP_ASSIGNNODETYPESOPERATIONDEFINITION_DESCRIPTION: '',
  OP_ASSIGNNODETYPESOPERATIONDEFINITION_PROCESSING: 'Assign node markers in progress...',
  OP_ASSIGNNODETYPESOPERATIONDEFINITION_SCHEDULED: 'Assign node markers waiting to start...',
  OP_ASSIGNNODETYPESOPERATIONDEFINITION_FAILED: 'Assign node markers failed',
  OP_ASSIGNNODETYPESOPERATIONDEFINITION_SUCCEEDED: '',
  OP_ASSIGNNODETYPESOPERATIONDEFINITION_DRAFT: '',

  // States for operations with mesh result type. Used if more specific messages are not available
  OP_MESH_DRAFT: 'Draft',
  OP_MESH_PROCESSING: 'Meshing in progress...',
  OP_MESH_PARTIALLYSUCCEEDED: 'Meshing partially succeeded',
  OP_MESH_FAILED: 'Meshing failed',
  OP_MESH_SCHEDULED: 'Meshing waiting to start...',
  OP_MESH_SUCCEEDED: 'Meshing succeeded',
  OP_MESH_CONFLICTING: 'Some applied operations failed',
  OP_MESH_OUTDATED: 'Mesh is outdated',

  OP_MESH_OUTDATED_INFO: 'This can happen if a geometry or variable used in the mesh has been edited.',

  PARAM_VALUE_ON: 'On',
  PARAM_VALUE_OFF: 'Off',

  PAN_HORIZONTALY: 'Pan horizontaly',
  PAN_VERTICALLY: 'Pan vertically',
  TILT_HORIZONTALY: 'Tilt horizontaly',
  TILT_VERTICALLY: 'Tilt vertically',

  PARAM_CREATESOURCEITEMCOPY: 'Create geometry copy',

  /** Mesh operation parameters */
  PARAM_MINANGLE: 'Min angle',
  PARAM_MINANGLE_SHORT: 'Min angle',
  PARAM_MINANGLE_PLACEHOLDER: 'i.e. 20.7',
  PARAM_MINANGLE_INFO: '',

  PARAM_CFLLIMIT: 'CFL limit',
  PARAM_CFLLIMIT_SHORT: 'CFL limit',
  PARAM_CFLLIMIT_PLACEHOLDER: 'i.e. 1',
  PARAM_CFLLIMIT_INFO: '',

  PARAM_SEALEVEL: 'Sea level',
  PARAM_SEALEVEL_INFO: 'Sea level',
  PARAM_SEALEVEL_PLACEHOLDER: 'Sea level',
  /** Params for triangulate */
  PARAM_SIZEBOUND: 'Max element face length',
  PARAM_SIZEBOUND_SHORT: 'Max element face length [m]',
  PARAM_SIZEBOUND_PLACEHOLDER: '',
  PARAM_SIZEBOUND_INFO:
    'Max element face length determines the maximum length of a triangle face inside the geometry, limiting the size of the triangles inside the geometry. 0 means the value is ignored and the triangle size will be restricted by the domain and the vertices along a boundary.',

  PARAM_BORDERSIZEBOUND: 'Element length at mesh border',
  PARAM_BORDERSIZEBOUND_SHORT: 'Element length at border[m]',
  PARAM_BORDERSIZEBOUND_PLACEHOLDER: '',
  PARAM_BORDERSIZEBOUND_INFO:
    'Element length at meshborder determines the maximum length of the triangle face that forms the mesh border, limiting the size of triangles at the part of the border of the mesh defined by the geometry.',

  PARAM_GRADUALSIZEENABLED: 'Enable gradual size',
  PARAM_GRADUALSIZEENABLED_SHORT: 'Gradual size',
  PARAM_GRADUALSIZEENABLED_PLACEHOLDER: '',
  PARAM_GRADUALSIZEENABLED_INFO: 'Make the transition from small to large elements smoother',

  PARAM_MAXAREA: 'Maximum area of element',
  PARAM_MAXAREA_SHORT: 'Maximum area of element',
  PARAM_MAXAREA_PLACEHOLDER: '',
  PARAM_MAXAREA_INFO: '',

  PARAM_MAXBORDERAREA: 'Maximum area of element at border',
  PARAM_MAXBORDERAREA_SHORT: 'Maximum area of element at border',
  PARAM_MAXBORDERAREA_PLACEHOLDER: '',
  PARAM_MAXBORDERAREA_INFO: '',

  /** Params for quadrangulate */
  PARAM_ROWCOUNT: 'Rows',
  PARAM_ROWCOUNT_SHORT: 'Rows',
  PARAM_ROWCOUNT_PLACEHOLDER: '',
  PARAM_ROWCOUNT_INFO: '',

  PARAM_COLUMNCOUNT: 'Columns',
  PARAM_COLUMNCOUNT_SHORT: 'Columns',
  PARAM_COLUMNCOUNT_PLACEHOLDER: '',
  PARAM_COLUMNCOUNT_INFO: '',

  PARAM_COLUMNWIDTH: 'Optimal length flow direction',
  PARAM_COLUMNWIDTH_SHORT: 'Optimal length flow direction',
  PARAM_COLUMNWIDTH_INFO: '',
  PARAM_COLUMNWIDTH_PLACEHOLDER: '',

  PARAM_WIDTH: 'Buffer width',
  PARAM_WIDTH_SHORT: 'Buffer width',
  PARAM_WIDTH_PLACEHOLDER: '',
  PARAM_WIDTH_INFO: '',

  PARAM_TRIANGLEAREA: 'Maximum triangle area',
  PARAM_TRIANGLEAREA_SHORT: 'Maximum triangle area',
  PARAM_TRIANGLEAREA_PLACEHOLDER: '',
  PARAM_TRIANGLEAREA_INFO: '',

  PARAM_TRIANGLEBORDERAREA: 'Maximum triangle area at border',
  PARAM_TRIANGLEORDERAREA_SHORT: 'Maximum triangle area at border',
  PARAM_TRIANGLEORDERAREA_PLACEHOLDER: '',
  PARAM_TRIANGLEORDERAREA_INFO: '',

  /** Params for radial mesher*/
  PARAM_RADIUS: 'Radius',
  PARAM_RADIUS_SHORT: 'Radius',
  PARAM_RADIUS_PLACEHOLDER: '',
  PARAM_RADIUS_INFO: '',

  PARAM_HOLERADIUS: 'Radius of hole',
  PARAM_HOLERADIUS_SHORT: 'Radius of hole',
  PARAM_HOLERADIUS_PLACEHOLDER: '',
  PARAM_HOLERADIUS_INFO: '',

  PARAM_FILLHOLES: 'Fill hole',
  PARAM_FILLHOLES_SHORT: 'Fill hole',
  PARAM_FILLHOLES_PLACEHOLDER: '',
  PARAM_FILLHOLES_INFO: '',

  PARAM_RESOLUTIONMETHOD: 'Resolution method',
  PARAM_RESOLUTIONMETHOD_INFO: '',
  PARAM_RESOLUTIONMETHOD_PLACEHOLDER: 'select resolution method',
  PARAM_VALUE_RESOLUTIONMETHOD_NUMBEROFELEMENTS: 'Number of elements',
  PARAM_VALUE_RESOLUTIONMETHOD_SIZEOFELEMENTS: 'Size of elements',

  PARAM_ROWHEIGHT: 'Optimal length transverse direction',
  PARAM_ROWHEIGHT_SHORT: 'Optimal length transverse direction',
  PARAM_ROWHEIGHT_INFO: '',
  PARAM_ROWHEIGHT_PLACEHOLDER: '',

  PARAM_RADIALBORDERSIZE: 'Element length at mesh border',
  PARAM_RADIALBORDERSIZE_SHORT: 'Element length at border[m]',
  PARAM_RADIALBORDERSIZE_PLACEHOLDER: '',
  PARAM_RADIALBORDERSIZE_INFO:
    'Element length at mesh border determines the maximum length of the triangle face that forms the mesh border, limiting the size of triangles at the part of the border of the mesh defined by the circumreference of the circle.',

  PARAM_SLICEANGLE: 'Slice angle',
  PARAM_SLICEANGLE_SHORT: 'Slice angle',
  PARAM_SLICEANGLE_PLACEHOLDER: '',
  PARAM_SLICEANGLE_INFO: '',

  PARAM_ORIENTATIONANGLE: 'Orientation angle',
  PARAM_ORIENTATIONANGLE_SHORT: 'Orientation angle',
  PARAM_ORIENTATIONANGLE_PLACEHOLDER: '',
  PARAM_ORIENTATIONANGLE_INFO: '',

  PARAM_RADIALELEMENTTYPE: 'Element type',
  PARAM_RADIALELEMENTTYPE_SHORT: 'Element type',
  PARAM_RADIALELEMENTTYPE_PLACEHOLDER: '',
  PARAM_RADIALELEMENTTYPE_INFO: '',

  PARAM_TRIANGLESIZE: 'Max element face length',
  PARAM_TRIANGLESIZE_SHORT: 'Max element face length [m]',
  PARAM_TRIANGLESIZE_PLACEHOLDER: '',
  PARAM_TRIANGLESIZE_INFO:
    'Max element face length determines the maximum length of a triangle face inside the geometry, limiting the size of the triangles inside the geometry. 0 means the value is ignored and the triangle size will be restricted by the domain and the vertices along a boundary.',

  PARAM_INSERTONLYVERTICES: 'Use only vertices',
  PARAM_INSERTONLYVERTICES_SHORT: 'Vertices only',
  PARAM_INSERTONLYVERTICES_PLACEHOLDER: '',
  PARAM_INSERTONLYVERTICES_INFO: 'Only insert the vertices into the mesh, not the edge segments.',

  PARAM_TRIANGLEBORDERSIZE: 'Element length at mesh border',
  PARAM_TRIANGLEBORDERSIZE_SHORT: 'Element length at border[m]',
  PARAM_TRIANGLEBORDERSIZE_PLACEHOLDER: '',
  PARAM_TRIANGLEBORDERSIZE_INFO:
    'Element length at meshborder determines the maximum length of the triangle face that forms the mesh border, limiting the size of triangles at the part of the border of the mesh defined by the geometry.',

  //interpolation method
  PARAM_INTERPOLATIONMETHOD: 'Interpolation method',
  PARAM_INTERPOLATIONMETHOD_SHORT: 'Interpolation method',
  PARAM_INTERPOLATIONMETHOD_PLACEHOLDER: '',
  PARAM_INTERPOLATIONMETHOD_INFO: '',

  PARAM_VALUE_METHOD_UNSPECIFIED: 'Unspecified',
  PARAM_VALUE_METHOD_BILINEAR: 'Bilinear',
  PARAM_VALUE_METHOD_INVERSEDISTANCEWEIGHTING: 'Inverse distance weighting',
  PARAM_VALUE_METHOD_NEARESTNEIGBOUR: 'Nearest neigbour',
  PARAM_VALUE_METHOD_NATURALNEIGBOUR: 'Natural neigbour',
  PARAM_VALUE_METHOD_LINEAR: 'Linear (* only valid on channels)',

  // radial element values
  PARAM_VALUE_RADIALELEMENTTYPE_QUAD: 'Quads',
  PARAM_VALUE_RADIALELEMENTTYPE_TRIANGLE: 'Triangles',

  /** Params for rectangular mesher */
  PARAM_ELEMENTWIDTH: 'Element width',
  PARAM_ELEMENTWIDTH_SHORT: 'Element width',
  PARAM_ELEMENTWIDTH_PLACEHOLDER: '',
  PARAM_ELEMENTWIDTH_INFO: '',

  PARAM_ELEMENTHEIGHT: 'Element height',
  PARAM_ELEMENTHEIGHT_SHORT: 'Element height',
  PARAM_ELEMENTHEIGHT_PLACEHOLDER: '',
  PARAM_ELEMENTHEIGHT_INFO: '',

  PARAM_RECTANGLEBORDERPOLICY: 'Border policy',
  PARAM_RECTANGLEBORDERPOLICY_SHORT: 'Border policy',
  PARAM_RECTANGLEBORDERPOLICY_PLACEHOLDER: '',
  PARAM_RECTANGLEBORDERPOLICY_INFO: `When defining two or more nested rectangular areas, Border policy of the outer polygon must be 'Fill with triangles' and the inner polygon(s) Border policy as 'Leave gap'.`,

  // Border policy values
  PARAM_VALUE_RECTANGLEBORDERPOLICY_OVERLAP: 'Overlap',
  PARAM_VALUE_RECTANGLEBORDERPOLICY_FILLWITHTRIANGLES: 'Fill with triangles',
  PARAM_VALUE_RECTANGLEBORDERPOLICY_LEAVEGAP: 'Leave gap',

  PARAM_BORDERBUFFER: 'Border buffer width',
  PARAM_TRIANGLESIZEBOUND: 'Max triangle face length',

  /** Density function */
  PARAM_APPLYDENSITYPARAMETERS: 'Use background density function',
  PARAM_APPLYDENSITYPARAMETERS_SHORT: 'Density function',
  PARAM_APPLYDENSITYPARAMETERS_PLACEHOLDER: '',
  PARAM_APPLYDENSITYPARAMETERS_INFO:
    'Select a raster variable to be used as mesh density function for guiding the meshing process. The required inputs are a target number of mesh points to be generated in the bounding polygonal domain and the density function to be used.',

  PARAM_APPLYGRADIENT: 'Apply gradient',
  PARAM_APPLYGRADIENT_SHORT: 'Gradient',
  PARAM_APPLYGRADIENT_PLACEHOLDER: '',
  PARAM_APPLYGRADIENT_INFO: 'Use the gradient magnitude of the variable as mesh density function.',

  PARAM_INVERTDENSITY: 'Invert the density function',
  PARAM_INVERTDENSITY_SHORT: 'Invert density',
  PARAM_INVERTDENSITY_PLACEHOLDER: '',
  PARAM_INVERTDENSITY_INFO: 'A higher densification will result where the variable data contains lower values.',

  PARAM_VARIABLEID: 'Select a variable or no variable',
  PARAM_VARIABLEID_SHORT: 'Variable',
  PARAM_VARIABLEID_PLACEHOLDER: 'Use without a variable',
  PARAM_VARIABLEID_INFO: '',

  PARAM_NUMBEROFPOINTS: 'Number of points',
  PARAM_NUMBEROFPOINTS_SHORT: 'N. of points',
  PARAM_NUMBEROFPOINTS_PLACEHOLDER: '',
  PARAM_NUMBEROFPOINTS_INFO: '',

  PARAM_DENSITYBUFFERSIZE: 'Buffer size',
  PARAM_DENSITYBUFFERSIZE_SHORT: 'Buffer size',
  PARAM_DENSITYBUFFERSIZE_INFO: 'The size of a buffer around geometries within with densification should be excluded.',
  PARAM_DENSITYBUFFERSIZE_PLACEHOLDER: '',

  /** Mesh interpolation parameters */
  PARAM_PROPERTYNAME: 'Result property',
  PARAM_PROPERTYNAME_SHORT: 'Result property',
  PARAM_PROPERTYNAME_PLACEHOLDER: 'Result property',
  PARAM_PROPERTYNAME_INFO: 'The property to hold the interpolation result',

  PARAM_SOURCEPROPERTYNAME: 'Source property',
  PARAM_SOURCEPROPERTYNAME_SHORT: 'Source property',
  PARAM_SOURCEPROPERTYNAME_PLACEHOLDER: 'Source property',
  PARAM_SOURCEPROPERTYNAME_INFO: 'The property to be used as input for the interpolation',

  PARAM_PRIORITY: 'Priority',
  PARAM_PRIORITY_SHORT: 'Priority',
  PARAM_PRIORITY_PLACEHOLDER: '',
  PARAM_PRIORITY_INFO:
    'Prioritisation of variable datasets is set by a simple numbering convention with the lowest number establishing the highest (first) priority.',

  PARAM_DERIVETYPE: 'Derive type',
  PARAM_DERIVETYPE_SHORT: 'Derive type',
  PARAM_DERIVETYPE_PLACEHOLDER: '',
  PARAM_DERIVETYPE_INFO: '',

  PARAM_VALUE_DERIVETYPE_DOMAIN_AS_POLYGON: 'Domain as polygon',
  PARAM_VALUE_DERIVETYPE_REGIONS_AS_POLYGONS: 'Regions as polygons',
  PARAM_VALUE_DERIVETYPE_NODE_MARKERS_AS_POLYLINES: 'Node markers as polylines',
  PARAM_VALUE_DERIVETYPE_NODE_MARKERS_AS_POINTS: 'Node markers as points',

  /** Geometry operations (Mesh Builder api) */
  OPERATION_CONFIRM_DELETE_TITLE: 'Delete operation confirmation',
  OPERATION_CONFIRM_DELETE_TEXT: 'Are you sure want to cancel the operation?',

  //  Aggregate operation
  PARAM_AGGREGATEDISTANCE: 'Distance between original polygons',
  PARAM_AGGREGATEDISTANCE_INFO: '',
  PARAM_AGGREGATEDISTANCE_PLACEHOLDER: '',

  // Simplify (client-side)
  PARAM_SIMPLIFYTOLERANCESPEC: 'Percentage change of polygon outline',
  PARAM_SIMPLIFYTOLERANCESPEC_SHORT: 'Percentage change of polygon outline',
  PARAM_SIMPLIFYTOLERANCESPEC_PLACEHOLDER: '',
  PARAM_SIMPLIFYTOLERANCESPEC_INFO: '',

  // Simplify (backend-side)
  PARAM_SIMPLIFICATIONMETHOD: 'Simplification method',
  PARAM_SIMPLIFICATIONMETHOD_SHORT: 'Simplification method',
  PARAM_SIMPLIFICATIONMETHOD_PLACEHOLDER: '',
  PARAM_SIMPLIFICATIONMETHOD_INFO: '',

  PARAM_VALUE_SIMPLIFICATIONMETHOD_DOUGLASPEUCKER: 'Douglas-Peucker',
  PARAM_VALUE_SIMPLIFICATIONMETHOD_REMOVESMALLSEGMENTS: 'Remove small segments',

  PARAM_CONCAVITY: 'Polygon outline fidelity',
  PARAM_CONCAVITY_SHORT: 'Polygon outline fidelity',
  PARAM_CONCAVITY_PLACEHOLDER: '',
  PARAM_CONCAVITY_INFO: '',

  // Buffer operation
  PARAM_DISTANCESPEC: 'Distance',
  PARAM_DISTANCESPEC_PLACEHOLDER: '',
  PARAM_DISTANCESPEC_INFO: '',

  PARAM_BUFFERDISTANCE: 'Distance',
  PARAM_BUFFERDISTANCE_INFO: '',
  PARAM_BUFFERDISTANCE_PLACEHOLDER: '',

  PARAM_DENSIFYEDGELENGTH: 'Edge length',
  PARAM_DENSIFYEDGELENGTH_INFO: '',
  PARAM_DENSIFYEDGELENGTH_PLACEHOLDER: '',

  // Clean operation
  PARAM_REMOVEDUPLICATES: 'Remove duplicates from geometry',
  PARAM_REMOVEDUPLICATES_PLACEHOLDER: 'Remove duplicates',
  PARAM_REMOVEDUPLICATES_INFO: '',

  PARAM_REMOVENONSIMPLE: 'Remove non-simple geometries',
  PARAM_REMOVENONSIMPLE_PLACEHOLDER: '',
  PARAM_REMOVENONSIMPLE_INFO: '',

  PARAM_REDUCEPRECISION: 'Reduce precision of coordinates',
  PARAM_REDUCEPRECISION_PLACEHOLDER: '',
  PARAM_REDUCEPRECISION_INFO: '',

  PARAM_REMOVESMALL: 'Remove small geometries',
  PARAM_REMOVESMALL_PLACEHOLDER: '',
  PARAM_REMOVESMALL_INFO: '',

  PARAM_BUFFERSMALL: 'Buffer small geometries',
  PARAM_BUFFERSMALL_PLACEHOLDER: '',
  PARAM_BUFFERSMALL_INFO: '',

  PARAM_REMOVEPOINTSONEDGES: 'Remove points which are on or near edges',
  PARAM_REMOVEPOINTSONEDGES_PLACEHOLDER: '',
  PARAM_REMOVEPOINTSONEDGES_INFO: '',

  PARAM_SNAPTOSELF: 'Snap vertices and edges of the geometry to itself',
  PARAM_SNAPTOSELF_PLACEHOLDER: '',
  PARAM_SNAPTOSELF_INFO: '',

  PARAM_SNAPTONEAR: 'Snap vertices & edges of geometries near to each other',
  PARAM_SNAPTONEAR_PLACEHOLDER: '',
  PARAM_SNAPTONEAR_INFO: '',

  PARAM_SNAPTOVERTICES: 'Snap to vertices',
  PARAM_SNAPTOVERTICES_PLACEHOLDER: '',
  PARAM_SNAPTOVERTICES_INFO: '',

  // Densify operation
  PARAM_EDGELENGTHSPEC: 'Edge length',
  PARAM_EDGELENGTHSPEC_PLACEHOLDER: '',
  PARAM_EDGELENGTHSPEC_INFO: '',

  // Smooth operation
  PARAM_CONTROLPOINTCOUNT: 'Control points count',
  PARAM_CONTROLPOINTCOUNT_PLACEHOLDER: '',
  PARAM_CONTROLPOINTCOUNT_INFO: '',

  // Densify / smooth Operation
  PARAM_KEEPPOINTS: 'Keep geometry points',
  PARAM_KEEPPOINTS_PLACEHOLDER: '',
  PARAM_KEEPPOINTS_INFO: '',

  // Simplify operation (client-side)
  PARAM_TOLERANCESPEC: 'Tolerance',
  PARAM_TOLERANCESPEC_PLACEHOLDER: '',
  PARAM_TOLERANCESPEC_INFO: '',

  PARAM_SIMPLIFYTOLERANCE: 'Tolerance',
  PARAM_SIMPLIFYTOLERANCE_INFO: '',
  PARAM_SIMPLIFYTOLERANCE_PLACEHOLDER: '',

  // Dissolve operation (backend-side)
  PARAM_DISSOLVETOLERANCE: 'Dissolve tolerance',
  PARAM_DISSOLVETOLERANCE_INFO: '',
  PARAM_DISSOLVETOLERANCE_PLACEHOLDER: '',

  // Simplify buildings operation
  PARAM_SIMPLIFYDISTANCE: 'Simplification distance',
  PARAM_SIMPLIFYDISTANCE_PLACEHOLDER: '',
  PARAM_SIMPLIFYDISTANCE_INFO: '',

  PARAM_SIMPLIFYANGLE: 'Simplification angle',
  PARAM_SIMPLIFYANGLE_INFO: '',
  PARAM_SIMPLIFYANGLE_PLACEHOLDER: '',

  PARAM_SNAPDISTANCE: 'Snap distance',
  PARAM_SNAPDISTANCE_INFO: '',
  PARAM_SNAPDISTANCE_PLACEHOLDER: '',

  // Clip operation
  PARAM_MODE: 'Operator',
  PARAM_MODE_SHORT: 'Operator',
  PARAM_MODE_PLACEHOLDER: 'Select operator',
  PARAM_MODE_INFO: '',

  SMALLEST_ELEMENTS_HEADER: 'By smallest value',
  BIGGEST_ELEMENTS_HEADER: 'By biggest value',
  ZOOM_IN: 'Zoom in',
  EDIT_NODE: 'Edit node',

  // clip operation mode values
  PARAM_VALUE_MODE_INTERSECTION: 'Intersection',
  PARAM_VALUE_MODE_UNION: 'Union',
  PARAM_VALUE_MODE_DIFFERENCE: 'Difference',
  PARAM_VALUE_MODE_XOR: 'Xor',

  PARAM_CLIPITEMID: 'Clip-polygons',
  PARAM_CLIPITEMID_SHORT: 'Clip-polygons',
  PARAM_CLIPITEMID_PLACEHOLDER: 'Select Clip-polygons',
  PARAM_CLIPITEMID_INFO: 'Used to clip the selected geometries',

  /** Coastline mesher */
  OP_COASTLINEOPERATIONDEFINITION: 'Coastline',
  OP_COASTLINEOPERATIONDEFINITION_NAME: 'Coastline',
  OP_COASTLINEOPERATIONDEFINITION_DRAFT: '',
  OP_COASTLINEOPERATIONDEFINITION_PROCESSING: '',
  OP_COASTLINEOPERATIONDEFINITION_PARTIALLYSUCCEEDED: '',
  OP_COASTLINEOPERATIONDEFINITION_FAILED: '',
  OP_COASTLINEOPERATIONDEFINITION_SCHEDULED: '',
  OP_COASTLINEOPERATIONDEFINITION_SUCCEEDED: '',
  OP_COASTLINEOPERATIONDEFINITION_OUTDATED: '',

  PARAM_CIRCLEITEMID: 'Circle',
  PARAM_CIRCLEITEMID_SHORT: 'Circle',
  PARAM_CIRCLEITEMID_PLACEHOLDER: '',
  PARAM_CIRCLEITEMID_INFO: '',

  PARAM_COASTLINESIZEBOUND: 'Element length at coastline',
  PARAM_COASTLINESIZEBOUND_SHORT: 'Element length at coastline[m]',
  PARAM_COASTLINESIZEBOUND_PLACEHOLDER: '',
  PARAM_COASTLINESIZEBOUND_INFO: '',

  PARAM_OPENBOUNDARYSIZEBOUND: 'Element length at open boundary',
  PARAM_OPENBOUNDARYSIZEBOUND_SHORT: 'Element length at open boundary[m]',
  PARAM_OPENBOUNDARYSIZEBOUND_PLACEHOLDER: '',
  PARAM_OPENBOUNDARYSIZEBOUND_INFO: '',

  PARAM_USEAREAOFINTEREST: 'Include an area of interest',
  PARAM_USEAREAOFINTEREST_SHORT: 'Include an area of interest',
  PARAM_USEAREAOFINTEREST_PLACEHOLDER: '',
  PARAM_USEAREAOFINTEREST_INFO: '',

  PARAM_AREAOFINTERESTITEMID: 'Area of interest',
  PARAM_AREAOFINTERESTITEMID_SHORT: 'Area of interest',
  PARAM_AREAOFINTERESTITEMID_PLACEHOLDER: 'Area of interest',
  PARAM_AREAOFINTERESTITEMID_INFO: '',

  PARAM_AREAOFINTERESTSIZEBOUND: 'Max edge length in area of interest',
  PARAM_AREAOFINTERESTSIZEBOUND_SHORT: 'Max edge length in area of interest',
  PARAM_AREAOFINTERESTSIZEBOUND_PLACEHOLDER: '',
  PARAM_AREAOFINTERESTSIZEBOUND_INFO: '',

  PARAM_MAXCOASTLINEAREA: 'Maximum area of element at coastline',
  PARAM_MAXCOASTLINEAREA_SHORT: 'Maximum area of element at coastline',
  PARAM_MAXCOASTLINEAREA_PLACEHOLDER: '',
  PARAM_MAXCOASTLINEAREA_INFO: '',

  PARAM_MAXAREAOFINTERESTAREA: 'Max. area of elements in area-of-interest',
  PARAM_MAXAREAOFINTERESTAREA_SHORT: 'Maximum area of elements in area-of-interest',
  PARAM_MAXAREAOFINTERESTAREA_PLACEHOLDER: '',
  PARAM_MAXAREAOFINTERESTAREA_INFO: '',

  /** Split operation */
  OP_SPLITGEOMETRYBYPROPERTYOPERATIONDEFINITION: 'Split by attribute',
  OP_SPLITOPERATIONDEFINITION_NAME: 'Split',
  OP_SPLITOPERATIONDEFINITION: 'Split',
  OP_SPLITOPERATIONDEFINITION_PLACEHOLDER: 'Split',
  PARAM_SPLITTERITEMID_INFO: 'Splitter - polylines',
  PARAM_SPLITTERITEMID: 'Splitter - polylines',
  PARAM_SPLITTERITEMID_PLACEHOLDER: 'Select Split-polylines',
  OP_SPLITOPERATIONDEFINITION_DESCRIPTION:
    'Split geometries. Use the splitter-polyline to split the selected geometries.',

  /** UI Mapshaper Geometry operations */

  // Dissolve operation (client-side)
  PARAM_DISSOLVE: 'Dissolve',
  PARAM_DISSOLVE_DESCRIPTION: 'Dissolve',

  // Mapshaper clean
  PARAM_MINGAPAREA: 'Min gap area',
  PARAM_SNAPINTERVAL: 'Snap interval',

  /**Geometry parameters */
  PARAM_ISHOLE: 'Exclude from meshes',
  PARAM_ISHOLE_SHORT: 'Exclude from meshes',
  PARAM_ISHOLE_PLACEHOLDER: '',
  PARAM_ISHOLE_INFO: '',

  PARAM_SNAPTOITEMID: 'Geometry to snap to',
  PARAM_SNAPTOITEMID_SHORT: 'Geometry to snap to',
  PARAM_SNAPTOITEMID_PLACEHOLDER: '',
  PARAM_SNAPTOITEMID_INFO: '',

  /** Modify mesh operation parameters */

  // Quad dominant
  PARAM_DIHEDRALANGLELOWERBOUND: 'Min angle of the squares',
  PARAM_DIHEDRALANGLELOWERBOUND_PLACEHOLDER: '',
  PARAM_DIHEDRALANGLELOWERBOUND_INFO: '',

  PARAM_DIHEDRALANGLEUPPERBOUND: 'Max angle of the squares',
  PARAM_DIHEDRALANGLEUPPERBOUND_PLACEHOLDER: '',
  PARAM_DIHEDRALANGLEUPPERBOUND_INFO: '',

  // refine
  PARAM_KEEPUNSELECTED: 'Do not allow change of border elements',
  PARAM_KEEPUNSELECTED_PLACEHOLDER: '',
  PARAM_KEEPUNSELECTED_INFO: 'Do not allow change of border elements that are not in selection',

  // smooth + quad dominant
  PARAM_ITERATIONCOUNT: 'Number of iterations',
  PARAM_ITERATIONCOUNT_PLACEHOLDER: '',
  PARAM_ITERATIONCOUNT_INFO: '',

  // Reduce node-elements (reduce nodal connectivity)
  PARAM_MAXCONNECTEDELEMENTS: 'Preferred max. no. of node-elements',
  PARAM_MAXCONNECTEDELEMENTS_PLACEHOLDER: '',
  PARAM_MAXCONNECTEDELEMENTS_INFO: '',
  PARAM_SMOOTHCOUNT: 'Number of smoothing iterations',
  PARAM_SMOOTHCOUNT_PLACEHOLDER: '',
  PARAM_SMOOTHCOUNT_INFO: '',

  /** TODO hevo where are these used */
  PARAM_NUMBEROFITERATIONS: 'Number of iterations to be performed',
  PARAM_SMOOTHPASSES: 'Number of Lloyd smoothing passes',
  PARAM_BATCHSIZE: 'Batch size',

  PARAM_APPEND: 'Append to geometry',

  /** Spatial queries on geometries */
  PARAM_EXTENDTOVERTICES: 'Extend selection to vertices',
  PARAM_EXTENDTOVERTICES_INFO: '',

  PARAM_ISOPENBOUNDARY: 'Make the selection an open boundary',
  PARAM_ISOPENBOUNDARY_INFO: '',

  // Misc operation params:
  PARAM_SNAPTOLERANCE: 'Snap tolerance',
  PARAM_SNAPTOLERANCE_SHORT: 'Snap tolerance',
  PARAM_RESOLUTIONMETHOD_SHORT: 'Resolution method',

  /** Mesh */
  FAILED_TO_LOAD_AVAILABLE_CREATE_MESH_OPERATIONS:
    "We've encountered some problems while retrieving available mesh operations. Please try again.",
  FAILED_TO_LOAD_PREVIOUS_MESH_OPERATIONS:
    "We've encountered some problems while loading your previous mesh configuration. Please try again.",
  MESH: 'Mesh | Meshes',
  MESHING: 'Meshing',

  /**
   * Queries
   */
  SELECT_QUERY_CRITERIA_LABEL: 'Query criteria',
  SELECT_QUERY_CRITERIA_PLACEHOLDER: 'Select query criteria',
  SELECT_MESH_ELEMENTS_QUERY: 'Select mesh query',

  QUERY_CRITERIA_LABEL: 'Query',
  MESH_ELEMENTS_QUERY_CRITERIA: 'Mesh query',

  QUERY_ATTRIBUTERANGEQUERYDEFINITION2: 'Attribute',
  QUERY_SELECTALLQUERYDEFINITION2: 'Select all',
  QUERY_PERSISTEDQUERYDEFINITION: 'Saved selection',

  QUERY_VALUE: 'Query value',
  QUERY_MIN_VALUE: 'Query min value',
  QUERY_MAX_VALUE: 'Query max value',
  QUERY_APPLY_TO_TABLE: 'Apply to table',

  /**
   * Properties of meshes, geometries, variables
   */
  PROPERTY: 'Property | Properties',

  PROP_CATEGORY_MMG_ELEMENTTYPE_1: 'Vertices',
  PROP_CATEGORY_MMG_ELEMENTTYPE_2: 'Poly vertices',
  PROP_CATEGORY_MMG_ELEMENTTYPE_3: 'Lines',
  PROP_CATEGORY_MMG_ELEMENTTYPE_4: 'Poly lines',
  PROP_CATEGORY_MMG_ELEMENTTYPE_5: 'Triangles',
  PROP_CATEGORY_MMG_ELEMENTTYPE_6: 'Triangle strips',
  PROP_CATEGORY_MMG_ELEMENTTYPE_7: 'Polygons',
  PROP_CATEGORY_MMG_ELEMENTTYPE_8: 'Pixels',
  PROP_CATEGORY_MMG_ELEMENTTYPE_9: 'Quads',
  PROP_CATEGORY_MMG_ELEMENTTYPE_10: 'Tetras',
  PROP_CATEGORY_MMG_ELEMENTTYPE_11: 'Voxels',
  PROP_CATEGORY_MMG_ELEMENTTYPE_12: 'Hexahedrons',
  PROP_CATEGORY_MMG_ELEMENTTYPE_13: 'Wedges',
  PROP_CATEGORY_MMG_ELEMENTTYPE_14: 'Pyramids',

  PROP_CATEGORY_MMG_REGION: '{value}',
  PROP_CATEGORY_MMG_NODETYPE: '{value}',

  PROP_MMG_ELEMENTTYPE: 'Element types',
  PROP_MMG_NODETYPE: 'Node markers',
  PROP_MMG_AREA: 'Area [m²]',
  PROP_MMG_REGION: 'Regions',
  PROP_MMG_LENGTH: 'Length [m]',
  PROP_MMG_ASPECTRATIO: 'Aspect ratio',
  PROP_MMG_CONDITIONNUMBER: 'Condition no.',
  PROP_MMG_ELEVATION: 'Elevation [m]',
  PROP_MMG_INTERPOLATIONERROR: 'Interpolation Errors',
  PROP_MMG_SAVED_SELECTIONS: 'Selections', // todo hevo - will this be an mmg one?
  PROP_MMG_ID: 'Id',
  PROP_MMG_EPSG: 'EPSG',
  PROP_MMG_DATAVERSION: 'Data version',

  PROP_MMG_CONDITIONNUMBER_HELPTEXT:
    'Mesh quality, or Condition Number, is a measure of the deviation from an ideally shaped element (for example, an equilateral triangle) to the actual mesh element. The lower the mesh quality value, the better the quality of an element, and vice versa.',
  PROP_MMG_AREA_HELPTEXT:
    'In general, small element areas require correspondingly small time steps, thereby increasing model simulation times.',
  PROP_MMG_ASPECTRATIO_HELPTEXT:
    'The aspect ratio is the ratio of the longest edge to shortest edge (for example, an equilateral triangle has aspect ratio 1). The lower the aspect ratio value, the better the quality of an element, and vice versa.',
  PROP_MMG_ESTIMATEDTIMESTEP_HELPTEXT:
    'The estimated time step is only applicable for marine modelling applications. It is based upon a fixed sea level of 0.0m and a fixed CFL number of 0.8 (the recommended value).',

  PROP_MMG_ELEMENTCOUNT: 'Element count',
  PROP_MMG_TRIANGLECOUNT: 'Triangle count',
  PROP_MMG_QUADCOUNT: 'Quadrangle count',
  PROP_MMG_NODECOUNT: 'Node count',
  PROP_MMG_EDGECOUNT: 'Edge count',
  PROP_MMG_POINTCOUNT: 'Point count',
  PROP_MMG_POLYGONCOUNT: 'Polygon count',
  PROP_MMG_POLYLINECOUNT: 'Polyline count',
  PROP_MMG_LINESTRINGCOUNT: 'Polyline count',
  PROP_MMG_MULTIPOINTCOUNT: 'MultiPoint count',
  PROP_MMG_MULTIPOLYGONCOUNT: 'MultiPolygon count',
  PROP_MMG_MULTIPOLYLINECOUNT: 'MultiPolyline count',
  PROP_MMG_MULTILINESTRINGCOUNT: 'MultiPolyline count',
  PROP_MMG_SEGMENTCOUNT: 'Segment count',
  PROP_MMG_VERTEXCOUNT: 'Vertex count',
  PROP_MMG_BADELEMENTS: 'Bad elements',
  PROP_MMG_TOTALAREA: 'Total area [m²]',
  PROP_MMG_HOLECOUNT: 'Hole count',
  PROP_MMG_PERIMETER: 'Perimeter [m]',
  PROP_MMG_MINSEGMENTLENGTH: 'Min segment length [m]',
  PROP_MMG_MAXSEGMENTLENGTH: 'Max segment length [m]',
  PROP_MMG_NUMBER_OF_CELLS: 'Number of cells',
  'PROP_MMG_WIDTH_(DX)': 'Width (dx)', // todo hevo units?
  'PROP_MMG_HEIGHT_(DY)': 'Height (dy)', // todo hevo units?
  'PROP_MMG_ORIGIN_(X0)': 'Origin (x0)',
  'PROP_MMG_ORIGIN_(Y0)': 'Origin (y0)',
  PROP_MMG_MAX_VALUE: 'Max value',
  PROP_MMG_MIN_VALUE: 'Min value',
  PROP_MMG_NODATA_VALUE: 'No data value',
  'PROP_MMG_MIKE1FM-MINTIMESTEP': 'Minimum time step',

  PROP_CATEGORY_REGION: '{value}',
  PROP_CATEGORY_NODETYPE: '{value}',

  PROP_NODETYPE: 'Node markers',
  PROP_REGION: 'Regions',
  PROP_AREA: 'Area',
  PROP_LENGTH: 'Length',
  PROP_ASPECTRATIO: 'Aspect ratio',
  PROP_CONDITIONNUMBER: 'Condition no.',
  PROP_ELEVATION: 'Elevation [m]',
  PROP_INTERPOLATIONERROR: 'Interpolation Errors',
  PROP_ID: 'Id',
  PROP_SAVED_SELECTIONS: 'Selections',
  PROP_GEOMETRIES: 'Geometries',

  PROP_ELEMENTCOUNT: 'Element count',
  PROP_TRIANGLECOUNT: 'Triangle count',
  PROP_QUADCOUNT: 'Quadrangle count',
  PROP_NODECOUNT: 'Node count',
  PROP_EDGECOUNT: 'Edge count',
  PROP_POINTCOUNT: 'Point count',
  PROP_POLYGONCOUNT: 'Polygon count',
  PROP_POLYLINECOUNT: 'Polyline count',
  PROP_LINESTRINGCOUNT: 'Polyline count',
  PROP_MULTIPOINTCOUNT: 'MultiPoint count',
  PROP_MULTIPOLYGONCOUNT: 'MultiPolygon count',
  PROP_MULTIPOLYLINECOUNT: 'MultiPolyline count',
  PROP_MULTILINESTRINGCOUNT: 'MultiPolyline count',
  PROP_SEGMENTCOUNT: 'Segment count',
  PROP_VERTEXCOUNT: 'Vertex count',
  PROP_BADELEMENTS: 'Bad elements',
  PROP_TOTALAREA: 'Total area',
  PROP_HOLECOUNT: 'Hole count',
  PROP_PERIMETER: 'Perimeter',
  PROP_MINSEGMENTLENGTH: 'Min segment length',
  PROP_MAXSEGMENTLENGTH: 'Max segment length',
  PROP_NUMBER_OF_CELLS: 'Number of cells',
  'PROP_WIDTH_(DX)': 'Width (dx)',
  'PROP_HEIGHT_(DY)': 'Height (dy)',
  'PROP_ORIGIN_(X0)': 'Origin (x0)',
  'PROP_ORIGIN_(Y0)': 'Origin (y0)',
  PROP_MAX_VALUE: 'Max value',
  PROP_MIN_VALUE: 'Min value',
  PROP_NODATA_VALUE: 'No data value',
  'PROP_MIKE1FM-MINTIMESTEP': 'Minimum time step',

  /**
   * Units. Keys correspond to the Id of DHI units in uppercase
   */
  UNIT_METER: 'm',
  UNIT_M2: 'm²',
  UNIT_NONE: '',
  UNIT_PERCENT: '%',
  UNIT_DEGREE: '°',
  UNIT_SQUAREMETER: 'm²',

  /**
   * Analyzing meshes, geoemtries and variables
   */
  SHOW_CHART: 'Show chart',
  HIDE_CHART: 'Hide chart',
  NONE: 'None',
  APPLY_GRADIENT: 'Apply gradient',
  ANALYZE: 'Analyze',
  ANALYZE_GEOMETRY: 'Geometry analysis',
  ANALYZE_MESH: 'Mesh analysis',
  ANALYZE_VARIABLE: 'Variable analysis',
  STYLE_SETTINGS: 'Visualization settings',

  /**
   * Comments
   */
  COMMENT: 'Comment',
  NO_COMMENTS: 'No comments have been added yet',
  ADD_COMMENT: 'Add comment',
  COMMENT_SUBMITTED_SUCCESSFULLY: 'Comment submitted succesfully',
  COMMENT_SUBMIT_FAILED: 'Failed to submit comment',
  RETRY_ADD_COMMENT: 'Retry',
  CONFIRM_ADD_COMMENT: 'Add',
  COMMENT_MESSAGE_LABEL: 'Comment',
  COMMENT_ADD_CONFIRMATION_TITLE: 'Add a comment',
  COMMENT_TIP: 'Click in the viewer where you would like to add the comment.',
  COMMENTS_LOADING: 'Loading comments...',
  COMMENTS_LOADING_FAILED: 'Failed to load comments',

  /**
   * Labels
   */
  LABELS_SUBMITTED_SUCCESSFULLY: 'Labels generation submitted succesfully ',
  LABELS_SUBMITTED_ERROR: 'Labels generation submission failed',

  /**
   * Login / authentication / authorization
   */
  LOGIN_RETRY: 'Retry',
  LOGIN_AUTHENTICATING: 'Authenticating',
  LOGIN_PLEASE_WAIT: 'Please wait...',
  LOGIN_LOADING: 'Loading',
  LOGIN_AUTHENTION_FAILED: 'Authentication failed',
  LOGIN_TRY_AGAIN: 'Please try again.',
  CONTINUE_WHERE_YOU_LEFT_OFF: 'Continue where you left off',
  SESSION_EXPIRED: 'Session expired',
  LOGIN_AGAIN: 'Please log in again.',
  LOGIN_INFO: 'Log in info',
  LOGIN_TO_CONTINUE: 'Log in to continue',
  LOGGED_IN_TITLE: 'MIKE Mesh Builder',
  LOGIN_USING_MS: 'Please log in using your Microsoft account to continue.',
  PLEASE_LOG_IN: 'Please log in',
  HOME_PAGE: 'Home page',
  LOGIN_PAGE: 'Log in page',
  LOGIN_PAGE_TITLE: 'Mesh Builder',
  LOGIN_PAGE_HEADER_SECONDARY: 'MIKE Cloud',
  LOGIN_PAGE_TEXT1: 'The New Ways of Modelling!',
  LOGIN_PAGE_TEXT2:
    'Create high-quality, 2D meshes for flood, inland, coastal and marine environments using the power of the Cloud.',
  LOGIN_PAGE_LEARN_MORE: 'Learn more',
  LOGIN: 'Log in',
  LOGOUT: 'Log out',
  LOGGED_IN_AS: 'Logged in as {name}',
  NOT_FOUND: 'Page or data not found in company',
  LOADING_CONFIGURATION: 'Loading configuration',
  FAILED_TO_GET_DATASETS: 'Failed to load project datasets.',
  FAILED_TO_GET_MESH_OPERATIONS: 'Failed to get mesh operations',
  FAILED_TO_GET_STATISTICS: 'Failed to get statistics',
  FAILED_TO_LOAD_CONFIGURATION: 'Failed to get configuration',
  FAILED_TO_LOAD_CONFIGURATION_DESC:
    'Please retry or come back at a later time. The API is not responding at the moment',
  GENERIC_ERROR_TITLE: 'Oh, snap',
  GENERIC_ERROR_DESC: 'An unexpected error has occured. Please try again in a bit.',
  GENERIC_ERROR_HELP_TITLE: 'Get help',
  GENERIC_ERROR_HELP_DESC:
    'Is this problem happening often? Please clear your browser cookies and try again. If you are still experiencing problems, then please contact support with the information below.',
  MY_APPS: 'My apps',
  OFFLINE_TITLE: 'You are offline',
  OFFLINE_DESC: 'Please check your internet connection before continuing.',
  LOGGED_OUT_TITLE: 'You session has expired',
  LOGGED_OUT_DESC: 'Log in to continue',
  APPLICATION_FORBIDDEN_ERROR:
    'You are not authorized to view this information. If you think this is wrong, please contact your company administrator to get more rights.',
  APPLICATION_FORBIDDEN_TITLE: 'You are not allowed to view this.',
  APPLICATION_EXPECTATION_FAILED_ERROR: 'Something went wrong while logging you in.',
  APPLICATION_EXPECTATION_FAILED_TITLE: 'There was a problem validating your credentials',
  UNSURE_ABOUT_YOUR_SUBSCRIPTION: 'Unsure about your subscription?',
  CONTACT_US: 'Contact us.',

  /**
   * Projects, admin-related strings.
   */
  NO_PROJECTS: "You haven't created any projects yet. Go to the data administrator portal to create one.",
  LOADING_PROJECTS: 'Loading projects',
  PROJECT_IS_PRIVATE: 'Project is private',
  PROJECT_LIST_FAILED: 'Failed to get projects',
  CREATE_NEW_PROJECT: 'Create new folder',
  PROJECT_NAME_LABEL: 'Name',
  PROJECT_DESCRIPTION_LABEL: 'Description',
  PROJECT_CREATE_BUTTON: 'Create project',
  PROJECT_CREATE_TITLE: 'Create new folder',
  PROJECT_CREATE_CONTINUE_BUTTON: 'Create & continue',

  /**
   * Exports
   */
  EXPORT_PANEL: 'Export data',
  MESSAGE_EXPORT_NOT_ALLOWED: 'You are not allowed to export data.',
  MESSAGE_EXPORT_NOT_ALLOWED_DETAILS:
    'You do not have the permissions needed to write data to this project and therefore cannot export. If you think this is a mistake, please contact your administrator.',

  EXPORT_SUCCESSFUL: 'Export successful',
  EXPORT_FAILED: 'Export failed',
  EXPORT_FORMAT_0: 'Export as .vtu', // deprecated. replaced by EXPORT_MESH_FORMAT_0
  EXPORT_FORMAT_1: 'Export as .mesh', // deprecated. replaced by EXPORT_MESH_FORMAT_1
  MESSAGE_EXPORT_MESH_NOT_ALLOWED: 'You are not allowed to export meshes.', // deprecated
  MESSAGE_EXPORT_MESH_NOT_ALLOWED_DETAILS:
    'You do not have the permissions needed to write data to this project and therefore cannot export meshes. If you think this is a mistake, please contact your administrator.', //deprecated

  EXPORT_SELECT_ITEM_TYPE: 'Select type to export',

  NO_MESHES_TO_EXPORT: 'There are no meshes to export yet.',
  EXPORT_MESHES_TIP: 'Only generated meshes can be exported.',
  EXPORT_MESH_FORMAT_LABEL: 'Export as',
  EXPORT_MESH_FORMAT_0: '.vtu file',
  EXPORT_MESH_FORMAT_1: '.mesh file',
  EXPORT_MESH_FORMAT_3: '.DFSU file',
  MESH_EXPORT: 'Export mesh | Export meshes',
  EXPORT_MESHES_SUCCESSFUL: 'Export meshes succeeded.',
  EXPORT_MESHES_FAILED: 'Export meshes failed',
  EXPORT_MESHES_SCHEDULED: 'Export meshes scheduled',
  EXPORT_MESHES_PROCESSING: 'Export meshes processing',

  NO_GEOMETRIES_TO_EXPORT: 'There are no geometries to export yet.',
  EXPORT_GEOMETRIES_TIP: '',
  GEOMETRY_EXPORT: 'Export geometry | Export geometries',
  EXPORT_GEOMETRIES_SUCCESSFUL: 'Export geometries succeeded.',
  EXPORT_GEOMETRIES_FAILED: 'Export geometries failed',
  EXPORT_GEOMETRIES_SCHEDULED: 'Export geometries scheduled',
  EXPORT_GEOMETRIES_PROCESSING: 'Export geometries processing',

  NO_VARIABLES_TO_EXPORT: 'There are no variables to export yet.',
  EXPORT_VARIABLES_TIP: 'Only scatter data can be exported',
  VARIABLE_EXPORT: 'Export variable | Export variables',
  EXPORT_VARIABLES_SUCCESSFUL: 'Export variables succeeded.',
  EXPORT_VARIABLES_FAILED: 'Export variables failed',
  EXPORT_VARIABLES_SCHEDULED: 'Export variables scheduled',
  EXPORT_VARIABLES_PROCESSING: 'Export variables processing',

  /**
   * Pages
   */
  PROJECT_WORKSPACES: 'Project workspaces',
  PROJECTS_PAGE_HEADER: 'Projects and recent workspaces',
  PROJECTS_PAGE_TITLE: 'Projects',
  PROJECT_LIST: 'Projects',
  WORKSPACES_PAGE_HEADER: '{projectName} - Workspaces',
  WORKSPACES_PAGE_TITLE: '{projectName} ',

  /**
   * Projects Table
   */

  PROJECTS_TABLE_COLUMN_NAME_LABEL: 'Folder name',
  PROJECTS_TABLE_COLUMN_DESCRIPTION_LABEL: 'Description',
  PROJECTS_TABLE_COLUMN_CREATED_AT_LABEL: 'Created',
  PROJECTS_TABLE_COLUMN_UPDATED_AT_LABEL: 'Updated',

  /**
   * Projects page filter and subtitle
   */
  PROJECTS_FILTER_PLACEHOLDER: 'What are you looking for?',
  HOME_PAGE_SUBTITLE: 'Folders and recent workspaces',

  /**
   * Project access rights
   */
  PROJECTS_READONLY_ACCESS:
    'You have only view rights on this project. Get in contact with the project owner to get more rights.',
  PROJECTS_NO_ACCESS:
    'You are not allowed to view this project. Get in contact with the project owner to get more rights.',

  /**
   * Support
   */
  SHOW_ERROR_DETAILS: 'Details',
  GET_HELP: 'Contact support',
  GET_HELP_DESC:
    'Please clear your browser cookies, refresh the page and try again. If you are still experiencing problems, then please contact support.',
  SUPPORT: 'Support',
  SP_MANUAL: 'Support manual',
  'SP_STATUS-DASHBOARD': 'MIKE Status Dashboard',
  SP_EXAMPLES: 'Download example data',
  SP_SUBJECT: 'MIKE Cloud: Mesh Builder',
  SP_BODY_TEXT:
    'Please provide all details relevant to your inquiry. If you need technical support, or are reporting a problem with Mesh Builder, please include the following information to enable us to investigate your issue in a timely fashion:',
  SP_BULLETED_LIST:
    '1) A link to your Mesh Builder workspace \r\n     2) If you would prefer not to provide access to your workspace, please attach any datasets relevant to the issue \r\n     3) A full description of the actions that caused the issue \r\n     4) Screenshots of the issue',
  SP_BODY_TIP: 'Please do not remove information below this line',
  SP_TRAINING: 'Mesh Builder Training Course',
  SP_YOUTUBE: 'Mesh Builder YouTube Channel',
  SUPPORT_PLACEHOLDER: 'Type a message',
  SUPPORT_SUBJECT: 'Subject',
  SUPPORT_SUBMIT: 'Send',
  SUPPORT_SELECT_APPLICATION: 'Select an application',
  SUPPORT_MESSAGE_SENT: 'Support message sent',
  FAILED_TO_SEND_SUPPORT_MESSAGE: 'Failed to send support message',
  ERROR_CODE: 'Error code',

  /**
   * Keyboard shortcuts
   */
  SCROLL: 'SCROLL',
  SHIFT: 'SHIFT',
  CTRL: 'CTRL',
  ALT: 'ALT',
  KEY_BACKSPACE: 'BACKSPACE',
  KEY_ESC: 'ESC',
  KEY_DELETE: 'DELETE',
  KEY_SPACE: 'SPACE',
  S: 'S',
  W: 'W',
  P: 'P',
  E: 'E',
  R: 'R',
  KEYBOARD_SHORTCUTS: 'Keyboard shortcuts',
  KEY_MOUSE_LEFT_PRESS_3D: 'Orbit',
  KEY_MOUSE_LEFT_PRESS_2D: 'Pan',
  KEY_MOUSE_DOUBLE_LEFT_PRESS_DRAW: 'Double click to complete current polygon/polyline',
  KEY_MOUSE_SHIFT_LEFT_PRESS_DRAW: 'Pan while drawing',
  KEY_MOUSE_LEFT_PRESS_DRAW: 'Click to add a point',
  KEY_BACKSPACE_DRAW: 'Delete last point from current polygon/polyline',
  KEY_SPACE_DRAW: 'Press SPACE to complete current polygon/polyline',
  KEY_ESC_DRAW: 'Cancel current polygon/polyline',
  KEY_DELETE_DRAW: 'Delete selected shapes',
  KEY_DELETE_DRAW_POINT: 'Delete selected points from just added points',
  KEY_MOUSE_CTRL_LEFT_CLICK_DRAW: 'Single selection. Selects the shape and deselects all other shapes',
  KEY_MOUSE_SHIFT_LEFT_CLICK_DRAW: 'Multiselection. Adds to or removes from already selected shapes',
  KEY_MOUSE_CTRL_LEFT_CLICK_DRAW_POINT: 'Single selection. Selects the points and deselects all other points',
  KEY_MOUSE_SHIFT_LEFT_CLICK_DRAW_POINT: 'Multiselection. Adds to or removes from already selected points',
  KEY_MOUSE_ALT_LEFT_CLICK_DRAW: 'Delete vertex from selected polygon/polyline',
  KEY_MOUSE_ALT_SHIFT_DRAG_DRAW: 'Select features (polygons or polyline arcs) by cliping the selected area',
  KEY_MOUSE_LEFT_PRESS_MODIFY_DRAWING:
    'Click on a polygon/polyline to insert a vertex. Click on vertex to select a polygon/polyline.',
  KEY_MOUSE_LEFT_PRESS_CTRL_PRESS_MODIFY_DRAWING: 'Drag to move vertex.',
  KEY_MOUSE_LEFT_PRESS_MODIFY_DRAWING_POINT:
    'Click to add a point or select an already added point. Drag to move already added point',
  KEY_MOUSE_LEFT_PRESS_MODIFY_DRAWING_POINT_SINGLE: 'Click to add or replace a point',
  KEY_SCROLL: 'Zoom in / out',
  KEY_SHIFT_PRESS: 'Pan',
  KEY_CTRL_PRESS: 'Rotate around center',
  KEY_ALT_PRESS: 'Rotate around bottom',
  KEY_ALT_SHIFT_S_PRESS: 'Display all items as surface',
  KEY_ALT_SHIFT_W_PRESS: 'Display all items as wireframe',
  KEY_ALT_SHIFT_P_PRESS: 'Display all items as points',
  KEY_ALT_SHIFT_E_PRESS: 'Display all items as surface + wireframe',
  KEY_ALT_SHIFT_R_PRESS: 'Reset camera position',
  KEY_SHIFT_MOUSE_LEFT_PRESS_2D: 'Pan while drawing',
  KEY_SECTION_3DMOVEMENT: 'Movement in 3D',
  KEY_SECTION_2DMOVEMENT: 'Movement in 2D',
  KEY_SECTION_2DDRAW: 'Drawing in 2D',
  KEY_SECTION_2DMODIFY: 'Drawing in 2D - Modify shapes not yet submitted',
  KEY_SECTION_VISUALIZATION: 'Visualization (2D and 3D)',

  /**
   * Tool descriptions
   */
  TOOL_RESET_VIEW: 'Reset view',
  TOOL_BUILDINGS_3D: 'Load 3D buildings inside the bounds of the workspace',
  TOOL_TERRAIN_3D: 'Choose 3D terrain',
  TOOL_TERRAIN_3D_EXAGGERATION: 'Choose exaggeration of 3D features',
  TOOL_DRAW_GEOMETRY: 'Draw geometry',
  TOOL_DRAW_POLYGON: 'Draw polygon',
  TOOL_DRAW_LINESTRING: 'Draw polyline',
  TOOL_DRAW_POINT: 'Draw point',
  TOOL_DRAW_CIRCLE: 'Draw circle',
  TOOL_DRAW_RECTANGLE: 'Draw rectangle',
  TOOL_DRAW_VARIABLE: 'Draw variable',
  TOOL_EDIT_VARIABLE_PROPERTIES: 'Edit variable values',
  TOOL_EXTRACT_FEATURES: 'Extract features from open data sources',
  TOOL_EXTRACT_FEATURES_SHORT: 'Extract features',
  TOOL_SPATIAL_SELECTION: 'Make a spatial selection',
  TOOL_FEATURE_INFO: 'Inspect data properties',
  TOOL_BOX_SELECTION: 'Select multiple features with a box',
  TOOL_ZOOM_IN: 'Zoom in',
  TOOL_ZOOM_OUT: 'Zoom out',
  TOOL_COMMENT_ADD: 'Add a comment',
  TOOL_MEASUREMENTS: 'Measure line or area',
  TOOL_MEASURE_LINE: 'Measure line',
  TOOL_MEASURE_AREA: 'Measure area',
  TOOL_3D_WIDGET_ZPLUS: 'top',
  TOOL_3D_WIDGET_ZMINUS: 'bottom',

  /**
   * Map-related text
   */
  'BASEMAP_MAPBOX-SATELLITE': 'Mapbox Satellite',
  'BASEMAP_MAPBOX-STREET': 'Mapbox Vector',
  'BASEMAP_MAPTILER-SATELLITE': 'Satellite',
  'BASEMAP_MAPTILER-STREET': 'Vector',
  BASEMAP_NONE: 'No Basemap',
  BASEMAP_PROJECTION_NOT_SUPPORTED:
    'Base maps are not supported for this projection. This might happen because the data is in a geographical projection (degrees). At the moment, only projected coordinate systems are supported (meters/feet).',
  BASEMAP_PROJECTION_DEF_FAILED:
    'Failed to fetch base map projection definition. Please refresh the page in order to retry.',
  BASEMAP_REFRESH: 'Refresh basemap',

  /**
   * Maps
   */
  MAP_TYPE_SHORT_SATELLITE: 'Sat',
  MAP_TYPE_SHORT_STREETS: 'Str',
  MAP_TYPE_SHORT_TERRAIN: 'Con',
  MAP_TYPE_SHORT_ELEVATION: 'Elv',
  MAP_TYPE_NONE: 'None',
  MAP_TYPE_SATELLITE: 'Satellite',
  MAP_TYPE_STREETS: 'Streets',
  MAP_TYPE_TERRAIN: 'Contour',
  MAP_TYPE_ELEVATION: 'Elevation',

  /**
   * Drawing, editing, extracting, selections
   */
  EXTRACTION_OPERATION_SUBMITTED_SUCCESSFULLY: 'Extraction submitted. Data will load shortly.',
  SELECT_GEOMETRIES_TO_EXTRACT_PANEL: 'Extract geometries',
  POLYGON_DRAWING_SUBMITTED_SUCCESSFULLY:
    'Polygon geometry submitted succesfully. It will be available for meshing shortly.',
  POLYGON_DRAWING_SUBMIT_FAILED: 'Failed to create polygon geometry.',
  LINESTRING_DRAWING_SUBMITTED_SUCCESSFULLY:
    'Polyline geometry submitted succesfully. It will be available for meshing shortly.',
  LINESTRING_DRAWING_SUBMIT_FAILED: 'Failed to create polyline geometry.',
  POINT_DRAWING_SUBMITTED_SUCCESSFULLY:
    'Point geometry submitted succesfully. It will be available for meshing shortly.',
  POINT_DRAWING_SUBMIT_FAILED: 'Failed to create point geometry.',
  GEOMETRY_DRAWING_SUBMITTED_SUCCESSFULLY:
    'Geometry submitted succesfully. It will be available for meshing shortly. | Geometries submitted succesfully. They will be available for meshing shortly.',
  GEOMETRY_DRAWING_SUBMIT_FAILED: 'Failed to submit geometry. | Failed to submit geometries.',
  VARIABLE_DRAWING_SUBMITTED_SUCCESSFULLY:
    'Variable submitted succesfully. It will be available shortly. | Variables submitted succesfully. They will be available shortly.',
  VARIABLE_DRAWING_SUBMIT_FAILED: 'Failed to submit variable. | Failed to submit variables.',
  GEOMETRY_USE_FOR_EXTRACTION_SUBMITED: 'Geometry submitted for extraction. It should be available shortly.',
  GEOMETRY_DRAW_TOOLTIP: 'Click on the map where you want to extract data or select an existing polygon below:',
  GEOMETRY_SELECTION_DRAW_TOOLTIP:
    'Click on the map where you want to draw a polgon or select one of the existing polygons below:',
  DEFINE_AREA_FOR_EXTRACTION: 'Define area for extraction',
  DEFINE_DATA_SOURCE_TO_EXTRACT: 'Define data source to extract',
  GEOMETRY_USE_FOR_EXTRACTION_SUBMIT_FAILED: 'Failed to use geometry for extraction. Please try again.',
  EXTRACT_SUBMITTED: 'Area submitted for extraction. It might take a while based on the size of your selection.',
  SELECTION_SUBMITTED: 'Selection submitted. Data will load shortly.',
  USE_GEOMETRIES: 'Use geometries',
  FEATURE_EXTRACT: 'Extracted geometry',
  FEATURE_EXTRACT_DRAWING_SUBMIT_FAILED:
    'Failed to retrieve open data for your selection. Open data servers might be busy or applied server settings might not be sufficient. Please retry with other settings or at a different time.',
  CONFIRM_DRAWING: 'Create',
  CONFIRM_EDIT_PROPERTIES: 'Done',
  CONFIRM_EXTRACTION: 'Extract',
  CONFIRM_EXTRACT_DRAWING: 'Create',
  CANCEL_EXTRACTION: 'Cancel extraction',
  RETRY_EXTRACTION: 'Retry extract',
  FEATURES_DRAWN: 'item drawn | items drawn',
  POLYGON_DRAWN: 'polygon drawn | polygons drawn',
  POLYLINE_DRAWN: 'polyline drawn | polylines drawn',
  POINT_DRAWN: 'point drawn | points drawn',
  DRAW_CONFIRMATION_TITLE: 'Draw geometry',
  DRAW_SELECTION: 'Draw a selection',
  DRAW_OR_SELECT_POLYGON: 'Draw or select a polygon',
  GEOMETRY_EDIT_CONFIRMATION_TITLE: 'Edit geometry',
  CONFIRM_EDITING_GEOMETRY: 'Save geometry',
  GEOMETRY_EDIT_LOADING: 'Getting geometry data...',
  GEOMETRY_EDIT_UPDATE_SUBMITTED: 'Geometry update submitted',
  GEOMETRY_EDIT_LOADING_FAILED: 'Failed to get geometry data',
  MESH_EDIT_LOADING_FAILED: 'Failed to get mesh data',
  MESH_EDIT_LOADING: 'Getting mesh data...',
  GEOMETRY_EDIT_UPDATE_FAILED: 'Failed to update geometry',
  EXTRACTION_TIP: 'Start drawing polygons in the area where you would like to extract data.',
  DRAW_TIP: 'You can draw polygons, polylines and points. Once you start drawing, you will see a summary here.',
  OPERATION_CFL_APPLY: ' Apply CFL operation',
  MODIFY_POLYGON_VERTICES: 'Use the cursor and keyboard shortcuts to modify vertices of selected polygons',
  MODIFY_POLYLINE_VERTICES: 'Use the cursor and keyboard shortcuts to modify vertices of selected lines',

  VARIABLE_DRAW_CONFIRMATION_TITLE: 'Draw variable',
  VARIABLE_EDIT_CONFIRMATION_TITLE: 'Edit variable',
  VARIABLE_PROPERTY_EDIT_CONFIRMATION_TITLE: 'Edit variable values',
  VARIABLE_DRAW_EDIT_PROPERTIES_SUBTITLE: 'Values to apply',
  VARIABLE_DRAW_PROPERTY_LABEL: '{property} value',
  VARIABLE_EDIT_PROPERTY_LABEL: '{property} value of selection',
  VARIABLE_EDIT_LOADING: 'Getting variable data...',
  VARIABLE_EDIT_UPDATE_SUBMITTED: 'Variable update submitted',
  VARIABLE_EDIT_LOADING_FAILED: 'Failed to get variable data.',
  VARIABLE_EDIT_UPDATE_FAILED: 'Failed to update variable',
  CONFIRM_EDITING_VARIABLE: 'Submit',
  CONFIRM_CREAING_VARIABLE: 'Create',
  VARIABLE_DRAW_TIP: 'Start drawing point(s) on the map to create a variable.',
  VARIABLE_PROPERTY_EDIT_TIP: 'Select variable point(s) to view and edit their values.',
  VARIABLE_DRAW_PROPERTY_VALUE_TIP:
    'Values only affect new points. Previous values can be changed at any time by using the edit tool. Previous points can also be selected and deleted while you draw.',

  GEOMETRY_DOWNLOAD_DATA: 'Download data',
  GEOMETRY_DOWNLOAD_DATA_SUBMITTED: 'Your data will begin downloading shortly',
  GEOMETRY_DOWNLOAD_DATA_FAILED: 'Failed to download data. Please try again.',

  MESH_EDIT_NODES_CONFIRMATION_TITLE: 'Edit mesh nodes',
  CONFIRM_EDITING_MESH_NODE: 'Submit',
  MESH_NODE_SAVE_FAILED: 'Failed to save mesh node edits.',
  MESH_NODE_LOADING: 'Loading mesh node...',
  MESH_NODE_LOADING_FAILED: 'Failed to load mesh node.',
  MESH_NODE_SELECT_TIP: 'Double click a mesh node to start editing.',
  MESH_NODE_EDIT_TIP:
    'Move the node freely and press either next node to edit another mesh node or submit to save when done.',
  MESH_EDIT_NODES_SUBMITTED: 'Updating mesh. Changes will be visible in a moment',
  MESH_NODE_EDIT_NEXT: 'Next node',
  MESH_NODE_EDIT_UNDO: 'Undo',

  EXTRACT_CONFIRMATION_TITLE: 'Extract geometry',
  EXTRACT_DRAW_CONFIRMATION_TITLE: 'Create geometry from extracted data',
  EXTRACT_NO_DATA: 'There is no data to extract',
  OPEN_DATA_PROVIDER: 'Open data provider',
  DATAPROVIDER_FOR_EXTRACTION: 'Data provider',
  DATASOURCE_TO_EXTRACT: 'Data',
  FEATURE_PROPERTY_TITLE: 'Selection values',
  FEATURE_NO_PROPERTIES: 'The selected item has no values',
  DATAPROVIDER_SETIINGS: 'Data provider settings',
  DATAPROVIDER_OSM_SETIINGS_INFO:
    'To enable time and / or memory consuming extractions, the OSM server settings can be adapted. Please keep your browser open to not abort the extraction. Be aware that especially during peak hours the server may reject extractions with adapted settings.',
  DATAPROVIDER_OSM_SETIINGS_ALLOWED_RUNTIME: 'Maximum allowed runtime',
  DATAPROVIDER_OSM_SETIINGS_ALLOWED_RUNTIME_INFO:
    'If the query runs longer than this time, the OSM server may abort the query with a timeout. The second effect is, the higher this value, the more probably the OSM server rejects the query before executing it. Please keep the browser open to not abort the extraction yourself.',
  DATAPROVIDER_OSM_SETIINGS_ALLOWED_MEMORY: 'Maximum allowed memory',
  DATAPROVIDER_OSM_SETIINGS_ALLOWED_MEMORY_INFO:
    'If the query needs more RAM than this value, the OSM server may abort the query with a memory exhaustion. The second effect is, the higher this value, the more probably the OSM server rejects the query before executing it. The maximum value highly depends on the current server load, e.g. requests for 2GB will likely be rejected during peak hours, as they do not fit into the overall resource management.',

  SPATIAL_SELECTION_CONFIRMATION_TITLE: 'Make a spatial selection',
  SPATIAL_SELECTION_EXPLANATION:
    'The extent of a spatial selection will be derived using the intersection of the digitised polygon.',
  GEOMETRY_SPATIAL_SELECTION_SUBMITTED_SUCCESSFULLY: 'Geometry spatial selection submitted succesfully.',
  GEOMETRY_SPATIAL_SELECTION_SUBMIT_FAILED: 'Failed to create geometry spatial selection',
  MESH_SPATIAL_SELECTION_SUBMITTED_SUCCESSFULLY: 'Mesh spatial selection submitted succesfully.',
  MESH_SPATIAL_SELECTION_SUBMIT_FAILED: 'Failed to create mesh spatial selection',
  RETRY_SPATIAL_SELECTION: 'Retry',
  CONFIRM_SPATIAL_SELECTION: 'Create',
  SHOW_SELECTION_RESULT: 'Show result',
  HIDE_SELECTION_RESULT: 'Hide result',
  SPATIAL_SELECTION_NAME: 'Selection name',

  FAILED_LOAD_DATA: 'Loading failed',

  SELECTION_RESULT_SUBMITTED: 'Computing selection result. It will be visible in a moment',
  SELECTION_RESULT_SUBMIT_FAILED: 'Failed to retrieve selection result',
  SELECTION_DELETE_FAILED: 'Failed to delete selection',
  SELECTION_DELETED_SUCCESSFULLY: 'Selection deleted succesfully',
  SELECTION_UPDATE_FAILED: 'Failed to update selection',
  SELECTION_UPDATED_SUCCESSFULLY: 'Selection updated succesfully',
  SELECTION_EMPTY_LIST: 'No selection created yet.',

  ZOOM_TO_LAYER: 'Zoom to extent',
  PROPERTIES: 'Properties',

  /**
   * Feature toggle texts / beta notices#
   */
  '3D_MAPS_BETA_NOTICE':
    "3D background maps are a Beta feature and we're still working on it. We're planning to increase their quality and allow using map elevation data to support meshing.",

  /**
   * Custom validation messages. For now, in general we rely on default yup validation messages (in english).
   */
  ERROR_MSG_REQUIRED: 'Required',

  /**
   * Misc
   */
  STATISTICS_ATTRIBUTE_NAME_HEADER: 'Name',
  STATISTICS_LABELS_HEADER: 'Labels',
  STATISTICS_ATTRIBUTE_COLOR_HEADER: 'Colour',
  STATISTICS_ATTRIBUTE_3D_HEADER: '3D',
  MUST_BE_NUMBER: 'Must be a number.',
};
