/** @jsxImportSource @emotion/react */
import { useCallback, useMemo } from 'react';
import { MmgConnectedLayer } from '../../shared/layers/mesh-layer';
import { MmgConnectedLayerGeometryActions } from '../../geometries/list/actions/layer-geometry-actions';
import { MikeConnectedLayerOpenActionButton } from '../../shared/layers/actions/layer-open-action-button';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import GeometryIconUtils from '../../geometries/geometry-icon-utils';
import { EElementCategories, ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import LayerUtils from '../../shared/layers/layer-utils';
import { MmgLayerError } from '../../shared/layers/mesh-layer-error';
import { IProject } from '../../models/IProject';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '../../store/reducers';
import { MmgConnectedSpatialSelectionListItems } from '../../queries/spatial-selections/statial-selection-list-items';
import WorkspaceGeometrySelectors from '../../store/selectors/WorkspaceGeometrySelectors';
import { IWorkspaceQuery } from '../../models/IQueries';
import { EWorkspaceQueryActionType } from '../../store/actions/WorkspaceQueryActionType';
import { MikeLayerColorComplication } from '../../shared-components/mike-layer/complications/layer-color-complications';
import { MikeLayerWorking } from '../../shared-components/mike-layer/layer-working';
import { LayerWithoutBorderStyle } from '../../shared-components/mike-layer/layer-styles';

const { getIcon } = GeometryIconUtils;
const { isLayerWorking, isLayerFailed, isLayerHidden, getLayerSurfaceColor, getLayerEdgeColor } = LayerUtils;

type GeometryListItemProps = {
  geometry: IWorkspaceEnrichedGeometry;
  hiddenGeometries: Array<string>;
  drawnGeometries: Array<IDrawnDataItem>;
  onGeometryEdit: (geometryId: string) => void;
  onGeometryOpenPressed: (geometryId: string) => void;
};

/**
 * @name MmgConnectedGeometryListItem
 * @summary An item in a geometry list.
 *
 * @param props
 */
export function MmgConnectedGeometryListItem(props: GeometryListItemProps) {
  const dispatch = useDispatch();
  const { geometry, drawnGeometries, hiddenGeometries, onGeometryOpenPressed } = props;
  const { id, name, message } = geometry;
  const getGeometryQueriesSelectorInstance = WorkspaceGeometrySelectors.makeGetGeometryQueries();

  const geometryQueries: Array<IWorkspaceQuery> = useSelector((state: IGlobalState) =>
    getGeometryQueriesSelectorInstance(state, { geometryId: id }),
  );

/*   const highlightedWorkspaceElementId = useSelector(
    (state: IGlobalState) => state.WorkspaceReducer.highlightedWorkspaceElementId,
  ); */

  const isWorking = isLayerWorking(geometry, drawnGeometries);
  const project: IProject | null = useSelector((state: IGlobalState) => state.ProjectReducer.project);
  
  const canUpdateWorkspace = useMemo(
    () => {  
      return project && project.capabilities && project.capabilities.canUpdateContent;      
    },
    [project],
  );

  const drawnData = useMemo(
    () => {
      if (drawnGeometries && id) {
        return drawnGeometries.find((d: IDrawnDataItem) => d.id === id);
      } else {
        return undefined;
      }
    },
    [drawnGeometries, id],
  );



/*   const handleLayerMouseEnter = (layerId: string) => {
    highlightLayer(layerId);
  }; */

  const handleVisibiltyChanged = useCallback(
    (show: boolean) => {
      if (geometryQueries && geometryQueries.length > 0) {
        const ids = geometryQueries.map((query: IWorkspaceQuery) => query.id);
        if (ids.length > 0) {
          if (show) {
            dispatch({ type: EWorkspaceQueryActionType.SELECTIONS_RESHOW_DUE_TO_REVISIBLE_PARENT, ids });
          } else {
            dispatch({ type: EWorkspaceQueryActionType.SELECTIONS_HIDE_DUE_TO_INVISIBLE_PARENT, ids });
          }
        }
      }
    },
    [dispatch, geometryQueries],
  );

  const DefaultLayer = () => {
    return (
      <MmgConnectedLayer
        layerId={id}
        {...baseLayerProperties}
        leftIcon={getIcon(geometry)}
        leftComplications={
          <MikeLayerColorComplication
            edgeColor={getLayerSurfaceColor(geometry, drawnGeometries)}
            surfaceColor={getLayerEdgeColor(geometry, drawnGeometries)}
          />
        }
        showTooltip={true}
        type={EElementCategories.GEOMETRY}
        css={geometryQueries && geometryQueries.length > 0 && LayerWithoutBorderStyle}
        // onLayerMouseEnter={handleLayerMouseEnter}
        // onLayerMouseLeave={handleLayerMouseLeave}
        onVisibiltyChanged={handleVisibiltyChanged}
        showVisibilityIcon={true}
        /* hovering={highlightedWorkspaceElementId === id} */
      />
    );
  };

  const baseLayerProperties = useMemo(() => {
      /**
   * Gets common right actions for geometry layers.
   *
   */
  const getCommonRightActions = () => {
    return (
      <>
        {canUpdateWorkspace && (
          <MmgConnectedLayerGeometryActions
            geometryDrawnData={drawnData}
            layerId={id}
            geometry={geometry}
            updateUserSettingsOnToggle={true}
            onGeometryEdit={props.onGeometryEdit}
          />
        )}
        <MikeConnectedLayerOpenActionButton layerId={id} layerCategory={ELEMENT_CATEGORIES.GEOMETRY} />
      </>
    );
  };
    return {
      layerId: id,
      layerName: name,
      rightActions: getCommonRightActions(),
      // isLayerFaded: hiddenGeometries && hiddenGeometries.includes(geometry.id), //(ELEMENT_CATEGORIES.GEOMETRY, geometry.id, hiddenGeometries),
      onLayerNamePressed: onGeometryOpenPressed,
    };

  }, [canUpdateWorkspace, drawnData, geometry, id, name, onGeometryOpenPressed, props.onGeometryEdit])

/*   const baseLayerProperties =  {
    layerId: id,
    layerName: name,
    rightActions: getCommonRightActions(),
    isLayerFaded: hiddenGeometries && hiddenGeometries.includes(geometry.id), //(ELEMENT_CATEGORIES.GEOMETRY, geometry.id, hiddenGeometries),
    onLayerNamePressed: onGeometryOpenPressed,
  };
 */
  if (isLayerFailed(geometry)) {
    return <MmgLayerError {...baseLayerProperties} layerDescription={message} />;
  }

  if (isWorking && isLayerHidden(ELEMENT_CATEGORIES.GEOMETRY, geometry.id, hiddenGeometries)) {
    return <DefaultLayer />;
  }

  if (isWorking) {
    return <MikeLayerWorking {...baseLayerProperties} />;
  }

  const isParentHidden = hiddenGeometries.includes(geometry.id);

  return (
    <>
      <DefaultLayer />
      <MmgConnectedSpatialSelectionListItems geometryId={geometry.id} isParentHidden={isParentHidden} />
    </>
  );
}
