/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { t } from '../../translations/i18n';

import { MmgGroup } from '../../shared/groups/group';

import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';

import { MmgWorkspaceProjectionSelector } from '../../workspaces/create/workspace-projection-selector';

import { IProjection } from '../../models/IProjections';

import { IWorkspaceDataset } from '../../models/IWorkspaces';
import MmgProjectContent from '../../project-datasets/project-content';
import { DATASET_TYPES, DEFAULT_USAGE_TYPE, IUsageType } from '../../project-datasets/mesh-project-dataset-constants';
import { EDATASETUSAGE_TYPE, IGetDataset } from '../../models/IGetDataset';
import MmgProjectContentExplorer from '../../project-datasets/project-content-explorer';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';

const WorkspaceImportDatasetTitleStyle = css`
  width: 100%;
  display: flex;
  justify-content: space-between; 
  padding-left: ${mikeSharedTheme.spacing(2)};
  text-transform: none;
`;

const separatorStyle = css`
  padding-left: ${mikeSharedTheme.spacing(1)};
`;

const WorkspaceCreateDatasetContainerStyle = css`
  position: relative;
`;

type IMmgWorkspaceCreateDataSetType = {
  shouldHideConfiguration: boolean;
  selectedDatasetsHaveUnknown: boolean;
  selectedProjectId: string;
  epsgCode: number;
  onProjectionSelected: (epsgNumber: number) => void;
  isCreateAllowed: boolean;
  workspaceCreateWorking: boolean;
  onWorkspaceCreate: () => void;
  loadingProjectionSystems: boolean;
  searchProjectionSystemsById: boolean;
  selectedProjectionSystems: IProjection[];
  onDatasetTypesUpdated: (datasets: Array<IWorkspaceDataset>) => void;
};

/**
 * Component to select and configure usage type of datasets for importing into new workspaces.
 *
 * @param props
 */
const MmgWorkspaceCreateDataSetType = (props: IMmgWorkspaceCreateDataSetType) => {
  const {
    shouldHideConfiguration,
    selectedDatasetsHaveUnknown,
    selectedProjectId,
    epsgCode,
    onProjectionSelected,
    loadingProjectionSystems,
    searchProjectionSystemsById,
    selectedProjectionSystems,
    onDatasetTypesUpdated,
  } = props;

  const [selectedDatasetUsageTypes, setSelectedDatasetUsageTypes] = useState(Array<IUsageType>());

  /**
   * Callback for when a dataset is added via project explorer.
   *
   * @param selectedItems
   */
  const onAddDatasetsToBeImported = (selectedItems: Array<IGetDataset>) => {
    const newUsageTypes: IUsageType[] = selectedItems.map((item: IGetDataset) => {
      // set type if it can be inferred from the datasetType
      let newUsageType = DEFAULT_USAGE_TYPE;
      if (item.usageTypes.length === 1) {
        newUsageType = item.usageTypes[0];
      } else {
        if (item.datasetType === DATASET_TYPES.GISVECTORDATA) {
          newUsageType = EDATASETUSAGE_TYPE.Geometry;
        } else if (item.datasetType === DATASET_TYPES.MESHDATA || item.datasetType === DATASET_TYPES.THREEDMESH) {
          newUsageType = EDATASETUSAGE_TYPE.Mesh;
        }
      }

      return {
        datasetId: item.id,
        usageType: newUsageType,
        name: item.name,
        spatialInformation: item.spatialInformation ? item.spatialInformation : {},
        projectId: item.projectId,
        datasetUsageTypes: item.usageTypes,
      };
    });
    const newSelectedUsageTypes = selectedDatasetUsageTypes.concat(newUsageTypes);
    onDatasetTypesUpdated(
      newSelectedUsageTypes.map((item: IUsageType) => {
        return { ...item, id: item.datasetId, projectId: item.projectId };
      }),
    );
    setSelectedDatasetUsageTypes(newSelectedUsageTypes);
  };

  /**
   * Callback for when a dataset type is updated or a dataset is deleted.
   *
   * @param newUsageTypes
   */
  const onDatasetsToBeImportedChanged = (newUsageTypes: Array<IUsageType>) => {
    setSelectedDatasetUsageTypes(newUsageTypes);
    const newWorkspaceDatasets: Array<IWorkspaceDataset> = newUsageTypes.map((usageType: IUsageType) => {
      return {
        id: usageType.datasetId,
        projectId: usageType.projectId, // todo sku: ask Kai if projectId is still needed as Get dataset request does not require a projectId
        usageType: usageType.usageType,
        spatialInformation: usageType.spatialInformation,
      };
    });
    onDatasetTypesUpdated(newWorkspaceDatasets);
  };

  return (
    <div hidden={shouldHideConfiguration} css={WorkspaceCreateDatasetContainerStyle}>
      <MmgProjectContentExplorer onSelectedDatasetsChanged={onAddDatasetsToBeImported} />
      {selectedDatasetUsageTypes &&
        selectedDatasetUsageTypes.length > 0 && (
          <MmgGroup
            groupName={
              <span css={WorkspaceImportDatasetTitleStyle}>
                <span>{t('WORKSPACE_CREATE_IMPORT_FILES')}</span>
                <span css={separatorStyle}>{t('WORKSPACE_CREATE_FILE_TYPES')}</span>
              </span>
            }
            canBeHidden={false}
          >
            <MmgPanelSubsection>
              <MmgProjectContent
                selectedDatasetUsageTypesChanged={onDatasetsToBeImportedChanged}
                selectedDatasetUsageTypes={selectedDatasetUsageTypes}
              />
            </MmgPanelSubsection>
          </MmgGroup>
        )}

      <MmgGroup groupName={t('WORKSPACE_PROJECTION')} canBeHidden={false}>
        <MmgPanelSubsection>
          {selectedDatasetsHaveUnknown && <p>{t('WORKSPACE_PROJECTION_SELECT_UNKNOWNS')}</p>}
          <MmgWorkspaceProjectionSelector
            projectId={selectedProjectId}
            epsgCode={epsgCode}
            onProjectionSelected={onProjectionSelected}
            loadingProjectionSystems={loadingProjectionSystems}
            searchProjectionSystemsById={searchProjectionSystemsById}
            selectedProjectionSystems={selectedProjectionSystems}
          />
        </MmgPanelSubsection>
      </MmgGroup>
    </div>
  );
};

export default MmgWorkspaceCreateDataSetType;
