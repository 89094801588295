/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import SpinnersButton, { SpinnersButtonIcon, IButtonTooltips } from './Spinners/SpinnersButton';
import SpinnersDropdown from './Spinners/SpinnersDropdown';
import { IProgressItem } from './IProgressItem';
import { ClickAwayListener } from '@mui/material';
import { wrapperStyle } from '../mike-topbar-dropdown';

export const SPINNER_TIMEOUT = 6000;
export const PROGRESS_ITEM_TIMEOUT = 6000;

interface IProps extends React.HTMLAttributes<HTMLElement> {
  progressItems: Array<IProgressItem>;
  spinnerTimeout?: number;
  tooltipTitles?: IButtonTooltips;
  onClickItemButton?: (id: string) => void;
}

/**
 * @name MikeTopbarProgressSpinner
 * @summary Shows progress in the topbar. Typically related to notifications on currently-running actions.
 */
export const MikeTopbarProgressSpinner = (props: IProps) => {
  const { progressItems, spinnerTimeout, tooltipTitles, onClickItemButton } = {
    spinnerTimeout: SPINNER_TIMEOUT,
    ...props,
  };

  const [showDropdown, setShowDropdown] = useState(true);
  const hideTORef = useRef<any>();
  const resetCheckTORef = useRef<any>();
  const resetAlertTORef = useRef<any>();
  const timeouts = useRef<Array<any>>([]);
  const toggleDropdown = () => {
    clearTimeout(hideTORef.current);
    setShowDropdown(!showDropdown);
  };

  const [btnIcon, setBtnIcon] = useState<SpinnersButtonIcon>('spinner');

const {allItemsDone, itemsHasError} = useMemo(() => {
  const done = progressItems.every((itm) => itm && itm.done === true) && progressItems.length > 0;
  const errorCount: number = progressItems.filter((itm) => itm && itm.error === true).length;
  return {allItemsDone: done, itemsHasError: errorCount}
}, [progressItems])

  // Find out if progressItems are all done:
 /*  const allItemsDone: boolean =
    progressItems.every((itm) => itm.done === true) && progressItems.length > 0; */
  // Find out if progressItems have any errors:
  //const itemsHasError: number = progressItems.filter((itm) => itm.error === true).length;

  // Show SpinnersDropdown again when there are new items:
  const prevItemsLength = useRef<number>(0); // <- Hold previous items length here
  if (progressItems.length > prevItemsLength.current) {
    setBtnIcon('spinner');
    setShowDropdown(true);
    clearTimeout(hideTORef.current);
    hideTORef.current = setTimeout(() => {
      setShowDropdown(false);
    }, spinnerTimeout);
    timeouts.current.push(hideTORef.current);
  }
  prevItemsLength.current = progressItems.length; // <- Store previous items length here

  // Show icon according to all-done state:
  const prevAllItemsDone = useRef<boolean>(); // prevent infinite loop
  if (allItemsDone && !prevAllItemsDone.current) {
    setBtnIcon('checkmark');
    clearTimeout(resetCheckTORef.current);
    resetCheckTORef.current = setTimeout(() => {
      setBtnIcon('spinner');
    }, spinnerTimeout);
    timeouts.current.push(resetCheckTORef.current);
    prevAllItemsDone.current = true;
  } else {
    prevAllItemsDone.current = false;
  }

  // Show icon according to error state:
  const prevItemsHasError = useRef<boolean>(); // prevent infinite loop
  if (itemsHasError && !prevItemsHasError.current) {
    setBtnIcon('alert');
    clearTimeout(resetAlertTORef.current);
    resetAlertTORef.current = setTimeout(() => {
      setBtnIcon('spinner');
    }, spinnerTimeout);
    timeouts.current.push(resetAlertTORef.current);
    prevItemsHasError.current = true;
  } else {
    prevItemsHasError.current = false;
  }

  useEffect(() => {
    // cleanup at unmount:
    return () => {
      timeouts.current.forEach((to) => clearTimeout(to));
    };
  }, []);

  // Only show tooltips when closed:
  const tooltips: IButtonTooltips | undefined = showDropdown
    ? { spinner: '', alert: '', checkmark: '' }
    : tooltipTitles;

  return (
    <div css={wrapperStyle}>
      {progressItems.length > 0 && (
        <>
          <SpinnersButton onClick={toggleDropdown} icon={btnIcon} tooltipTitles={tooltips} />
          {showDropdown && (
            <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
              <div /* this div fixes ref error for ClickAwayListener */>
                <SpinnersDropdown progressItems={progressItems} onClickItemButton={onClickItemButton}/>
              </div>
            </ClickAwayListener>
          )}
        </>
      )}
    </div>
  );
};

export default MikeTopbarProgressSpinner;
