import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import { t } from '../translations/i18n';
import { store } from '../store';

import { getProjectAndParent, getProjectContent, setProjectContentDialogOpen } from '../store/actions/projectContent';
import { IGlobalState } from '../store/reducers';
import { IGetProject } from '../shared-components/mike-project-explorer/model';
import MikeButton from '../shared-components/mike-button';


type IProps = {
  /**
   * In Create workspace panel shown on home / projects page a project can be selected
   */
  selectedProjectId?: string; //
};

/**
 * @name MmgProjectContentButton
 * @summary Button to open project explorer
 * @param props
 */
const MmgProjectContentButton = (props: IProps) => {
  const { selectedProjectId } = props;

  const match = useMatch({
    path: '/project/:id/*'
  });
  const navigate = useNavigate();
  const project: IGetProject | null = useSelector((state: IGlobalState) => state.ProjectContentReducer.project);

  const currentProjectId = match && match.params.id;

  // on change of projectId in url load project and parent
  useEffect(
    () => {
      currentProjectId && store.dispatch(getProjectAndParent(currentProjectId, navigate));
    },
    [currentProjectId, navigate],
  );

  // on change of selected projectId load project and parent
  useEffect(
    () => {
      selectedProjectId && store.dispatch(getProjectAndParent(selectedProjectId, navigate));
    },
    [navigate, selectedProjectId],
  );

  const handleImportFromPlatform = useCallback(
    () => {
      if (project) {
        store.dispatch(getProjectContent(project));
        store.dispatch(setProjectContentDialogOpen(true, true));
      }
    },
    [project],
  );

  const canUpdateWorkspace = useMemo(() => {
    return project && project.capabilities && project.capabilities.canUpdateContent;
  }, [project]) 

  return (
    <MikeButton
      color="secondary"
      variant="outlined"
      disabled={!canUpdateWorkspace}
      onClick={handleImportFromPlatform}
      fullWidth={true}
    >
      {t('WORKSPACE_IMPORT_FROM_PLATFORM')}
    </MikeButton>
  );
};

export default MmgProjectContentButton;
