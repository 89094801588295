import { isEqual, uniq } from 'lodash-es';
import { v4 as uuid } from 'uuid';
import { IAction } from '../actions/Action';
import { EMapToolActionType } from '../actions/MapToolActionType';
import { EWorkspaceActionType } from '../actions/WorkspaceActionType';
import { Feature, FeatureCollection, Point, Polygon } from 'geojson';
import MikeVisualizer from '../../MikeVisualizer/lib/MikeVisualizer';
import { 
  DEFAULT_VARIABLE_PROPERTIES,
  IGNORE_VARIABLE_PROPERTIES,
  MMG_,
  addEditValue,
  getNumericValue,
  transferElevationToZValue,
} from '../../variables/create/variable-draw-constants';
import { EEditModeIds } from '../../shared/edit-modes/edit-modes';
import { ESelectForEditingModeIds } from '../../shared/edit-modes/select-for-editing-modes';
import { Fill, Style, Text, Circle, Stroke } from 'ol/style';
import { LABELFONTSIZE, editStyleFunction } from '../../workspaces/viewer/viewer-utils';
import tinycolor from 'tinycolor2';
import { getFeatureId } from '../../variables/create/variable-draw-utils';
import { EGeometryItemTypes } from '../../models/IGeometries';
import { EItemType } from '../../models/IOperationDescriptions';
import { EWorkspaceMeshActionType } from '../actions/WorkspaceMeshActionType';
import { IChangedNode, INodeNeighbours } from './MeshMapToolReducer';
import { isNumeric } from '../../mike-shared-helpers/helpers';
import { FONTFAMILY } from '../../shared/styles/mikeSharedTheme';
import MIKE_COLORS, { MIKE_MAP_COLORS } from '../../shared/styles/mike-colors';
import { OLDRAW_CLICKTOLERANCE } from '../../workspaces/viewer/viewer-constants';
import { changedNodesToFeatures } from '../../workspaces/sagas/meshEditHelpers';

// This reducer controls all around editing of meshes, geometries and variables

export enum EGeometryDrawType {
  POLYGON = 'Polgon',
  CIRCLE = 'Circle',
  RECTANGLE = 'Rectangle',
  POINT = 'Point',
  LINE = 'Line',
}

export interface IEditState {
  previousEdits: Array<FeatureCollection<any, any>>;
  featuresForEditing: FeatureCollection<any, any>;
  loadingFeaturesForEditing: boolean;
  loadingFeaturesForEditingFailed: boolean;
  editsToApply: boolean;
  propertiesForEditing: IAppendProperties;
  editMode: EEditModeIds | 0;
  selectionMode: ESelectForEditingModeIds | null;
  selectionId: string;
  itemId: string;
  geometryEditItemType: EGeometryItemTypes | null;
  itemType: EItemType | null;
  geometryDrawType: EGeometryDrawType | null;
  mixedEditTypeSet: boolean;
  // State related to editing mesh nodes
  nodeNeighbours: INodeNeighbours | null;
  nodesToUpdate: Array<IChangedNode>;
  meshNodeSaveInProgress: boolean;
  saveNodeFailed: boolean;
  newNodeX: number | null;
  newNodeY: number | null;
  colorFromMeshSurface: string | null;
  newNeighbouringFeatures: Array<Feature<Polygon>>;
}

const initialState: IEditState = {
  previousEdits: new Array<FeatureCollection<any, any>>(),
  featuresForEditing: { type: 'FeatureCollection', features: [] } as FeatureCollection<any, any>,
  loadingFeaturesForEditing: false,
  loadingFeaturesForEditingFailed: false,
  editsToApply: false,
  propertiesForEditing: {} as IAppendProperties,
  editMode: 0,
  selectionMode: null,
  selectionId: "",
  itemId: "",
  geometryEditItemType: null,
  itemType: null,
  geometryDrawType: null,
  mixedEditTypeSet: false,
  // State related to editing mesh nodes
  nodeNeighbours: null,
  nodesToUpdate: Array<IChangedNode>(),
  meshNodeSaveInProgress: false,
  saveNodeFailed: false,
  newNodeX: null,
  newNodeY: null,
  colorFromMeshSurface: null,
  newNeighbouringFeatures: Array<Feature<Polygon>>(),
};
export interface IAppendProperties {
  [key: string]: string | number;
}

export const EDIT_LAYER_ID = 'Edit_Layer';
export const EDIT_MODE = 'mmg_EditState';
export const EDIT_ID = 'mmg_CellIndex';
export const EDIT_MODE_UNCHANGED = 0;
export const EDIT_MODE_ADDED = 3;
export const EDIT_MODE_DELETED = 2;
export const EDIT_MODE_MODIFIED = 1;

const {
  update2DData,
  clearDrawnVectorLayerData,
  getCurrentlyDrawnGeojson,
  enable2DPointDrawingV2,
  disable2DPointDrawing,
  enable2DPointDrawing,
  setDrawVectorLayerGeojson,
  enable2DPolygonSelection,
  enable2DPointSelection,
  enable2DBoxSelection,
  disable2DPolygonSelection,
  disableAllDrawingTools,
  enable2DPolygonDrawingV2,
  enable2DPolylineDrawingV2,
  enable2DCircleDrawing,
  enable2DBoxDrawing,
  getConfiguration,
  delete2DData,
} = MikeVisualizer;

const NEIGHBOUR_DATA_ID = 'neighbour-data';
const DEFAULT_NEIGHBOUR_OPACITY = 0.8;
const DEFAULT_NEIGHBOUR_BACKGROUND_COLOR = `rgba(158,181,194, ${DEFAULT_NEIGHBOUR_OPACITY})`;

const { ol } = getConfiguration();

const nodeEditPointNoStyle = new Style({});
const nodeEditPointStyle = new Style({
  image: new Circle({
    fill: new Fill({
      color: MIKE_COLORS.WHITE,
    }),
    stroke: new Stroke({
      color: MIKE_MAP_COLORS.VIOLET,
      width: ol.strokeWidth / 2,
    }),
    radius: ol.pointRadius / 2,
  }),
});

/**
 * Gets a cell data id, if it exists.
 *
 * @param feature
 */
export const getFeatureCellIndex = (feature: Feature<any, any>): number | undefined => {
  if (!feature) {
    return;
  }

  if (feature.properties) {
    return feature.properties[EDIT_ID];
  }

  return;
};

export const modifyLineColor = tinycolor(MIKE_COLORS.GREEN_DEFAULT).toRgbString();
export const addLineColor = tinycolor(MIKE_COLORS.BRANDBLUE_DEFAULT).toRgbString();
export const deleteLineColor = tinycolor(MIKE_COLORS.PINK_DEFAULT).toRgbString();

const getStyle = (geoType: EGeometryItemTypes) => {
  const isPoint = ['Point', 'MultiPoint'].includes(geoType);
  const symbolColor = deleteLineColor;
  const symbolFill = new Fill({ color: symbolColor });
  const circleStyle = new Circle({
    fill: symbolFill,
    radius: 10,
  });
  const polyStyle = new Style({
    fill: symbolFill,
  });

  const featureStyle = (feature) => {
    const values = feature && feature.values_ ? feature.values_ : null;
    const editMode = values && values[EDIT_MODE] ? values[EDIT_MODE] : 0;
    const labelEditMode = editMode === EDIT_MODE_DELETED ? 'D' : editMode === EDIT_MODE_ADDED ? 'A' : 'M';
    const editModeLabel = new Text({
      text: labelEditMode,
      fill: new Fill({ color: MIKE_COLORS.WHITE }),
      font: `${LABELFONTSIZE} ${FONTFAMILY}`,
      justify: 'center',
    });
    if (isPoint) {
      return new Style({
        image: circleStyle,
        text: editModeLabel,
      });
    } else {
      const geometry = feature.getGeometry();
      const isPolygon = ['Polygon', 'MultiPolygon'].includes(geoType);
      if (isPolygon) {
        const labelPoint = geometry.getInteriorPoint();
        const labelStyle = new Style({
          text: editModeLabel,
          geometry: labelPoint,
        });
        return [polyStyle, labelStyle];
      } else {
        const strokeColor =
          editMode === EDIT_MODE_DELETED
            ? deleteLineColor
            : editMode === EDIT_MODE_ADDED
              ? addLineColor
              : modifyLineColor;
        return new Style({ stroke: new Stroke({ color: strokeColor, width: 4 }) });
      }
    }
  };
  return featureStyle;
};

const renderFeaturesWithAppliedChanged = (
  currentEditFeatures: Array<Feature<any>>,
  previousEdits: Array<FeatureCollection<any, any>>,
  geometryType?: EGeometryItemTypes,
) => {
  let mergedFeatures = [...currentEditFeatures];
  let ids = currentEditFeatures.map((feature: Feature<any>) => getFeatureId(feature));
  const edits = [...previousEdits.reverse()];
  edits.forEach((fc) => {
    const features = fc.features;
    features.forEach((feature: Feature<any>) => {
      const id = getFeatureId(feature);
      if (!id || !ids.includes(id)) {
        ids = [...ids, id];
        mergedFeatures = [...mergedFeatures, feature];
      }
    });
  });
  update2DData(
    { type: 'FeatureCollection', features: mergedFeatures },
    EDIT_LAYER_ID,
    undefined,
    undefined,
    1,
    getStyle(geometryType as any) as any,
  );
};

const renderMeshNodesWithAppliedChanges = (currentNode: IChangedNode | null, previousEdits: Array<IChangedNode>) => {
  let mergedNodes = currentNode ? [currentNode] : [];
  let ids = mergedNodes.map((node: IChangedNode) => node.nodeIndex);
  const edits = [...previousEdits.reverse()];
  edits.forEach((node: IChangedNode) => {
    const id = node.nodeIndex;
    if (id === -1) {
      mergedNodes = [...mergedNodes, node];
    } else if (!ids.includes(id)) {
      ids = [...ids, id];
      mergedNodes = [...mergedNodes, node];
    }
  });
  const changedFeatures = changedNodesToFeatures(mergedNodes);
  update2DData(
    { type: 'FeatureCollection', features: changedFeatures },
    EDIT_LAYER_ID,
    undefined,
    undefined,
    1,
    getStyle(EGeometryItemTypes.POINT) as any,
  );
};

/**
 * Edit Reducer.
 * Controls all around editing of meshes, geometries and variables
 * @name EditReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state: IEditState = initialState, action: IAction) {
  switch (action.type) {
    case EMapToolActionType.SET_FEATURES_FOR_EDITING_IDS: {
      const { operationId, targetId } = action.data;
      return { ...state, selectionId: operationId, itemId: targetId };
    }
    case EMapToolActionType.VALIDATE_CHANGED_VARIABLE_POINTS: {
      const newFC = action.data;
      switch (state.editMode) {
        case EEditModeIds.EDIT_MODE_MODIFY: {
          const currentCount = state.featuresForEditing.features.length;
          if (currentCount === 0) {
            return state;
          }
          const newCount = newFC && newFC.features ? newFC.features.length : 0;
          if (newCount !== currentCount) {
            setDrawVectorLayerGeojson(state.featuresForEditing);
          } else {
            const changed = !isEqual(state.featuresForEditing.features, newFC.features);
            if (changed) {
              let coordsChanged = false;
              const features = state.featuresForEditing.features;
              const selectedIds = features.map((feature: Feature<any>) => {
                return getFeatureId(feature);
              });
              // skip newly added features
              const cleanedFeatures = newFC.features.filter((feature: Feature<Point>) => {
                const id = getFeatureId(feature);
                return id && selectedIds.includes(id);
              });
              const updatedFeatures = cleanedFeatures.map((feature: Feature<Point>) => {
                const id = getFeatureId(feature);
                const originalFeature: Feature<Point> | undefined = features.find((origFeature: Feature<Point>) => {
                  const origId = getFeatureId(origFeature);
                  return origId === id;
                });
                const coords = feature.geometry.coordinates;
                const coordsMoved =
                  originalFeature !== undefined && !isEqual(originalFeature.geometry.coordinates, coords);
                if (coordsMoved) {
                  coordsChanged = true;
                }
                return coordsMoved
                  ? { ...feature, properties: { ...feature.properties, [EDIT_MODE]: EDIT_MODE_MODIFIED } }
                  : feature;
              });
              setDrawVectorLayerGeojson(
                updatedFeatures.length === 0
                  ? state.featuresForEditing
                  : { ...state.featuresForEditing, features: updatedFeatures },
              );

              if (coordsChanged) {
                return {
                  ...state,
                  editsToApply: changed,
                  featuresForEditing: { ...state.featuresForEditing, features: updatedFeatures },
                };
              }
            }
          }
          break;
        }
        case EEditModeIds.EDIT_MODE_ADD: {
          const newCount = newFC && newFC.features ? newFC.features.length : 0;
          let canApplyValues = newCount !== state.featuresForEditing.features.length;
          if (canApplyValues) {
            const values = Object.values(state.propertiesForEditing);
            values.forEach((val) => {
              const numericVal = getNumericValue(val);
              canApplyValues = numericVal !== null;
            });
          }
          return { ...state, editsToApply: canApplyValues };
        }
      }
      return state;
    }
    case EMapToolActionType.SET_SELECTION_MODE: {
      const mode = action.data;
      switch (mode) {
        case ESelectForEditingModeIds.SELECT_BY_POINT: {
          enable2DPointSelection(true);
          break;
        }
        case ESelectForEditingModeIds.SELECT_BY_RECTANGLE: {
          enable2DBoxSelection(true);
          break;
        }
        case ESelectForEditingModeIds.SELECT_BY_POLYGON: {
          enable2DPolygonSelection(true);
          break;
        }
        default: {
          disable2DPolygonSelection();
          break;
        }
      }
      return { ...state, selectionMode: mode };
    }
    case EMapToolActionType.SET_EDIT_MODE: {
      const { editMode, geometryType, itemType } = action.data;
      clearDrawnVectorLayerData();
      if (editMode === EEditModeIds.EDIT_MODE_ADD) {
        switch (geometryType) {
          case EGeometryItemTypes.POINT:
          case EGeometryItemTypes.MULTI_POINT:
            enable2DPointDrawingV2({
              singleItem: itemType === EItemType.MESH,
              olDrawOptions: { clickTolerance: OLDRAW_CLICKTOLERANCE },
            });
            break;
          case EGeometryItemTypes.POLYGON:
          case EGeometryItemTypes.MULTI_POLYGON:
            if (state.geometryDrawType === EGeometryDrawType.RECTANGLE) {
              enable2DBoxDrawing();
            } else if (state.geometryDrawType === EGeometryDrawType.CIRCLE) {
              enable2DCircleDrawing({ featureAttributes: { mmg_ExtendedGeometryType: 'circle' } });
            } else {
              enable2DPolygonDrawingV2({ vectorLayerStyle: editStyleFunction });
            }
            break;
          case EGeometryItemTypes.LINE_STRING:
          case EGeometryItemTypes.MULTI_LINE_STRING:
            enable2DPolylineDrawingV2({
              vectorLayerStyle: editStyleFunction,
              olDrawOptions: { snapTolerance: 1 },
            });
            break;
          default:
            disableAllDrawingTools();
            break;
        }
      }
      return {
        ...state,
        editMode,
        featuresForEditing: initialState.featuresForEditing,
        geometryEditType: geometryType,
      };
    }
    case EMapToolActionType.ADD_DRAWN_FEATURES: {
      const geometryType = action.data;
      const newFeatureCollection = getCurrentlyDrawnGeojson(); // action.data;
      const newFeatures = newFeatureCollection.features.filter((feature: Feature<any>) => {
        const id = getFeatureCellIndex(feature);
        return id === undefined;
      });
      if (newFeatures.length) {
        const updatedFeatures =
          state.itemType === EItemType.VARIABLE
            ? transferElevationToZValue(newFeatures, state.propertiesForEditing, EDIT_MODE_ADDED)
            : addEditValue(newFeatures, EDIT_MODE_ADDED);
        clearDrawnVectorLayerData();
        renderFeaturesWithAppliedChanged(updatedFeatures, state.previousEdits, geometryType);
        return {
          ...state,
          previousEdits: [
            ...state.previousEdits,
            { type: 'FeatureCollection', features: updatedFeatures } as FeatureCollection<any, any>,
          ],
          editsToApply: false,
          featuresForEditing: initialState.featuresForEditing,
        };
      }
      return state;
    }

    case EMapToolActionType.SET_PROPERTIES_FOR_FEATURES_FOR_CREATING: {
      return { ...state, propertiesForEditing: DEFAULT_VARIABLE_PROPERTIES as IAppendProperties };
    }
    case EMapToolActionType.SET_PROPERTIES_FOR_FEATURES_FOR_EDITING: {
      const { dataArrays, type } = action.data;
      const cellDdataArrays = dataArrays.filter((da) => da.type.toLowerCase() === type);
      const ids: Array<string> = cellDdataArrays.map(({ id }) => id.toString().toLowerCase());
      const properties: IAppendProperties = {};
      ids.forEach((id) => {
        if (!IGNORE_VARIABLE_PROPERTIES.includes(id) && !id.startsWith(MMG_)) {
          properties[id] = 0;
        }
      });
      return { ...state, propertiesForEditing: properties as IAppendProperties };
    }
    case EMapToolActionType.STORE_FEATURES_FOR_EDITING: {
      const geometryType = action.data;
      const changedProperty = state.editMode === EEditModeIds.EDIT_MODE_DELETE ? EDIT_MODE_DELETED : EDIT_MODE_MODIFIED;
      const editFeatures = [...state.featuresForEditing.features];
      const newFeatures = state.mixedEditTypeSet
        ? editFeatures
        : state.editMode === EEditModeIds.EDIT_MODE_MODIFY
          ? state.featuresForEditing.features.filter((feature: Feature<any>) => {
              return (
                feature.properties && feature.properties[EDIT_MODE] && feature.properties[EDIT_MODE] === changedProperty
              );
            })
          : editFeatures.map((feature: Feature<any>) => {
              const f = { ...feature, properties: { ...feature.properties, [EDIT_MODE]: changedProperty } };
              return f;
            });

      const updatedFeatures =
        state.editMode === EEditModeIds.EDIT_MODE_ATTRIBUTION && state.itemType === EItemType.VARIABLE
          ? transferElevationToZValue(newFeatures, state.propertiesForEditing, EDIT_MODE_MODIFIED)
          : newFeatures;
      clearDrawnVectorLayerData();
      renderFeaturesWithAppliedChanged(updatedFeatures, state.previousEdits, geometryType);
      enable2DBoxSelection();
      return {
        ...state,
        previousEdits: [
          ...state.previousEdits,
          { type: 'FeatureCollection', features: updatedFeatures } as FeatureCollection<any, any>,
        ],
        editsToApply: false,
        featuresForEditing: initialState.featuresForEditing,
        selectionMode: ESelectForEditingModeIds.SELECT_BY_RECTANGLE,
        mixedEditTypeSet: false,
      };
    }
    case EMapToolActionType.UNDO_FEATURES_FOR_EDITING: {
      const geometryType = action.data;
      const previousEditCount = state.previousEdits.length;
      if (previousEditCount === 0) {
        return state;
      }
      const previouslyEditedFeatureCollections = state.previousEdits.slice(0, -1);
      clearDrawnVectorLayerData();
      renderFeaturesWithAppliedChanged([], previouslyEditedFeatureCollections, geometryType);
      return {
        ...state,
        featuresForEditing: initialState.featuresForEditing,
        previousEdits: previouslyEditedFeatureCollections,
        editsToApply: false,
      };
    }
    case EMapToolActionType.CLEAR_SELECTION_FOR_EDITING: {
      clearDrawnVectorLayerData();
      if (state.itemType === EItemType.MESH) {
        disable2DPointDrawing();
        clearDrawnVectorLayerData();
        if (state.nodeNeighbours) {
          const newNeighbouringFeatures = state.newNeighbouringFeatures.filter(
            (feature: Feature<Polygon, any>) =>
              feature.properties && feature.properties.nodeIndex !== state.nodeNeighbours.nodeIndex,
          );
          const newNeighbouringFeatureCollection = {
            type: 'FeatureCollection',
            features: newNeighbouringFeatures,
          } as FeatureCollection<any, any>;
          update2DData(
            newNeighbouringFeatureCollection,
            NEIGHBOUR_DATA_ID,
            state.colorFromMeshSurface || DEFAULT_NEIGHBOUR_BACKGROUND_COLOR,
            MIKE_MAP_COLORS.VIOLET,
          );
          return {
            ...state,
            newNodeX: null,
            newNodeY: null,
            nodeNeighbours: null,
            newNeighbouringFeatures,
          };
        }

        return {
          ...state,
          newNodeX: null,
          newNodeY: null,
          nodeNeighbours: null,
        };
      } else {
        const properties: IAppendProperties = {};
        const ids: Array<string> = Object.keys(state.propertiesForEditing);
        ids.forEach((id) => {
          properties[id] = 0;
        });
        return {
          ...state,
          editsToApply: false,
          propertiesForEditing: properties as IAppendProperties,
          featuresForEditing: initialState.featuresForEditing,
        };
      }
    }
    case EMapToolActionType.CLEAR_FEATURES_FOR_EDITING: {
      clearDrawnVectorLayerData();
      return {
        ...state,
        editsToApply: false,
        previousEdits: new Array<FeatureCollection<any, any>>(),
        loadingFeaturesForEditing: false,
        propertiesForEditing: {} as IAppendProperties,
        featuresForEditing: { type: 'FeatureCollection', features: [] } as FeatureCollection<any, any>,
      };
    }
    case EMapToolActionType.UPDATE_FEATURES_FOR_EDITING_VALUE: {
      const { key, value } = action.data;
      const valIsNumeric = isNumeric(value);      
      let canApplyValues = valIsNumeric;
      if (canApplyValues) {
        const keys = Object.keys(state.propertiesForEditing);
        keys.forEach((k: string) => {
          if (k !== key) {
            const numericVal = isNumeric(state.propertiesForEditing[k]);
            canApplyValues = numericVal !== null;
          }
        });
      }

      if (state.editMode === EEditModeIds.EDIT_MODE_ADD) {
        const newFeatureCollection = getCurrentlyDrawnGeojson();
        return {
          ...state,
          editsToApply: canApplyValues && newFeatureCollection.features.length > 0,
          propertiesForEditing: { ...state.propertiesForEditing, [key]: value } as IAppendProperties,
        };
      } else {
        return {
          ...state,
          editsToApply: canApplyValues,
          propertiesForEditing: { ...state.propertiesForEditing, [key]: value } as IAppendProperties,
        };
      }
    }
    case EMapToolActionType.LOADING_FEATURES_FOR_EDITING: {
      return { ...state, loadingFeaturesForEditing: action.data, editsToApply: false };
    }
    case EMapToolActionType.SET_GEOPROCESSING_EDITS_TO_APPLY: {
      return {
        ...state,
        editsToApply: true,
        featuresForEditing: action.data.featuresForEditing,
        mixedEditTypeSet: action.data.mixedEditTypeAlreadySet,
      };
    }
    case EMapToolActionType.LOADING_FEATURES_FOR_EDITING_FAILED: {
      return { ...state, loadingFeaturesForEditing: false, loadingFeaturesForEditingFailed: true };
    }
    case EMapToolActionType.SET_DRAW_TYPE: {
      const drawType = action.data;
      switch (drawType) {
        case EGeometryDrawType.RECTANGLE: {
          enable2DBoxDrawing();
          break;
        }
        case EGeometryDrawType.CIRCLE: {
          enable2DCircleDrawing({ featureAttributes: { mmg_ExtendedGeometryType: 'circle' } });
          break;
        }
        case EGeometryDrawType.POLYGON: {
          enable2DPolygonDrawingV2({ vectorLayerStyle: editStyleFunction });
          break;
        }
        case EGeometryDrawType.LINE: {
          enable2DPolylineDrawingV2({
            vectorLayerStyle: editStyleFunction,
            olDrawOptions: { snapTolerance: 1 },
          });
          break;
        }
        default: {
          enable2DPointDrawingV2({ olDrawOptions: { clickTolerance: OLDRAW_CLICKTOLERANCE } });
          break;
        }
      }
      return { ...state, geometryDrawType: action.data };
    }
    case EMapToolActionType.GEOMETRY_EDIT: {
      const editItemType = action.data.geometryEditItemType;
      let drawType;
      switch (editItemType) {
        case EGeometryItemTypes.LINE_STRING:
        case EGeometryItemTypes.MULTI_LINE_STRING:
          drawType = EGeometryDrawType.LINE;
          break;
        case EGeometryItemTypes.POLYGON:
        case EGeometryItemTypes.MULTI_POLYGON:
          drawType = EGeometryDrawType.POLYGON;
          break;
        default: {
          drawType = EGeometryDrawType.POINT;
          break;
        }
      }
      return { ...state, geometryEditItemType: editItemType, itemType: EItemType.GEOMETRY, geometryDrawType: drawType };
    }
    case EMapToolActionType.GEOMETRY_CREATE: {
      return {
        ...state,
        itemType: EItemType.GEOMETRY,
        geometryDrawType: action.data.drawType,
        geometryEditItemType: action.data.geometryType,
      };
    }
    case EMapToolActionType.VARIABLE_CREATE: {
      return { ...state, itemType: EItemType.VARIABLE };
    }
    case EMapToolActionType.VARIABLE_EDIT: {
      return { ...state, geometryEditItemType: null, itemType: EItemType.VARIABLE };
    }
    case EWorkspaceMeshActionType.EDIT_NODES: {
      return { ...state, geometryEditItemType: null, itemType: EItemType.MESH };
    }
    case EMapToolActionType.SET_FEATURES_FOR_EDITING: {
      const featureCollection = action.data.featureCollection;
      if (action.data.operationId !== state.selectionId) {
        return state;
      }
      const selectedCount = featureCollection && featureCollection.features ? featureCollection.features.length : 0;

      const featuresWithId =
        selectedCount > 0
          ? featureCollection.features.map((feature: Feature<any>) => {
              const id = getFeatureId(feature);
              if (!id) {
                const newId = uuid();
                return {
                  ...feature,
                  id: newId,
                  properties: { ...feature.properties, id: newId },
                };
              } else {
                return feature;
              }
            })
          : featureCollection.features;
      const featureCollectionWithId = { ...featureCollection, features: featuresWithId };

      const getValue = (key: string, features: Array<Feature<any>>) => {
        const values = features.map((feature: Feature<any>) => {
          const properties = feature.properties;
          return properties ? properties[key] : null;
        });
        const numericValues = values.filter((value: any) => value !== undefined && isNumeric(value.toString()));
        const uniqValues = uniq(numericValues);
        if (uniqValues.length === 1) {
          return uniqValues[0];
        } else if (uniqValues.length > 1) {
          const sortedValues = uniqValues.sort();
          return 'range: ' + sortedValues[0] + ' - ' + sortedValues[sortedValues.length - 1];
        } else {
          return undefined;
        }
      };
      if (state.itemType === EItemType.VARIABLE) {
        const properties: IAppendProperties = {};
        if (selectedCount > 0) {
          const ids: Array<string> = Object.keys(state.propertiesForEditing);
          ids.forEach((id) => {
            properties[id] = getValue(id, featureCollectionWithId.features);
          });
        }

        switch (state.editMode) {
          case EEditModeIds.EDIT_MODE_MODIFY:
            enable2DPointDrawing(true, false);
            break;
          default:
            disable2DPointDrawing();
            break;
        }
        setDrawVectorLayerGeojson(featureCollectionWithId);
        return {
          ...state,
          featuresForEditing: featureCollectionWithId,
          editsToApply: false,
          propertiesForEditing: properties,
        };
      } else {
        if (
          state.itemType === EItemType.GEOMETRY &&
          (state.editMode === EEditModeIds.EDIT_MODE_MODIFY || state.editMode === EEditModeIds.EDIT_MODE_ADD)
        ) {
          switch (state.geometryEditItemType) {
            case EGeometryItemTypes.POLYGON:
            case EGeometryItemTypes.MULTI_POLYGON:
              enable2DPolygonDrawingV2({ vectorLayerStyle: editStyleFunction });
              break;
            case EGeometryItemTypes.LINE_STRING:
            case EGeometryItemTypes.MULTI_LINE_STRING:
              enable2DPolylineDrawingV2({
                vectorLayerStyle: editStyleFunction,
                olDrawOptions: { snapTolerance: 1 },
              });
              break;
            case EGeometryItemTypes.POINT:
            case EGeometryItemTypes.MULTI_POINT:
              enable2DPointDrawingV2({ olDrawOptions: { clickTolerance: OLDRAW_CLICKTOLERANCE } });
              break;
            default:
              disableAllDrawingTools();
              break;
          }
        }
        setDrawVectorLayerGeojson(featureCollectionWithId);
        return {
          ...state,
          featuresForEditing: featureCollectionWithId,
          editsToApply: false,
        };
      }
    }
    case EWorkspaceActionType.CLOSE: {
      return { ...state, ...initialState };
    }
    case EMapToolActionType.VARIABLES_UNLOAD: {
      return { ...state, ...initialState };
    }
    case EWorkspaceMeshActionType.SET_NODE_NEIGHBOURS: {
      const nodeNeighbours: INodeNeighbours = action.data;

      if (nodeNeighbours && nodeNeighbours.nodeX && nodeNeighbours.nodeY && nodeNeighbours.nodeIndex >= 0) {
        const features = nodeNeighbours.neighbouringFeatures;
        const enrichedFeatures =
          features && features.length > 0
            ? features.map((feature: Feature<Polygon, any>) => {
                return {
                  ...feature,
                  properties: feature.properties
                    ? { ...feature.properties, nodeIndex: nodeNeighbours.nodeIndex }
                    : { nodeIndex: nodeNeighbours.nodeIndex },
                };
              })
            : features;

        update2DData(
          {
            type: 'FeatureCollection',
            features: enrichedFeatures,
          },
          NEIGHBOUR_DATA_ID,
          state.colorFromMeshSurface || DEFAULT_NEIGHBOUR_BACKGROUND_COLOR,
          MIKE_MAP_COLORS.VIOLET,
        );
        // Enable point drawing when a node is selected (node index becomes available).
        enable2DPointDrawing(true, true, nodeEditPointStyle as any, nodeEditPointNoStyle as any, nodeEditPointStyle as any);
        // Point geojson is created & appended as drawn data so it can be edited.
        const featureCollection = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              id: nodeNeighbours.nodeIndex,
              geometry: {
                type: 'Point',
                coordinates: [nodeNeighbours.nodeX, nodeNeighbours.nodeY],
              },
            },
          ],
        } as FeatureCollection<Point, any>;

        setDrawVectorLayerGeojson(featureCollection);
        return {
          ...state,
          propertiesForEditing: nodeNeighbours.nodeProperties,
          nodeNeighbours: { ...nodeNeighbours, neighbouringFeatures: enrichedFeatures },
        };
      } else {
        disable2DPointDrawing();
      }

      return { ...state, nodeNeighbours };
    }
    case EWorkspaceMeshActionType.SUBMIT_NODE_UPDATE: {
      clearDrawnVectorLayerData();
      disable2DPointDrawing();
      const newX = state.newNodeX;
      const newY = state.newNodeY;
      if (state.editMode === EEditModeIds.EDIT_MODE_ADD) {
        const currentNode = {
          nodeIndex: -1,
          nodeX: newX,
          nodeY: newY,
          editMode: EDIT_MODE_ADDED,
          nodeProperties: state.propertiesForEditing,
        };
        const nodesToUpdate = [...state.nodesToUpdate, currentNode];
        renderMeshNodesWithAppliedChanges(currentNode as any, state.nodesToUpdate);
        return {
          ...state,
          newNodeX: null,
          newNodeY: null,
          nodeNeighbours: null,
          nodesToUpdate,
        };
      } else if (state.editMode === EEditModeIds.EDIT_MODE_DELETE) {
        if (state.nodeNeighbours && state.nodeNeighbours.nodeIndex >= 0) {
          const currentNode = {
            nodeIndex: state.nodeNeighbours.nodeIndex,
            nodeX: state.nodeNeighbours.nodeX,
            nodeY: state.nodeNeighbours.nodeY,
            editMode: EDIT_MODE_DELETED,
            nodeProperties: state.propertiesForEditing,
          };
          const nodesToUpdate = [...state.nodesToUpdate, currentNode];
          renderMeshNodesWithAppliedChanges(currentNode, state.nodesToUpdate);
          return {
            ...state,
            newNodeX: null,
            newNodeY: null,
            nodeNeighbours: null,
            nodesToUpdate,
          };
        }
      } else if (state.editMode === EEditModeIds.EDIT_MODE_ATTRIBUTION) {
        if (state.nodeNeighbours && state.nodeNeighbours.nodeIndex >= 0) {
          const currentNode = {
            nodeIndex: state.nodeNeighbours.nodeIndex,
            nodeX: state.nodeNeighbours.nodeX,
            nodeY: state.nodeNeighbours.nodeY,
            editMode: EDIT_MODE_MODIFIED,
            nodeProperties: state.propertiesForEditing,
          };
          const nodesToUpdate = [...state.nodesToUpdate, currentNode];
          renderMeshNodesWithAppliedChanges(currentNode, state.nodesToUpdate);
          return {
            ...state,
            newNodeX: null,
            newNodeY: null,
            nodeNeighbours: null,
            nodesToUpdate,
          };
        }
      } else if (state.nodeNeighbours && state.nodeNeighbours.nodeIndex >= 0 && newX && newY) {
        const currentNode = {
          nodeIndex: state.nodeNeighbours.nodeIndex,
          nodeX: newX,
          nodeY: newY,
          editMode: EDIT_MODE_MODIFIED,
          nodeProperties: state.propertiesForEditing,
        };
        const nodesToUpdate = [...state.nodesToUpdate, currentNode];
        renderMeshNodesWithAppliedChanges(currentNode, state.nodesToUpdate);
        return {
          ...state,
          newNodeX: null,
          newNodeY: null,
          nodeNeighbours: null,
          nodesToUpdate,
        };
      }
      return state;
    }
    case EWorkspaceMeshActionType.UNDO_NODE_SUBMIT: {
      const nodeIndex = action.data;
      disable2DPointDrawing();
      clearDrawnVectorLayerData();
      const remainingNodesToUpdate = state.nodesToUpdate.filter((node: IChangedNode) => node.nodeIndex !== nodeIndex);
      const newNeighbouringFeatures = state.newNeighbouringFeatures.filter(
        (feature: Feature<Polygon, any>) => feature.properties && feature.properties.nodeIndex !== nodeIndex,
      );
      const newNeighbouringFeatureCollection = {
        type: 'FeatureCollection',
        features: newNeighbouringFeatures,
      } as FeatureCollection<any, any>;
      update2DData(
        newNeighbouringFeatureCollection,
        NEIGHBOUR_DATA_ID,
        state.colorFromMeshSurface || DEFAULT_NEIGHBOUR_BACKGROUND_COLOR,
        MIKE_MAP_COLORS.VIOLET,
      );
      renderMeshNodesWithAppliedChanges(null, remainingNodesToUpdate);
      return {
        ...state,
        newNodeX: null,
        newNodeY: null,
        nodeNeighbours: null,
        newNeighbouringFeatures,
        nodesToUpdate: remainingNodesToUpdate,
      };
    }

    case EWorkspaceMeshActionType.FINISH_NODE_UPDATES: {
      disable2DPointDrawing();
      clearDrawnVectorLayerData();
      delete2DData(NEIGHBOUR_DATA_ID);
      return {
        ...state,
        meshEditNodesTargetId: null,
        nodeNeighbours: null,
        nodesToUpdate: new Array<IChangedNode>(),
        newNeighbouringFeatures: new Array<Feature<Polygon>>(),
        newNodeX: null,
        newNodeY: null,
      };
    }

    case EWorkspaceMeshActionType.SAVE_NODE_UPDATES: {
      return { ...state, meshNodeSaveInProgress: true, saveNodeFailed: false };
    }

    case EWorkspaceMeshActionType.SAVE_NODE_UPDATES_FAILED: {
      return { ...state, meshNodeSaveInProgress: false, saveNodeFailed: true };
    }

    case EWorkspaceMeshActionType.SAVE_NODE_UPDATES_SUCCEEDED: {
      return { ...state, meshNodeSaveInProgress: false, saveNodeFailed: false };
    }

    case EWorkspaceMeshActionType.SET_NEW_NODE_COORDS: {
      const id = action.data.id;
      const newCoordinates = action.data.newCoordinates;
      if (state.nodesToUpdate.length > 0) {
        const lastNodeToUpdate = state.nodesToUpdate[state.nodesToUpdate.length - 1];
        const canContinue = lastNodeToUpdate.editMode === EDIT_MODE_MODIFIED ? true : false;
        if (!canContinue) {
          return state;
        }
      }

      if (newCoordinates && newCoordinates.length > 1) {
        const [newX, newY] = newCoordinates;
        if (state.editMode === EEditModeIds.EDIT_MODE_ADD) {
          return { ...state, newNodeX: newX, newNodeY: newY };
        } else if (newX === state.newNodeX && newY === state.newNodeY) {
          return state; // If updates get triggered with the same position, do nothing.
        }
      } else {
        return state;
      }

      const nodeNeighbours: INodeNeighbours | null = state.nodeNeighbours;

      const inside = (vs, newX, newY) => {
        const n = vs.length;
        let is_in = false;
        let x1;
        let x2;
        let y1;
        let y2;

        const x = newX;
        const y = newY;

        for (let i = 0; i < n - 1; ++i) {
          x1 = vs[i][0];
          x2 = vs[i + 1][0];
          y1 = vs[i][1];
          y2 = vs[i + 1][1];
                 
          if (y < y1 !== y < y2 && x < ((x2 - x1) * (y - y1)) / (y2 - y1) + x1) {
            is_in = !is_in;
          }      
        }

        return is_in;
      };

      if (nodeNeighbours && nodeNeighbours.neighbouringFeatures && nodeNeighbours.nodeX && nodeNeighbours.nodeY) {
        const nodeNeighbourFeatures = nodeNeighbours.neighbouringFeatures;

        const [newX, newY] = newCoordinates;
        const isInside = [];
        nodeNeighbourFeatures.forEach((neig) => {
          const neightCoords = neig.geometry.coordinates[0];
          const check = inside(neightCoords, newX, newY);
          if (check) {
            isInside.push('isInside');
          }
        });

        if (!isInside.includes('isInside')) {
          return state;
        }
        // Update neighbouring features, by updating the coordinates of the selected node.
        const newFeatures = nodeNeighbourFeatures.map((feature) => {
          const coordinates = feature.geometry.coordinates[0];

          const updatedCoordinates = coordinates.map((coordinate) => {
            const [x, y, z] = coordinate;
            if (x === nodeNeighbours.nodeX && y === nodeNeighbours.nodeY) {
              return [newX, newY, z];
            }

            return [x, y, z];
          });

          return {
            ...feature,
            properties: feature.properties ? { ...feature.properties, nodeIndex: id } : { nodeIndex: id },
            geometry: {
              ...feature.geometry,
              coordinates: [updatedCoordinates],
            },
          };
        });
        const newNeighbouringFeatures = state.newNeighbouringFeatures
          .filter((feature: Feature<Polygon, any>) => feature.properties && feature.properties.nodeIndex !== id)
          .concat(newFeatures);
        const newNeighbouringFeatureCollection = {
          type: 'FeatureCollection',
          features: newNeighbouringFeatures,
        } as FeatureCollection<any, any>;
        update2DData(
          newNeighbouringFeatureCollection,
          NEIGHBOUR_DATA_ID,
          state.colorFromMeshSurface || DEFAULT_NEIGHBOUR_BACKGROUND_COLOR,
          MIKE_MAP_COLORS.VIOLET,
        );
        return { ...state, newNodeX: newX, newNodeY: newY, newNeighbouringFeatures };
      }

      return state;
    }
    case EWorkspaceMeshActionType.SET_COLOR_FROM_MESH_SURFACE: {
      const colorFromMeshSurface = action.data;
      return { ...state, colorFromMeshSurface };
    }

    default:
      return state;
  }
}
